import { API } from "../services/api";
import { ActionKeys } from "./constants/adminConstants";
import FileDownload from "js-file-download";

export const actionCreators = {
  getNewsListing: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_NEWS_LISTING });
    return dispatch(API.admin.getNewsListing(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_NEWS_LISTING,
        isSuccess: response.isSuccess,
        isLoading: false,
        items: response.items,
        message: response.message
      });
    });
  },
  getNewsItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_NEWS_ITEM });
    return dispatch(API.admin.getNewsItem(token, id)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_NEWS_ITEM,
        isSuccess: response.isSuccess,
        isLoading: false,
        item: response.item,
        message: response.message
      });
    });
  },
  updateNewsItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPDATE_NEWS_ITEM });
    return dispatch(API.admin.updateNewsItem(token, item)).then(response => {
      dispatch({
        type: ActionKeys.UPDATED_NEWS_ITEM,
        isSuccess: response.isSuccess,
        isLoading: false,
        message: response.message
      });
    });
  },
  updateNewsAttachment: (token, id, data) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPDATE_NEWS_ATTACHMENT });
    return dispatch(API.admin.updateNewsAttachment(token, id, data)).then(
      response => {
        dispatch({
          type: ActionKeys.UPDATED_NEWS_ATTACHMENT,
          isSuccess: response.isSuccess,
          isUploading: false,
          message: response.message
        });
      }
    );
  },
  downloadNewsAttachment:
    (token, id, filename) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.DOWNLOAD_NEWS_ATTACHMENT });
      return dispatch(
        API.admin.downloadNewsAttachment(token, id, filename)
      ).then(response => {
        if (response.status === 200) {
          FileDownload(response.data, filename);
        }

        return response.isError ? response.errorMessage : true;
      });
    },
  insertNewsItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.INSERT_NEWS_ITEM });
    return dispatch(API.admin.insertNewsItem(token, item)).then(response => {
      dispatch({
        type: ActionKeys.INSERTED_NEWS_ITEM,
        isSuccess: response.isSuccess,
        isLoading: false,
        message: response.message
      });
    });
  },
  deleteNewsItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.DELETE_NEWS_ITEM });
    return dispatch(API.admin.deleteNewsItem(token, id)).then(response => {
      dispatch({
        type: ActionKeys.DELETED_NEWS_ITEM,
        isSuccess: response.isSuccess,
        isLoading: false,
        message: response.message
      });
    });
  },
  getNotificationsListing: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_NOTIFICATION_LISTING });
    return dispatch(API.admin.getNotificationsListing(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_NOTIFICATION_LISTING,
        isSuccess: response.isSuccess,
        isLoading: false,
        items: response.items,
        message: response.message
      });
    });
  },
  getNotificationItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_NOTIFICATION_ITEM });
    return dispatch(API.admin.getNotificationItem(token, id)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_NOTIFICATION_ITEM,
        isSuccess: response.isSuccess,
        isLoading: false,
        item: response.item,
        message: response.message
      });
    });
  },
  updateNotificationItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPDATE_NOTIFICATION_ITEM });
    return dispatch(API.admin.updateNotificationItem(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.UPDATED_NOTIFICATION_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  insertNotificationItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.INSERT_NOTIFICATION_ITEM });
    return dispatch(API.admin.insertNotificationItem(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.INSERTED_NOTIFICATION_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  deleteNotificationItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.DELETE_NOTIFICATION_ITEM });
    return dispatch(API.admin.deleteNotificationItem(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.DELETED_NOTIFICATION_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  getUserListing: (token, options) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_USER_LISTING });
    return dispatch(API.admin.getUserListing(token, options)).then(response => {
      return response;
    });
  },
  getUserLookup: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_USER_LOOKUP });
    return dispatch(API.admin.getUserLookup(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_USER_LOOKUP,
        isSuccess: response.isSuccess,
        isLoading: false,
        items: response.items,
        message: response.message
      });
    });
  },
  getDdsUserLookup: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DDS_USER_LOOKUP });
    return dispatch(API.admin.getUsersDds(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_DDS_USER_LOOKUP,
        isSuccess: response.isSuccess,
        isLoading: false,
        items: response.items,
        message: response.message
      });
    });
  },
  getUserItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_USER_ITEM });
    return dispatch(API.admin.getUserItem(token, id)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_USER_ITEM,
        isSuccess: response.isSuccess,
        message: response.message
      });
      return response;
    });
  },
  updateUserItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPDATE_USER_ITEM });
    return dispatch(API.admin.updateUserItem(token, item)).then(response => {
      dispatch({
        type: ActionKeys.UPDATED_USER_ITEM,
        isSuccess: response.isSuccess,
        isLoading: false,
        message: response.message
      });
    });
  },
  insertUserItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.INSERT_USER_ITEM });
    return dispatch(API.admin.insertUserItem(token, item)).then(response => {
      dispatch({
        type: ActionKeys.INSERTED_USER_ITEM,
        isSuccess: response.isSuccess,
        isLoading: false,
        message: response.message
      });
      return response;
    });
  },
  deleteUserItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.DELETE_USER_ITEM });
    return dispatch(API.admin.deleteUserItem(token, item)).then(response => {
      dispatch({
        type: ActionKeys.DELETED_USER_ITEM,
        isSuccess: response.isSuccess,
        isLoading: false,
        message: response.message
      });
    });
  },
  sendPasswordReset: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.SEND_PASSWORD_RESET });
    return dispatch(API.admin.sendPasswordReset(token, item)).then(response => {
      dispatch({
        type: ActionKeys.SENT_PASSWORD_RESET,
        isSuccess: response.isSuccess,
        isLoading: false,
        message: response.message
      });
    });
  },
  getDealerListing: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DEALER_LISTING });
    return dispatch(API.admin.getDealerListing(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_DEALER_LISTING,
        isSuccess: response.isSuccess,
        items: response.items,
        message: response.message
      });
    });
  },
  getFilteredDealerListing: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DEALER_LISTING });
    return dispatch(API.admin.getFilteredDealerListing(token)).then(
      response => {
        dispatch({
          type: ActionKeys.RECEIVE_DEALER_LISTING,
          isSuccess: response.isSuccess,
          isFilteredDealerListingLoading: false,
          items: response.items,
          message: response.message
        });
      }
    );
  },
  getDealerDiscount: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DEALER_DISCOUNT });
    return dispatch(API.admin.getDealerDiscount(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_DEALER_LISTING,
        isSuccess: response.isSuccess,
        message: response.message
      });
      return response;
    });
  },
  getDiscountViewed:
    (token, status = "all") =>
    async (dispatch, getState) => {
      dispatch({ type: ActionKeys.REQUEST_DISCOUNT_VIEWED });
      return dispatch(API.admin.getDiscountViewed(token, status)).then(
        response => {
          dispatch({
            type: ActionKeys.RECEIVE_DEALER_LISTING,
            isSuccess: response.isSuccess,
            message: response.message
          });
          return response;
        }
      );
    },
  insertDealerDiscount: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.INSERT_DEALER_DISCOUNT });
    return API.admin
      .insertDealerDiscount(token, item)()
      .then(response => {
        dispatch({
          type: ActionKeys.INSERTED_DEALER_DISCOUNT,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
        return response;
      });
  },
  updatePageItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPDATE_PAGE_ITEM });
    return dispatch(API.admin.updatePageItem(token, item)).then(response => {
      dispatch({
        type: ActionKeys.UPDATED_PAGE_ITEM,
        isSuccess: response.isSuccess,
        isLoading: false,
        message: response.message
      });
    });
  },
  insertPageItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.INSERT_PAGE_ITEM });
    return dispatch(API.admin.insertPageItem(token, item)).then(response => {
      dispatch({
        type: ActionKeys.INSERTED_PAGE_ITEM,
        isSuccess: response.isSuccess,
        isLoading: false,
        message: response.message
      });
    });
  },
  deletePageItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.DELETE_PAGE_ITEM });
    return dispatch(API.admin.deletePageItem(token, id)).then(response => {
      dispatch({
        type: ActionKeys.DELETED_PAGE_ITEM,
        isSuccess: response.isSuccess,
        isLoading: false,
        message: response.message
      });
    });
  },
  getServiceRequestListing: (token, options) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_SERVICE_REQUEST_LISTING });
    return dispatch(API.admin.getServiceRequestListing(token, options)).then(
      response => {
        return response;
      }
    );
  },
  getServiceRequestItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_SERVICE_REQUEST_ITEM });
    return dispatch(API.admin.getServiceRequestItem(token, id)).then(
      response => {
        dispatch({
          type: ActionKeys.RECEIVE_SERVICE_REQUEST_ITEM,
          isSuccess: response.isSuccess,
          message: response.message
        });
        return response;
      }
    );
  },
  getIncident: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_INCIDENT });
    return dispatch(API.admin.getIncident(token, id)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_INCIDENT,
        isSuccess: response.isSuccess,
        message: response.message
      });
      return response;
    });
  },
  updateIncident: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPDATE_INCIDENT });
    return dispatch(API.admin.updateIncident(token, item)).then(response => {
      dispatch({
        type: ActionKeys.UPDATED_INCIDENT,
        isSuccess: response.isSuccess,
        isLoading: false,
        message: response.message
      });
      return response;
    });
  },
  getPricingListing: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_PRICING_LISTING });
    return dispatch(API.admin.getPricingListing(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_PRICING_LISTING,
        isSuccess: response.isSuccess,
        isLoading: false,
        items: response.items,
        message: response.message
      });
    });
  },
  getDaDeptListing: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DA_DEPT_LISTING });
    return dispatch(API.admin.getDaDeptListing(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_DA_DEPT_LISTING,
        isSuccess: response.isSuccess,
        isLoading: false,
        items: response.items,
        message: response.message
      });
    });
  },
  getDaDeptItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DA_DEPT_ITEM });
    return dispatch(API.admin.getDaDeptItem(token, id)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_DA_DEPT_ITEM,
        isSuccess: response.isSuccess,
        isLoading: false,
        item: response.item,
        message: response.message
      });
    });
  },
  updateDaDeptItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPDATE_DA_DEPT_ITEM });
    return dispatch(API.admin.updateDaDeptItem(token, item)).then(response => {
      dispatch({
        type: ActionKeys.UPDATED_DA_DEPT_ITEM,
        isSuccess: response.isSuccess,
        isLoading: false,
        message: response.message
      });
    });
  },
  insertDaDeptItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.INSERT_DA_DEPT_ITEM });
    return dispatch(API.admin.insertDaDeptItem(token, item)).then(response => {
      dispatch({
        type: ActionKeys.INSERTED_DA_DEPT_ITEM,
        isSuccess: response.isSuccess,
        isLoading: false,
        message: response.message
      });
    });
  },
  deleteDaDeptItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.DELETE_DA_DEPT_ITEM });
    return dispatch(API.admin.deleteDaDeptItem(token, id)).then(response => {
      dispatch({
        type: ActionKeys.DELETED_DA_DEPT_ITEM,
        isSuccess: response.isSuccess,
        isLoading: false,
        message: response.message
      });
    });
  },
  getDaBomCommentsListing: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DA_BOMCOMMENTS_LISTING });
    return dispatch(API.admin.getDaBomCommentsListing(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_DA_BOMCOMMENTS_LISTING,
        isSuccess: response.isSuccess,
        isLoading: false,
        items: response.items,
        message: response.message
      });
    });
  },
  getDaBomCommentsItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DA_BOMCOMMENTS_ITEM });
    return dispatch(API.admin.getDaBomCommentsItem(token, id)).then(
      response => {
        dispatch({
          type: ActionKeys.RECEIVE_DA_BOMCOMMENTS_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          item: response.item,
          message: response.message
        });
      }
    );
  },
  updateDaBomCommentsItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPDATE_DA_BOMCOMMENTS_ITEM });
    return dispatch(API.admin.updateDaBomCommentsItem(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.UPDATED_DA_BOMCOMMENTS_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  insertDaBomCommentsItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.INSERT_DA_BOMCOMMENTS_ITEM });
    return dispatch(API.admin.insertDaBomCommentsItem(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.INSERTED_DA_BOMCOMMENTS_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  deleteDaBomCommentsItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.DELETE_DA_BOMCOMMENTS_ITEM });
    return dispatch(API.admin.deleteDaBomCommentsItem(token, id)).then(
      response => {
        dispatch({
          type: ActionKeys.DELETED_DA_BOMCOMMENTS_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  getDaMfgCommentsListing: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DA_MFGCOMMENTS_LISTING });
    return dispatch(API.admin.getDaMfgCommentsListing(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_DA_MFGCOMMENTS_LISTING,
        isSuccess: response.isSuccess,
        isLoading: false,
        items: response.items,
        message: response.message
      });
    });
  },
  getDaMfgCommentsItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DA_MFGCOMMENTS_ITEM });
    return dispatch(API.admin.getDaMfgCommentsItem(token, id)).then(
      response => {
        dispatch({
          type: ActionKeys.RECEIVE_DA_MFGCOMMENTS_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          item: response.item,
          message: response.message
        });
      }
    );
  },
  updateDaMfgCommentsItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPDATE_DA_MFGCOMMENTS_ITEM });
    return dispatch(API.admin.updateDaMfgCommentsItem(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.UPDATED_DA_MFGCOMMENTS_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  insertDaMfgCommentsItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.INSERT_DA_MFGCOMMENTS_ITEM });
    return dispatch(API.admin.insertDaMfgCommentsItem(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.INSERTED_DA_MFGCOMMENTS_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  deleteDaMfgCommentsItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.DELETE_DA_MFGCOMMENTS_ITEM });
    return dispatch(API.admin.deleteDaMfgCommentsItem(token, id)).then(
      response => {
        dispatch({
          type: ActionKeys.DELETED_DA_MFGCOMMENTS_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },

  getDaOrderEntryListing: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DA_ORDERENTRY_LISTING });
    return dispatch(API.admin.getDaOrderEntryListing(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_DA_ORDERENTRY_LISTING,
        isSuccess: response.isSuccess,
        isLoading: false,
        items: response.items,
        message: response.message
      });
    });
  },
  getDaOrderEntryItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DA_ORDERENTRY_ITEM });
    return dispatch(API.admin.getDaOrderEntryItem(token, id)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_DA_ORDERENTRY_ITEM,
        isSuccess: response.isSuccess,
        isLoading: false,
        item: response.item,
        message: response.message
      });
    });
  },
  updateDaOrderEntryItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPDATE_DA_ORDERENTRY_ITEM });
    return dispatch(API.admin.updateDaOrderEntryItem(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.UPDATED_DA_ORDERENTRY_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  insertDaOrderEntryItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.INSERT_DA_ORDERENTRY_ITEM });
    return dispatch(API.admin.insertDaOrderEntryItem(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.INSERTED_DA_ORDERENTRY_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  deleteDaOrderEntryItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.DELETE_DA_ORDERENTRY_ITEM });
    return dispatch(API.admin.deleteDaOrderEntryItem(token, id)).then(
      response => {
        dispatch({
          type: ActionKeys.DELETED_DA_ORDERENTRY_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  getDaWarrantyListing: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DA_WARRANTY_LISTING });
    return dispatch(API.admin.getDaWarrantyListing(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_DA_WARRANTY_LISTING,
        isSuccess: response.isSuccess,
        isLoading: false,
        items: response.items,
        message: response.message
      });
    });
  },
  getPricingCategories: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_PRICING_CATEGORIES });
    return dispatch(API.admin.getPricingCategories(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_PRICING_CATEGORIES,
        isSuccess: response.isSuccess,
        isLoading: false,
        items: response.items,
        message: response.message
      });
    });
  },
  updateAdminToken: token => async (dispatch, getState) => {
    return dispatch({
      type: ActionKeys.UPDATED_USER_TOKEN,
      token: token
    });
  },
  updateLoggedInUser: user => async (dispatch, getState) => {
    return dispatch({
      type: ActionKeys.UPDATED_LOGGED_IN_USER,
      user: user
    });
  },
  getPhoneTypes: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_PHONE_TYPES });
    return dispatch(API.admin.getPhoneTypes(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_PHONE_TYPES,
        isSuccess: response.isSuccess,
        isLoading: false,
        items: response.items,
        message: response.message
      });
    });
  },
  getDaAcctPrefListing: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DA_ACCTPREF_LISTING });
    return dispatch(API.admin.getDaAcctPrefListing(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_DA_ACCTPREF_LISTING,
        isSuccess: response.isSuccess,
        isLoading: false,
        items: response.items,
        message: response.message
      });
    });
  },
  getDaAcctPrefItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DA_ACCTPREF_ITEM });
    return dispatch(API.admin.getDaAcctPrefItem(token, id)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_DA_ACCTPREF_ITEM,
        isSuccess: response.isSuccess,
        isLoading: false,
        item: response.item,
        message: response.message
      });
    });
  },
  updateDaAcctPrefItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPDATE_DA_ACCTPREF_ITEM });
    return dispatch(API.admin.updateDaAcctPrefItem(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.UPDATED_DA_ACCTPREF_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  insertDaAcctPrefItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.INSERT_DA_ACCTPREF_ITEM });
    return dispatch(API.admin.insertDaAcctPrefItem(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.INSERTED_DA_ACCTPREF_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  deleteDaAcctPrefItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.DELETE_DA_ACCTPREF_ITEM });
    return dispatch(API.admin.deleteDaAcctPrefItem(token, id)).then(
      response => {
        dispatch({
          type: ActionKeys.DELETED_DA_ACCTPREF_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  getDdsProjectListing: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DDS_PROJECT_LISTING });
    return dispatch(API.admin.getDdsProjectListing(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_DDS_PROJECT_LISTING,
        isSuccess: response.isSuccess,
        isLoading: false,
        items: response.items,
        message: response.message
      });
    });
  },
  getDdsProjectItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DDS_PROJECT_ITEM });
    return dispatch(API.admin.getDdsProjectItem(token, id)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_DDS_PROJECT_ITEM,
        isSuccess: response.isSuccess,
        isLoading: false,
        item: response.item,
        message: response.message
      });
    });
  },
  updateDdsProjectItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPDATE_DDS_PROJECT_ITEM });
    return dispatch(API.admin.updateDdsProjectItem(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.UPDATED_DDS_PROJECT_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  insertDdsProjectItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.INSERT_DDS_PROJECT_ITEM });
    return dispatch(API.admin.insertDdsProjectItem(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.INSERTED_DDS_PROJECT_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  deleteDdsProjectItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.DELETE_DDS_PROJECT_ITEM });
    return dispatch(API.admin.deleteDdsProjectItem(token, id)).then(
      response => {
        dispatch({
          type: ActionKeys.DELETED_DDS_PROJECT_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  getDaApprovalUnitListing: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DA_APPROVALUNIT_LISTING });
    return dispatch(API.admin.getDaApprovalUnitListing(token)).then(
      response => {
        dispatch({
          type: ActionKeys.RECEIVE_DA_APPROVALUNIT_LISTING,
          isSuccess: response.isSuccess,
          isLoading: false,
          items: response.items,
          message: response.message
        });
      }
    );
  },
  getDaApprovalUnitItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DA_APPROVALUNIT_ITEM });
    return dispatch(API.admin.getDaApprovalUnitItem(token, id)).then(
      response => {
        dispatch({
          type: ActionKeys.RECEIVE_DA_APPROVALUNIT_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          item: response.item,
          message: response.message
        });
      }
    );
  },
  updateDaApprovalUnitItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPDATE_DA_APPROVALUNIT_ITEM });
    return dispatch(API.admin.updateDaApprovalUnitItem(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.UPDATED_DA_APPROVALUNIT_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  insertDaApprovalUnitItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.INSERT_DA_APPROVALUNIT_ITEM });
    return dispatch(API.admin.insertDaApprovalUnitItem(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.INSERTED_DA_APPROVALUNIT_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  deleteDaApprovalUnitItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.DELETE_DA_APPROVALUNIT_ITEM });
    return dispatch(API.admin.deleteDaApprovalUnitItem(token, id)).then(
      response => {
        dispatch({
          type: ActionKeys.DELETED_DA_APPROVALUNIT_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  getDaApprovedForListing: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DA_APPROVEDFOR_LISTING });
    return dispatch(API.admin.getDaApprovedForListing(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_DA_APPROVEDFOR_LISTING,
        isSuccess: response.isSuccess,
        isLoading: false,
        items: response.items,
        message: response.message
      });
    });
  },
  getDaApprovedForItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DA_APPROVEDFOR_ITEM });
    return dispatch(API.admin.getDaApprovedForItem(token, id)).then(
      response => {
        dispatch({
          type: ActionKeys.RECEIVE_DA_APPROVEDFOR_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          item: response.item,
          message: response.message
        });
      }
    );
  },
  updateDaApprovedForItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPDATE_DA_APPROVEDFOR_ITEM });
    return dispatch(API.admin.updateDaApprovedForItem(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.UPDATED_DA_APPROVEDFOR_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  insertDaApprovedForItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.INSERT_DA_APPROVEDFOR_ITEM });
    return dispatch(API.admin.insertDaApprovedForItem(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.INSERTED_DA_APPROVEDFOR_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  deleteDaApprovedForItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.DELETE_DA_APPROVEDFOR_ITEM });
    return dispatch(API.admin.deleteDaApprovedForItem(token, id)).then(
      response => {
        dispatch({
          type: ActionKeys.DELETED_DA_APPROVEDFOR_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  getDDSTechniciansByDate: (token, fromDate, toDate) => async () => {
    return API.admin
      .getDDSTechniciansByDate(token, fromDate, toDate)
      .then(response => {
        return response;
      });
  },
  getDDSTechReportData:
    (token, techId, type, fromDate, toDate) => async (dispatch, getState) => {
      dispatch({
        type: ActionKeys.REQUEST_DDSTECHREPORT_DATA
      });
      return dispatch(
        API.admin.getDDSTechReportData(token, techId, type, fromDate, toDate)
      ).then(response => {
        dispatch({
          type: ActionKeys.RECIEVE_DDSTECHREPORT_DATA,
          isSuccess: response.isSuccess,
          message: response.message
        });
        return response;
      });
    },
  getDDSAccountReportData:
    (token, account, type, fromDate, toDate) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.REQUEST_DDSACCOUNTREPORT_DATA });
      return dispatch(
        API.admin.getDDSAccountReportData(
          token,
          account,
          type,
          fromDate,
          toDate
        )
      ).then(response => {
        dispatch({
          type: ActionKeys.RECIEVE_DDSACCOUNTREPORT_DATA,
          isSuccess: response.isSuccess,
          message: response.message
        });
        return response;
      });
    },
  getDDSDepartmentReporData:
    (token, fromDate, toDate) => async (dispatch, getState) => {
      dispatch({
        type: ActionKeys.REQUEST_DDSDEPARTMENTREPORT_DATA,
        isSuccess: response.isSuccess,
        message: response.message
      });
      return dispatch(
        API.admin.getDDSDepartmentReportData(token, fromDate, toDate)
      ).then(response => {
        dispatch({
          type: ActionKeys.RECIEVE_DDSDEPARTMENTREPORT_DATA,
          isSuccess: response.isSuccess,
          message: response.message
        });
        return response;
      });
    },
  getDDSPriorityReportData:
    (token, fromDate, toDate) => async (dispatch, getState) => {
      dispatch({
        type: ActionKeys.REQUEST_DDSPRIORITYREPORT_DATA,
        isSuccess: response.isSuccess,
        message: response.message
      });
      return dispatch(
        API.admin.getDDSPriorityReportData(token, fromDate, toDate)
      ).then(response => {
        dispatch({
          type: ActionKeys.RECIEVE_DDSPRIORITYREPORT_DATA,
          isSuccess: response.isSuccess,
          message: response.message
        });
        return response;
      });
    },
  getRemittanceItems: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_REMITTANCE_ITEMS });
    return dispatch(API.admin.getRemittanceItems(token)).then(response => {
      return response;
    });
  },
  getRemittanceItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_REMITTANCE_ITEM });
    return dispatch(API.admin.getRemittanceDetails(token, id)).then(
      response => {
        dispatch({
          type: ActionKeys.RECEIVE_REMITTANCE_ITEM,
          isSuccess: response.isSuccess,
          message: response.message
        });
        return response;
      }
    );
  },
  getRemittanceInvoiceItems: (token, remitId) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_REMITTANCE_INVOICE_ITEMS });
    return dispatch(API.admin.getRemittanceInvoiceItems(token, remitId)).then(
      response => {
        return response;
      }
    );
  },
  actionRemittance: (token, id, action) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.ACTION_REMITTANCE });
    return dispatch(API.admin.actionRemittance(token, id, action)).then(
      response => {
        dispatch({
          type: ActionKeys.ACTIONED_REMITTANCE,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  updateRemittance: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPDATE_REMITTANCE });
    return dispatch(API.admin.updateRemittance(token, item)).then(response => {
      dispatch({
        type: ActionKeys.UPDATED_REMITTANCE,
        isSuccess: response.isSuccess,
        isLoading: false,
        message: response.message
      });
      return response;
    });
  },
  updateRemittanceInvoice: (token, body) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPDATE_REMITTANCE_INVOICE });
    return dispatch(API.admin.updateRemittanceInvoice(token, body)).then(
      response => {
        return response;
      }
    );
  },
  getAdminRoles: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_ADMIN_ROLES });
    return dispatch(API.admin.getAdminRoles(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_ADMIN_ROLES,
        isSuccess: response.isSuccess,
        isLoading: false,
        items: response.items,
        message: response.message
      });
    });
  },
  downloadServiceRequestImage:
    (token, id, filename) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.DOWNLOAD_SERVICE_REQUEST_IMAGE });
      return dispatch(
        API.admin.downloadServiceRequestImage(token, id, filename)
      ).then(response => {
        if (response.status === 200) {
          FileDownload(response.data, filename);
        }

        return response.isError ? response.errorMessage : true;
      });
    },
  downloadServiceRequestImageInline:
    (token, id, filename) => (dispatch, getState) => {
      dispatch({ type: ActionKeys.DOWNLOAD_SERVICE_REQUEST_IMAGE });
      return dispatch(
        API.admin.downloadServiceRequestImage(token, id, filename)
      ).then(response => {
        if (response?.status === 200) {
          return response.data;
        }
        return false;
      });
    },
  uploadServiceRequestAttachment:
    (token, id, data) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.UPLOAD_SERVICE_REQUEST_ATTACHMENT });
      return dispatch(
        API.admin.uploadServiceRequestAttachment(token, id, data)
      ).then(response => {
        dispatch({
          type: ActionKeys.UPLOADED_SERVICE_REQUEST_ATTACHMENT,
          isSuccess: response.isSuccess,
          isUploading: false,
          message: response.message
        });
        return response;
      });
    },
  deleteServiceRequestAttachment:
    (token, id, requestId) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.DELETE_SERVICE_REQUEST_ATTACHMENT });
      return dispatch(
        API.admin.deleteServiceRequestAttachment(token, id, requestId)
      ).then(response => {
        dispatch({
          type: ActionKeys.DELETED_SERVICE_REQUEST_ATTACHMENT,
          isSuccess: response.isSuccess,
          isUploading: false,
          message: response.message
        });
        return response;
      });
    },
  getShippingMethods: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_SHIPPING_METHODS });
    return dispatch(API.admin.getShippingMethods(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_SHIPPING_METHODS,
        isSuccess: response.isSuccess,
        items: response.items,
        message: response.message,
        count: response.count
      });
    });
  },
  getPriorityLevels: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_PRIORITY_LEVELS });
    return dispatch(API.admin.getPriorityLevels(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_PRIORITY_LEVELS,
        isSuccess: response.isSuccess,
        items: response.items,
        message: response.message,
        count: response.count
      });
    });
  },
  getPriorityTypes: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_PRIORITY_TYPES });
    return dispatch(API.admin.getPriorityTypes(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_PRIORITY_TYPES,
        isSuccess: response.isSuccess,
        items: response.items,
        message: response.message,
        count: response.count
      });
    });
  },
  getAdditionalCrewReasons: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_ADDITIONAL_CREW_REASONS });
    return dispatch(API.admin.getAdditionalCrewReasons(token)).then(
      response => {
        dispatch({
          type: ActionKeys.RECEIVE_ADDITIONAL_CREW_REASONS,
          isSuccess: response.isSuccess,
          items: response.items,
          message: response.message,
          count: response.count
        });
      }
    );
  },
  getServiceRequestCrews: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_CREWS });
    return dispatch(API.admin.getServiceRequestCrews(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_CREWS,
        isSuccess: response.isSuccess,
        items: response.items,
        message: response.message,
        count: response.count
      });
    });
  },
  getServiceAdvisors: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_SERVICE_ADVISORS });
    return dispatch(API.admin.getServiceAdvisors(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_SERVICE_ADVISORS,
        isSuccess: response.isSuccess,
        items: response.items,
        message: response.message,
        count: response.count
      });
    });
  },
  insertServiceRequest: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.INSERT_SERVICE_REQUEST });
    return dispatch(API.admin.insertServiceRequest(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.INSERTED_SERVICE_REQUEST,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message,
          id: response.id
        });
      }
    );
  },
  updateServiceRequest: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPDATE_SERVICE_REQUEST });
    return dispatch(API.admin.updateServiceRequest(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.UPDATED_SERVICE_REQUEST,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message,
          id: response.id
        });
        return response;
      }
    );
  },
  actionServiceRequest:
    (token, id, action, reason, crewNumber) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.ACTION_SERVICE_REQUEST });
      return dispatch(
        API.admin.actionServiceRequest(token, id, action, reason, crewNumber)
      ).then(response => {
        dispatch({
          type: ActionKeys.ACTIONED_SERVICE_REQUEST,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
        return response;
      });
    },
  dispatchCompleteEmail: (token, requestId) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.ACTION_SERVICE_REQUEST });
    return dispatch(API.admin.dispatchCompleteEmail(token, requestId)).then(
      response => {
        dispatch({
          type: ActionKeys.ACTIONED_SERVICE_REQUEST,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
        return response;
      }
    );
  },
  dispatchMoreInfoEmail: (token, body) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.ACTION_SERVICE_REQUEST });
    return dispatch(API.admin.dispatchMoreInfoEmail(token, body)).then(
      response => {
        dispatch({
          type: ActionKeys.ACTIONED_SERVICE_REQUEST,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
        return response;
      }
    );
  },
  getServiceRequestYears: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_SERVICE_REQUEST_YEARS });
    return dispatch(API.admin.getServiceRequestYears(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_SERVICE_REQUEST_YEARS,
        isSuccess: response.isSuccess,
        items: response.items,
        message: response.message,
        count: response.count
      });
      return response;
    });
  },
  assignServiceRequestTech:
    (token, id, serviceAdvisorId) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.ASSIGN_SERVICE_REQUEST_TECH });
      return dispatch(
        API.admin.assignServiceRequestTech(token, id, serviceAdvisorId)
      ).then(response => {
        dispatch({
          type: ActionKeys.ASSIGNED_SERVICE_REQUEST_TECH,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
        return response;
      });
    },
  getProductWizard: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_PRODUCT_WIZARD });
    return dispatch(API.admin.getProductWizard(token, item)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_PRODUCT_WIZARD,
        isLoading: false,
        item: response.item
      });
    });
  },
  getIncidentWizard: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_INCIDENT_WIZARD });
    return dispatch(API.admin.getIncidentWizard(token, item)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_INCIDENT_WIZARD
      });
      return response;
    });
  },
  getOrderLineItems: (token, id, isQuote) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_ORDER_LINE_ITEMS });
    return dispatch(API.admin.getOrderLineItems(token, id, isQuote)).then(
      response => {
        dispatch({
          type: ActionKeys.RECEIVE_ORDER_LINE_ITEMS,
          isSuccess: response.isSuccess,
          items: response.items,
          message: response.message,
          count: response.count
        });
        return response;
      }
    );
  },
  getOrderLineItemDetails:
    (token, id, lineNumber) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.REQUEST_ORDER_LINE_ITEM_DETAILS });
      return dispatch(
        API.admin.getOrderLineItemDetails(token, id, lineNumber)
      ).then(response => {
        return response.items;
      });
    },
  getIncidentServiceTypes: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_INCIDENT_SERVICE_TYPES });
    return dispatch(API.admin.getIncidentServiceTypes(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_INCIDENT_SERVICE_TYPES,
        isSuccess: response.isSuccess,
        items: response.items,
        message: response.message,
        count: response.count
      });
    });
  },
  getProjectRequestHeaders: (token, filter) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_PROJECT_REQUEST_HEADERS });
    return dispatch(API.admin.getProjectRequestHeaders(token, filter)).then(
      response => {
        return response;
      }
    );
  },
  actionProjectRequest:
    (token, action, requestId) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.ACTION_PROJECT_REQUEST });
      return dispatch(
        API.admin.actionProjectRequest(token, action, requestId)
      ).then(response => {
        dispatch({
          type: ActionKeys.ACTIONED_PROJECT_REQUEST,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      });
    },
  completeProjectRequest: (token, requestId) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.COMPLETE_PROJECT_REQUEST });
    return dispatch(API.admin.completeProjectRequest(token, requestId)).then(
      response => {
        dispatch({
          type: ActionKeys.COMPLETED_PROJECT_REQUEST,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
        return response;
      }
    );
  },
  getProjectRequest: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_PROJECT_REQUEST });
    return dispatch(API.admin.getProjectRequest(token, id)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_PROJECT_REQUEST,
        isSuccess: response.isSuccess,
        message: response.message
      });
      return response;
    });
  },
  beginLoadProjectRequest: () => (dispatch, state) => {
    dispatch({ type: ActionKeys.BEGIN_LOAD_PROJECT_REQUEST });
  },
  endLoadProjectRequest: () => (dispatch, state) => {
    dispatch({ type: ActionKeys.BEGIN_LOAD_PROJECT_REQUEST });
  },
  getProjectRequestRevisions:
    (token, projectId) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.REQUEST_PROJECT_REQUEST_REVISIONS });
      return dispatch(
        API.admin.getProjectRequestRevisions(token, projectId)
      ).then(response => {
        dispatch({
          type: ActionKeys.RECEIVE_PROJECT_REQUEST_REVISIONS,
          isSuccess: response.isSuccess,
          message: response.message
        });
        return response;
      });
    },
  getRequestTypes: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_REQUEST_TYPES });
    return dispatch(API.admin.getRequestTypes(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_REQUEST_TYPES,
        isSuccess: response.isSuccess,
        items: response.items,
        message: response.message
      });
    });
  },
  getRequestStatuses: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_REQUEST_STATUSES });
    return dispatch(API.admin.getRequestStatuses(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_REQUEST_STATUSES,
        isSuccess: response.isSuccess,
        items: response.items,
        message: response.message
      });
    });
  },
  getIncidents: (token, id, orderNumber) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_INCIDENTS });
    return dispatch(API.admin.getIncidents(token, id, orderNumber)).then(
      response => {
        return response.items;
      }
    );
  },
  updateProjectRequest: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPDATE_PROJECT_REQUEST });
    return dispatch(API.admin.updateProjectRequest(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.UPDATED_PROJECT_REQUEST,
          isSuccess: response.isSuccess,
          message: response.message
        });
      }
    );
  },
  resolveOrphanedRequest: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.RESOLVE_PROJECT_REQUEST });
    return dispatch(API.admin.resolveOrphanedRequest(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.RESOLVED_PROJECT_REQUEST,
          isSuccess: response.isSuccess,
          message: response.message
        });
        return response;
      }
    );
  },
  downloadProjectRequestAttachmentInline:
    (token, id, filename, isInternal) => (dispatch, getState) => {
      dispatch({ type: ActionKeys.DOWNLOAD_PROJECT_REQUEST_ATTACHMENT });
      return dispatch(
        API.admin.downloadProjectRequestAttachment(
          token,
          id,
          filename,
          isInternal
        )
      ).then(response => {
        if (response?.status === 200) {
          return response.data;
        }
        return false;
      });
    },
  downloadProjectRequestAttachment:
    (token, id, filename, isInternal) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.DOWNLOAD_PROJECT_REQUEST_ATTACHMENT });
      return dispatch(
        API.admin.downloadProjectRequestAttachment(
          token,
          id,
          filename,
          isInternal
        )
      ).then(response => {
        dispatch({
          type: ActionKeys.DOWNLOADED_PROJECT_REQUEST_ATTACHMENT,
          isSuccess: response.isSuccess,
          message: response.message
        });
        if (response?.status === 200) {
          FileDownload(response.data, filename);
        }
        return response?.isSuccess;
      });
    },
  deleteProjectRequestAttachment:
    (token, id, requestId, projectId) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.DELETE_PROJECT_REQUEST_ATTACHMENT });
      return dispatch(
        API.admin.deleteProjectRequestAttachment(
          token,
          id,
          requestId,
          projectId
        )
      ).then(response => {
        dispatch({
          type: ActionKeys.DELETED_PROJECT_REQUEST_ATTACHMENT,
          isSuccess: response.isSuccess,
          message: response.message
        });
      });
    },
  uploadProjectRequestAttachment:
    (token, id, data, isQuote, isInternal) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.UPLOAD_PROJECT_REQUEST_ATTACHMENT });
      return dispatch(
        API.admin.uploadProjectRequestAttachment(
          token,
          id,
          data,
          isQuote,
          isInternal
        )
      ).then(response => {
        dispatch({
          type: ActionKeys.UPLOADED_PROJECT_REQUEST_ATTACHMENT,
          isSuccess: response.isSuccess,
          isUploading: false,
          message: response.message
        });
        return response;
      });
    },
  getProjectRequestDepartments:
    (token, requestId) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.REQUEST_PROJECT_REQUEST_DEPARTMENTS });
      return dispatch(
        API.admin.getProjectRequestDepartments(token, requestId)
      ).then(response => {
        return response;
      });
    },
  insertProjectRequestDepartment:
    (token, body) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.INSERT_PROJECT_REQUEST_DEPARTMENT });
      return dispatch(
        API.admin.insertProjectRequestDepartment(token, body)
      ).then(response => {
        return response;
      });
    },
  deleteProjectRequestDepartment:
    (token, requestId, id) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.DELETE_PROJECT_REQUEST_DEPARTMENT });
      return dispatch(
        API.admin.deleteProjectRequestDepartment(token, requestId, id)
      ).then(response => {
        return response;
      });
    },
  updateProjectRequestDepartment:
    (token, body, isResponse) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.UPDATE_PROJECT_REQUEST_DEPARTMENT });
      return dispatch(
        API.admin.updateProjectRequestDepartment(token, body, isResponse)
      ).then(response => {
        return response;
      });
    },
  uploadProjectRequestDepartmentAttachment:
    (token, id, data) => async (dispatch, getState) => {
      dispatch({
        type: ActionKeys.UPLOAD_PROJECT_REQUEST_DEPARTMENT_ATTACHMENT
      });
      return dispatch(
        API.admin.uploadProjectRequestDepartmentAttachment(token, id, data)
      ).then(response => {
        dispatch({
          type: ActionKeys.UPLOADED_PROJECT_REQUEST_DEPARTMENT_ATTACHMENT,
          isSuccess: response.isSuccess,
          isDepartmentUploading: false,
          message: response.message
        });
      });
    },
  downloadProjectRequestDepartmentAttachment:
    (token, id, filename) => async (dispatch, getState) => {
      dispatch({
        type: ActionKeys.DOWNLOAD_PROJECT_REQUEST_DEPARTMENT_ATTACHMENT
      });
      return dispatch(
        API.admin.downloadProjectRequestDepartmentAttachment(
          token,
          id,
          filename
        )
      );
    },
  getDAPListing: (token, projectRequestId) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DAP_LISTING });
    return dispatch(API.admin.getDAPListing(token, projectRequestId)).then(
      response => {
        return response;
      }
    );
  },
  getDAPItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DAP_ITEM });
    return dispatch(API.admin.getDAPItem(token, id)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_DAP_ITEM,
        isSuccess: response.isSuccess,
        message: response.message
      });
      return response;
    });
  },
  updateDAPItem:
    (token, item, drawingChanged, approvalChanged, pricingChanged) =>
    async (dispatch, getState) => {
      dispatch({ type: ActionKeys.UPDATE_DAP_ITEM });
      return dispatch(
        API.admin.updateDapItem(
          token,
          item,
          drawingChanged,
          approvalChanged,
          pricingChanged
        )
      ).then(response => {
        dispatch({
          type: ActionKeys.UPDATED_DAP_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
        return response;
      });
    },
  insertDapItem:
    (token, item, drawingChanged, approvalChanged, pricingChanged) =>
    async (dispatch, getState) => {
      dispatch({ type: ActionKeys.INSERT_DAP_ITEM });
      return dispatch(
        API.admin.insertDapItem(
          token,
          item,
          drawingChanged,
          approvalChanged,
          pricingChanged
        )
      ).then(response => {
        dispatch({
          type: ActionKeys.INSERTED_DAP_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message,
          id: response.id
        });
        return response;
      });
    },
  cloneDapItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.CLONE_DAP_ITEM });
    return dispatch(API.admin.cloneDapItem(token, item)).then(response => {
      dispatch({
        type: ActionKeys.CLONED_DAP_ITEM,
        isSuccess: response.isSuccess,
        isLoading: false,
        message: response.message
      });
      return response;
    });
  },
  uploadDapAttachment:
    (token, id, data, fileType) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.UPLOAD_DAP_ATTACHMENT });
      return dispatch(
        API.admin.uploadDapAttachment(token, id, data, fileType)
      ).then(response => {
        dispatch({
          type: ActionKeys.UPLOADED_DAP_ATTACHMENT,
          isSuccess: response.isSuccess,
          isUploading: false,
          message: response.message
        });
        return response;
      });
    },
  downloadDapAttachmentInline:
    (token, id, filename, fileType) => (dispatch, getState) => {
      dispatch({ type: ActionKeys.DOWNLOAD_DAP_ATTACHMENT });
      return dispatch(
        API.admin.downloadDapAttachment(token, id, filename, fileType)
      ).then(response => {
        if (response?.status === 200) {
          return response.data;
        }
        return false;
      });
    },
  downloadDapAttachment:
    (token, id, filename, fileType) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.DOWNLOAD_DAP_ATTACHMENT });
      return dispatch(
        API.admin.downloadDapAttachment(token, id, filename, fileType)
      ).then(response => {
        dispatch({
          type: ActionKeys.DOWNLOADED_DAP_ATTACHMENT,
          isSuccess: response.isSuccess,
          message: response.message
        });
        if (response?.status === 200) {
          FileDownload(response.data, filename);
        }
        return response?.isSuccess;
      });
    },
  deleteDapAttachment: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.DELETE_DAP_ATTACHMENT });
    return dispatch(API.admin.deleteDapAttachment(token, id)).then(response => {
      dispatch({
        type: ActionKeys.DELETED_DAP_ATTACHMENT,
        isSuccess: response.isSuccess,
        message: response.message
      });
      return response;
    });
  },
  getDapTypes: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DAP_TYPES });
    return dispatch(API.admin.getDapTypes(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_DAP_TYPES,
        isSuccess: response.isSuccess,
        items: response.items,
        message: response.message
      });
    });
  },
  getProcessTypes: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DAP_PROCESS });
    return dispatch(API.admin.getProcessTypes(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_DAP_PROCESS,
        isSuccess: response.isSuccess,
        items: response.items,
        message: response.message
      });
    });
  },
  getQuickSelectUnits: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_QUICK_SELECT_UNITS });
    return dispatch(API.admin.getQuickSelectUnits(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_QUICK_SELECT_UNITS,
        isSuccess: response.isSuccess,
        items: response.items,
        message: response.message
      });
    });
  },
  getRequestLineItems: (token, requestId) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_LINE_ITEMS });
    return dispatch(API.admin.getRequestLineItems(token, requestId)).then(
      response => {
        dispatch({
          type: ActionKeys.RECEIVE_LINE_ITEMS,
          isSuccess: response.isSuccess,
          items: response.items,
          message: response.message
        });
      }
    );
  },
  getItemNumber: (token, requestId) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_ITEM_NUMBER });
    return dispatch(API.admin.getItemNumber(token, requestId)).then(
      response => {
        dispatch({
          type: ActionKeys.RECEIVE_ITEM_NUMBER,
          isSuccess: response.isSuccess,
          isLoading: false,
          item: response.item,
          message: response.message
        });
        return response;
      }
    );
  },
  downloadDapPrint:
    (token, requestId, dapIds, filename) => async (dispatch, getState) => {
      return dispatch(
        API.admin.downloadDapPrint(token, requestId, dapIds)
      ).then(response => {
        if (response.status === 200) {
          FileDownload(response.data, filename);
        }

        return response.isError ? response.errorMessage : true;
      });
    },
  updateAdditionalPrice:
    (token, projectId, body) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.UPDATE_PROJECT_VALUE });
      return dispatch(
        API.admin.updateAdditionalPrice(token, projectId, body)
      ).then(response => {
        dispatch({
          type: ActionKeys.UPDATED_PROJECT_VALUE,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      });
    },
  getAccountQuotes:
    (token, account, requestId) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.REQUEST_ACCOUNT_QUOTES });
      return dispatch(
        API.admin.getAccountQuotes(token, account, requestId)
      ).then(response => {
        dispatch({
          type: ActionKeys.RECEIVE_ACCOUNT_QUOTES,
          isSuccess: response.isSuccess,
          isLoading: false,
          items: response.items,
          message: response.message
        });
        return response;
      });
    },
  getAccountOrders:
    (token, account, requestId) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.REQUEST_ACCOUNT_ORDERS });
      return dispatch(
        API.admin.getAccountOrders(token, account, requestId)
      ).then(response => {
        dispatch({
          type: ActionKeys.RECEIVE_ACCOUNT_ORDERS,
          isSuccess: response.isSuccess,
          isLoading: false,
          items: response.items,
          message: response.message
        });
        return response;
      });
    },
  getChangeRequestItems: (token, options) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_CHANGE_ITEMS });
    return dispatch(API.admin.getChangeRequestItems(token, options)).then(
      response => {
        return response;
      }
    );
  },
  getDealerLookup: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DEALER_LOOKUP });
    return dispatch(API.admin.getDealerLookup(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_DEALER_LOOKUP,
        isSuccess: response.isSuccess,
        isLoading: false,
        items: response.items,
        message: response.message
      });
    });
  },
  getChangeRequestItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_CHANGE_ITEM });
    return dispatch(API.admin.getChangeRequestItem(token, id)).then(
      response => {
        dispatch({
          type: ActionKeys.RECEIVE_CHANGE_ITEM,
          isSuccess: response.isSuccess,
          message: response.message
        });
        return response;
      }
    );
  },
  updateChangeRequestItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPDATE_CHANGE_ITEM });
    return dispatch(API.admin.updateChangeRequestItem(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.UPDATED_CHANGE_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  updateChangeRequestDetails:
    (token, id, item) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.UPDATE_CHANGE_DETAILS });
      return dispatch(
        API.admin.updateChangeRequestDetails(token, id, item)
      ).then(response => {
        dispatch({
          type: ActionKeys.UPDATED_CHANGE_DETAILS,
          isSuccess: response.isSuccess,
          detailsLoading: false,
          message: response.message
        });
      });
    },
  verifyChangeRequestItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.VERIFY_CHANGE_ITEM });
    return dispatch(API.admin.verifyChangeRequestItem(token, id)).then(
      response => {
        dispatch({
          type: ActionKeys.VERIFIED_CHANGE_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  revertChangeRequestItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REVERT_CHANGE_ITEM });
    return dispatch(API.admin.revertChangeRequestItem(token, id)).then(
      response => {
        dispatch({
          type: ActionKeys.REVERTED_CHANGE_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  revertAmChangeRequestItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REVERT_AM_CHANGE_ITEM });
    return dispatch(API.admin.revertAmChangeRequestItem(token, id)).then(
      response => {
        dispatch({
          type: ActionKeys.REVERTED_AM_CHANGE_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      }
    );
  },
  attachRequestPlanningComment:
    (token, id, comment) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.PROD_COMMENT_CHANGE_ITEM });
      return dispatch(
        API.admin.attachRequestPlanningComment(token, id, comment)
      ).then(response => {
        dispatch({
          type: ActionKeys.PROD_COMMENTED_CHANGE_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      });
    },
  attachRequestAMComment:
    (token, id, comment, hours) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.AM_COMMENT_CHANGE_ITEM });
      return dispatch(
        API.admin.attachRequestAMComment(token, id, comment, hours)
      ).then(response => {
        dispatch({
          type: ActionKeys.AM_COMMENTED_CHANGE_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      });
    },
  forwardChangeRequestItem:
    (token, id, comment, forwardTo) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.FORWARD_CHANGE_ITEM });
      return dispatch(
        API.admin.forwardChangeRequestItem(token, id, comment, forwardTo)
      ).then(response => {
        dispatch({
          type: ActionKeys.FORWARDED_CHANGE_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      });
    },
  priceChangeRequestItem:
    (token, id, price, comment) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.PRICE_CHANGE_ITEM });
      return dispatch(
        API.admin.priceChangeRequestItem(token, id, price, comment)
      ).then(response => {
        dispatch({
          type: ActionKeys.PRICED_CHANGE_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      });
    },
  cancelChangeRequestItem:
    (token, id, comment) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.CANCEL_CHANGE_ITEM });
      return dispatch(
        API.admin.cancelChangeRequestItem(token, id, comment)
      ).then(response => {
        dispatch({
          type: ActionKeys.CANCELLED_CHANGE_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      });
    },
  supervisorActionChangeRequestItem:
    (token, id, isApproved) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.ACTION_CHANGE_ITEM });
      return dispatch(
        API.admin.supervisorActionChangeRequestItem(token, id, isApproved)
      ).then(response => {
        dispatch({
          type: ActionKeys.ACTIONED_CHANGE_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      });
    },
  supervisorAssignChangeRequestItem:
    (token, id, isApproved) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.ASSIGN_CHANGE_ITEM });
      return dispatch(
        API.admin.supervisorAssignChangeRequestItem(token, id)
      ).then(response => {
        dispatch({
          type: ActionKeys.ASSIGNED_CHANGE_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      });
    },
  completeChangeRequestItem:
    (token, id, comment) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.COMPLETE_CHANGE_ITEM });
      return dispatch(
        API.admin.completeChangeRequestItem(token, id, comment)
      ).then(response => {
        dispatch({
          type: ActionKeys.COMPLETED_CHANGE_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
      });
    },
  getChangeRequestUsers: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_CHANGE_USER_ITEMS });
    return dispatch(API.admin.getChangeRequestUsers(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_CHANGE_USER_ITEMS,
        items: response.items
      });
    });
  },
  getAdminLoadDates: (token, account) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_LOAD_DATES });
    return dispatch(API.admin.getAdminLoadDates(token, account)).then(
      response => {
        dispatch({
          type: ActionKeys.RECEIVE_LOAD_DATES,
          isSuccess: response.isSuccess,
          isLoading: false,
          items: response.items,
          message: response.message,
          count: response.count
        });
      }
    );
  },
  getSystemRoles: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_SYSTEM_ROLES });
    return dispatch(API.admin.getSystemRoles(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_SYSTEM_ROLES,
        isSuccess: response.isSuccess,
        items: response.items,
        message: response.message
      });
    });
  },
  getSystemSections: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_SYSTEM_SECTIONS });
    return dispatch(API.admin.getSystemSections(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_SYSTEM_SECTIONS,
        isSuccess: response.isSuccess,
        items: response.items,
        message: response.message
      });
    });
  },
  getKnowledgeItems: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_KNOWLEDGE_ITEMS });
    return dispatch(API.admin.getKnowledgeItems(token)).then(response => {
      return response;
    });
  },
  getKnowledgebaseCategories: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_KNOWLEDGE_CATEGORIES });
    return dispatch(API.admin.getKnowledgebaseCategories(token)).then(
      response => {
        dispatch({
          type: ActionKeys.RECEIVE_KNOWLEDGE_CATEGORIES,
          isSuccess: response.isSuccess,
          items: response.items,
          message: response.message
        });
      }
    );
  },
  getKnowledgeItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_KNOWLEDGE_ITEM });
    return dispatch(API.admin.getKnowledgeItem(token, id)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_KNOWLEDGE_ITEM,
        isSuccess: response.isSuccess,
        message: response.message
      });
      return response;
    });
  },
  getPageItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_PAGE_ITEM });
    return dispatch(API.admin.getPageItem(token, id)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_PAGE_ITEM,
        isSuccess: response.isSuccess,
        message: response.message
      });
      return response;
    });
  },
  updateKnowledgeItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPDATE_KNOWLEDGE_ITEM });
    return dispatch(API.admin.updateKnowledgebaseItem(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.UPDATED_KNOWLEDGE_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
        return response;
      }
    );
  },
  insertKnowledgeItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.INSERT_KNOWLEDGE_ITEM });
    return dispatch(API.admin.insertKnowledgebaseItem(token, item)).then(
      response => {
        dispatch({
          type: ActionKeys.INSERTED_KNOWLEDGE_ITEM,
          isSuccess: response.isSuccess,
          isLoading: false,
          message: response.message
        });
        return response;
      }
    );
  },
  deleteKnowledgeItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.DELETE_KNOWLEDGE_ITEM });
    return dispatch(API.admin.deleteKnowledgebaseItem(token, id)).then(
      response => {
        dispatch({
          type: ActionKeys.DELETED_KNOWLEDGE_ITEM,
          isSuccess: response.isSuccess,
          message: response.message
        });
        return response;
      }
    );
  },
  uploadKnowledgeImage: (token, id, body) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPLOAD_KNOWLEDGE_IMAGE });
    return dispatch(
      API.admin.uploadKnowledgebaseAttachment(token, id, body)
    ).then(response => {
      dispatch({
        type: ActionKeys.UPLOADED_KNOWLEDGE_IMAGE,
        isSuccess: response.isSuccess,
        isUploading: false,
        message: response.message
      });
      return response;
    });
  },
  downloadKnowledgeImageInline:
    (token, id, filename) => (dispatch, getState) => {
      dispatch({ type: ActionKeys.DOWNLOAD_KNOWLEDGE_IMAGE });
      return dispatch(
        API.admin.downloadKnowledgebaseImage(token, id, filename)
      ).then(response => {
        if (response?.status === 200) {
          return response.data;
        }
        return false;
      });
    },
  downloadKnowledgeImage:
    (token, id, filename) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.DOWNLOAD_KNOWLEDGE_IMAGE });
      return dispatch(
        API.admin.downloadKnowledgebaseImage(token, id, filename)
      ).then(response => {
        dispatch({
          type: ActionKeys.DOWNLOADED_KNOWLEDGE_IMAGE,
          isSuccess: response.isSuccess,
          message: response.message
        });
        if (response?.status === 200) {
          FileDownload(response.data, filename);
        }
        return response?.isSuccess;
      });
    },
  getStepListing: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_KNOWLEDGE_STEPS });
    return dispatch(API.admin.getStepListing(token, id)).then(response => {
      return response;
    });
  },
  updateStepItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPDATE_KNOWLEDGE_STEP });
    return dispatch(API.admin.updateStepItem(token, item)).then(response => {
      dispatch({
        type: ActionKeys.UPDATED_KNOWLEDGE_STEP,
        isSuccess: response.isSuccess,
        message: response.message
      });
    });
  },
  insertStepItem: (token, item) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.INSERT_KNOWLEDGE_STEP });
    return dispatch(API.admin.insertStepItem(token, item)).then(response => {
      dispatch({
        type: ActionKeys.INSERTED_KNOWLEDGE_STEP,
        isSuccess: response.isSuccess,
        message: response.message
      });
    });
  },
  deleteStepItem: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.DELETE_KNOWLEDGE_STEP });
    return dispatch(API.admin.deleteStepItem(token, id)).then(response => {
      dispatch({
        type: ActionKeys.DELETED_KNOWLEDGE_STEP,
        isSuccess: response.isSuccess,
        message: response.message
      });
    });
  },
  uploadStepAttachment: (token, id, body) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPLOAD_STEP_IMAGE });
    return dispatch(API.admin.uploadStepAttachment(token, id, body)).then(
      response => {
        dispatch({
          type: ActionKeys.UPLOADED_STEP_IMAGE,
          isSuccess: response.isSuccess,
          isUploading: false,
          message: response.message
        });
        return response;
      }
    );
  },
  downloadStepImageInline: (token, id, filename) => (dispatch, getState) => {
    dispatch({ type: ActionKeys.DOWNLOAD_STEP_IMAGE });
    return dispatch(API.admin.downloadStepImage(token, id, filename)).then(
      response => {
        if (response?.status === 200) {
          return response.data;
        }
        return false;
      }
    );
  },
  downloadStepImage: (token, id, filename) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.DOWNLOAD_KNOWLEDGE_IMAGE });
    return dispatch(API.admin.downloadStepImage(token, id, filename)).then(
      response => {
        dispatch({
          type: ActionKeys.DOWNLOADED_KNOWLEDGE_IMAGE,
          isSuccess: response.isSuccess,
          message: response.message
        });
        if (response?.status === 200) {
          FileDownload(response.data, filename);
        }
        return response?.isSuccess;
      }
    );
  },
  deleteStepAttachment:
    (token, id, requestId) => async (dispatch, getState) => {
      dispatch({ type: ActionKeys.DELETE_STEP_IMAGE });
      return dispatch(
        API.admin.deleteStepAttachment(token, id, requestId)
      ).then(response => {
        dispatch({
          type: ActionKeys.DELETED_STEP_IMAGE,
          isSuccess: response.isSuccess,
          message: response.message
        });
      });
    },
  getUserPreference: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_USER_PREFERENCE });
    return dispatch(API.admin.getUserPreference(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_USER_PREFERENCE,
        item: response.item
      });
      return response;
    });
  },
  clearUserPreference: () => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.CLEAR_USER_PREFERENCE });
  },
  updateUserFavourite: (token, favourite) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.UPDATE_USER_FAVOURITE });
    return dispatch(API.admin.updateUserFavourite(token, favourite)).then(
      response => {
        dispatch({
          type: ActionKeys.UPDATED_USER_FAVOURITE,
          isSuccess: response.isSuccess,
          message: response.message
        });
      }
    );
  },
  setUserPreference: item => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.CLEAR_USER_PREFERENCE, item: item });
  }
};
