import { ActionKeys } from "../actions/constants/adminConstants";

const initialState = {
  isLoading: false,
  newsItems: [],
  phoneTypes: [],
  orderLineItems: [],
  orderLineItemDetails: [],
  roles: [],
  user: {},
  incidentWizard: {},
  productWizard: {},
  adminLoadDates: []
};

export default function adminReducer(state, action) {
  state = state || initialState;

  switch (action.type) {
    case ActionKeys.REQUEST_NEWS_LISTING:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: ""
      };
    case ActionKeys.RECEIVE_NEWS_LISTING:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        newsItems: action.items
      };
    case ActionKeys.REQUEST_NEWS_ITEM:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: "",
        newsItem: {}
      };
    case ActionKeys.RECEIVE_NEWS_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        newsItem: action.item
      };
    case ActionKeys.UPDATE_NEWS_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.UPDATED_NEWS_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.UPDATE_NEWS_ATTACHMENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isUploading: true,
        message: ""
      };
    case ActionKeys.UPDATED_NEWS_ATTACHMENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isUploading: false,
        message: action.message
      };
    case ActionKeys.INSERT_NEWS_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.INSERTED_NEWS_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.DELETE_NEWS_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.DELETED_NEWS_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REQUEST_NOTIFICATION_LISTING:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: ""
      };
    case ActionKeys.RECEIVE_NOTIFICATION_LISTING:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        notificationItems: action.items
      };
    case ActionKeys.REQUEST_NOTIFICATION_ITEM:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: "",
        notificationItem: {}
      };
    case ActionKeys.RECEIVE_NOTIFICATION_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        notificationItem: action.item
      };
    case ActionKeys.UPDATE_NOTIFICATION_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.UPDATED_NOTIFICATION_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.INSERT_NOTIFICATION_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.INSERTED_NOTIFICATION_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.DELETE_NOTIFICATION_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.DELETED_NOTIFICATION_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REQUEST_USER_LISTING:
      return {
        ...state,
        isSuccess: false,
        message: ""
      };
    case ActionKeys.RECEIVE_USER_LISTING:
      return {
        ...state
      };
    case ActionKeys.REQUEST_USER_LOOKUP:
      return {
        ...state,
        isSuccess: false,
        message: ""
      };
    case ActionKeys.RECEIVE_USER_LOOKUP:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        userLookups: action.items
      };
    case ActionKeys.REQUEST_DDS_USER_LOOKUP:
      return {
        ...state,
        isSuccess: false,
        message: ""
      };
    case ActionKeys.RECEIVE_DDS_USER_LOOKUP:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        ddsUserLookups: action.items
      };
    case ActionKeys.REQUEST_USER_ITEM:
      return {
        ...state,
        message: ""
      };
    case ActionKeys.RECEIVE_USER_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.UPDATE_USER_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.UPDATED_USER_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.INSERT_USER_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.INSERTED_USER_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.SEND_PASSWORD_RESET:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: ""
      };
    case ActionKeys.SENT_PASSWORD_RESET:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REQUEST_DEALER_LISTING:
      return {
        ...state,
        isSuccess: false,
        isFilteredDealerListingLoading: true,
        message: ""
      };
    case ActionKeys.RECEIVE_DEALER_LISTING:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isFilteredDealerListingLoading: false,
        message: action.message,
        dealerItems: action.items
      };
    case ActionKeys.REQUEST_DEALER_DISCOUNT:
      return {
        ...state,
        isSuccess: false,
        message: ""
      };
    case ActionKeys.RECIEVE_DEALER_DISCOUNT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.REQUEST_DISCOUNT_VIEWED:
      return {
        ...state,
        isSuccess: false,
        message: ""
      };
    case ActionKeys.RECIEVE_DISCOUNT_VIEWED:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.INSERT_DEALER_DISCOUNT:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: ""
      };
    case ActionKeys.INSERTED_DEALER_DISCOUNT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.UPDATE_PAGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.UPDATED_PAGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.INSERT_PAGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.INSERTED_PAGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.DELETE_PAGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.DELETED_PAGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REQUEST_SERVICE_REQUEST_LISTING:
      return {
        ...state,
        isLoading: false,
        isUploading: false,
        message: ""
      };
    case ActionKeys.RECEIVE_SERVICE_REQUEST_LISTING:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.REQUEST_SERVICE_REQUEST_ITEM:
      return {
        ...state,
        isSuccess: false,
        isUploading: false,
        message: ""
      };
    case ActionKeys.RECEIVE_SERVICE_REQUEST_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.DELETE_SERVICE_REQUEST_ATTACHMENT:
      return {
        ...state,
        isUploading: true
      };
    case ActionKeys.DELETED_SERVICE_REQUEST_ATTACHMENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.REQUEST_DA_DEPT_LISTING:
      return {
        ...state,
        isSuccess: false,
        message: ""
      };
    case ActionKeys.REQUEST_PRICING_LISTING:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: ""
      };
    case ActionKeys.RECEIVE_PRICING_LISTING:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        pricingItems: action.items
      };
    case ActionKeys.RECEIVE_DA_DEPT_LISTING:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        daDeptItems: action.items
      };
    case ActionKeys.REQUEST_DA_DEPT_ITEM:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: "",
        daDeptItems: {}
      };
    case ActionKeys.RECEIVE_DA_DEPT_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        daDeptItems: action.item
      };
    case ActionKeys.UPDATE_DA_DEPT_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.UPDATED_DA_DEPT_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.INSERT_DA_DEPT_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.INSERTED_DA_DEPT_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.DELETE_DA_DEPT_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.DELETED_DA_DEPT_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REQUEST_DA_BOMCOMMENTS_LISTING:
      return {
        ...state,
        isSuccess: false,
        message: ""
      };
    case ActionKeys.RECEIVE_DA_BOMCOMMENTS_LISTING:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        daBomItems: action.items
      };
    case ActionKeys.REQUEST_DA_BOMCOMMENTS_ITEM:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: "",
        daBomItems: null
      };
    case ActionKeys.RECEIVE_DA_BOMCOMMENTS_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        daBomItems: action.item
      };
    case ActionKeys.UPDATE_DA_BOMCOMMENTS_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.UPDATED_DA_BOMCOMMENTS_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.INSERT_DA_BOMCOMMENTS_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.INSERTED_DA_BOMCOMMENTS_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.DELETE_DA_BOMCOMMENTS_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.DELETED_DA_BOMCOMMENTS_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REQUEST_DA_MFGCOMMENTS_LISTING:
      return {
        ...state,
        isSuccess: false,
        message: ""
      };
    case ActionKeys.RECEIVE_DA_MFGCOMMENTS_LISTING:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        daMfgItems: action.items
      };
    case ActionKeys.REQUEST_DA_MFGCOMMENTS_ITEM:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: "",
        daMfgItems: null
      };
    case ActionKeys.RECEIVE_DA_MFGCOMMENTS_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        daMfgItems: action.item
      };
    case ActionKeys.UPDATE_DA_MFGCOMMENTS_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.UPDATED_DA_MFGCOMMENTS_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.INSERT_DA_MFGCOMMENTS_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.INSERTED_DA_MFGCOMMENTS_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.DELETE_DA_MFGCOMMENTS_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.DELETED_DA_MFGCOMMENTS_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REQUEST_DA_ORDERENTRY_LISTING:
      return {
        ...state,
        isSuccess: false,
        message: ""
      };
    case ActionKeys.RECEIVE_DA_ORDERENTRY_LISTING:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        daOrderEntryItems: action.items
      };
    case ActionKeys.REQUEST_DA_ORDERENTRY_ITEM:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: "",
        daOrderEntryItems: null
      };
    case ActionKeys.RECEIVE_DA_ORDERENTRY_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        daOrderEntryItems: action.item
      };
    case ActionKeys.UPDATE_DA_ORDERENTRY_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.UPDATED_DA_ORDERENTRY_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.INSERT_DA_ORDERENTRY_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.INSERTED_DA_ORDERENTRY_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.DELETE_DA_ORDERENTRY_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.DELETED_DA_ORDERENTRY_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REQUEST_DA_WARRANTY_LISTING:
      return {
        ...state,
        isSuccess: false,
        message: "",
        warrantyItems: null
      };
    case ActionKeys.RECEIVE_DA_WARRANTY_LISTING:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        warrantyItems: action.items
      };
    case ActionKeys.REQUEST_PRICING_CATEGORIES:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: ""
      };
    case ActionKeys.RECEIVE_PRICING_CATEGORIES:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        categoryItems: action.items
      };
    case ActionKeys.REQUEST_PHONE_TYPES:
      return {
        ...state,
        isSuccess: false,
        message: ""
      };
    case ActionKeys.RECEIVE_PHONE_TYPES:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        phoneTypes: action.items
      };
    case ActionKeys.REQUEST_DA_ACCTPREF_LISTING:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: ""
      };
    case ActionKeys.RECEIVE_DA_ACCTPREF_LISTING:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        daAcctPrefItems: action.items
      };
    case ActionKeys.REQUEST_DA_ACCTPREF_ITEM:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: "",
        daAcctPrefItems: {}
      };
    case ActionKeys.RECEIVE_DA_ACCTPREF_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        daAcctPrefItems: action.item
      };
    case ActionKeys.UPDATE_DA_ACCTPREF_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.UPDATED_DA_ACCTPREF_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.INSERT_DA_ACCTPREF_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.INSERTED_DA_ACCTPREF_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.DELETE_DA_ACCTPREF_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.DELETED_DA_ACCTPREF_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.BEGIN_LOAD_PROJECT_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case ActionKeys.END_LOAD_PROJECT_REQUEST:
      return {
        ...state,
        isLoading: false
      };
    case ActionKeys.REQUEST_PROJECT_REQUEST_HEADERS:
      return {
        ...state,
        isLoading: false,
        isUploading: false,
        message: ""
      };
    case ActionKeys.DOWNLOAD_PROJECT_REQUEST_ATTACHMENT:
      return {
        ...state
      };
    case ActionKeys.DOWNLOADED_PROJECT_REQUEST_ATTACHMENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.DOWNLOAD_DAP_ATTACHMENT:
      return {
        ...state
      };
    case ActionKeys.DOWNLOADED_DAP_ATTACHMENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.DELETE_PROJECT_REQUEST_ATTACHMENT:
      return {
        ...state,
        isUploading: true
      };
    case ActionKeys.DELETED_PROJECT_REQUEST_ATTACHMENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.UPLOAD_SERVICE_REQUEST_ATTACHMENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isUploading: true,
        message: ""
      };
    case ActionKeys.UPLOADED_SERVICE_REQUEST_ATTACHMENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.REQUEST_DDS_PROJECT_LISTING:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: ""
      };
    case ActionKeys.RECEIVE_DDS_PROJECT_LISTING:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        ddsProjectItems: action.items
      };
    case ActionKeys.REQUEST_DDS_PROJECT_ITEM:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: "",
        ddsProjectItems: {}
      };
    case ActionKeys.RECEIVE_DDS_PROJECT_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        ddsProjectItems: action.item
      };
    case ActionKeys.UPDATE_DDS_PROJECT_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.UPDATED_DDS_PROJECT_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.INSERT_DDS_PROJECT_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.INSERTED_DDS_PROJECT_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.DELETE_DDS_PROJECT_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.DELETED_DDS_PROJECT_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REQUEST_DA_APPROVALUNIT_LISTING:
      return {
        ...state,
        isSuccess: false,
        message: ""
      };
    case ActionKeys.RECEIVE_DA_APPROVALUNIT_LISTING:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        daApprovalUnitItems: action.items
      };
    case ActionKeys.REQUEST_DA_APPROVALUNIT_ITEM:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: "",
        daApprovalUnitItems: null
      };
    case ActionKeys.RECEIVE_DA_APPROVALUNIT_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        daApprovalUnitItems: action.item
      };
    case ActionKeys.UPDATE_DA_APPROVALUNIT_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.UPDATED_DA_APPROVALUNIT_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.INSERT_DA_APPROVALUNIT_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.INSERTED_DA_APPROVALUNIT_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.DELETE_DA_APPROVALUNIT_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.DELETED_DA_APPROVALUNIT_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REQUEST_DA_APPROVEDFOR_LISTING:
      return {
        ...state,
        isSuccess: false,
        message: ""
      };
    case ActionKeys.RECEIVE_DA_APPROVEDFOR_LISTING:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        daApprovedForItems: action.items
      };
    case ActionKeys.REQUEST_DA_APPROVEDFOR_ITEM:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: "",
        daApprovedForItems: null
      };
    case ActionKeys.RECEIVE_DA_APPROVEDFOR_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        daApprovedForItems: action.item
      };
    case ActionKeys.UPDATE_DA_APPROVEDFOR_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.UPDATED_DA_APPROVEDFOR_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.INSERT_DA_APPROVEDFOR_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.INSERTED_DA_APPROVEDFOR_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.DELETE_DA_APPROVEDFOR_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.DELETED_DA_APPROVEDFOR_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REQUEST_DDSTECHREPORT_DATA:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: ""
      };
    case ActionKeys.RECIEVE_DDSTECHREPORT_DATA:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.REQUEST_DDSACCOUNTREPORT_DATA:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: ""
      };
    case ActionKeys.RECIEVE_DDSACCOUNTREPORT_DATA:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.REQUEST_DDSDEPARTMENTREPORT_DATA:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: ""
      };
    case ActionKeys.RECIEVE_DDSDEPARTMENTREPORT_DATA:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.REQUEST_DDSPRIORITYREPORT_DATA:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: ""
      };
    case ActionKeys.RECIEVE_DDSPRIORITYREPORT_DATA:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.UPDATED_USER_TOKEN:
      return {
        ...state,
        token: action.token
      };
    case ActionKeys.UPDATED_LOGGED_IN_USER:
      return {
        ...state,
        user: action.user
      };
    case ActionKeys.REQUEST_ADMIN_ROLES:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: ""
      };
    case ActionKeys.RECEIVE_ADMIN_ROLES:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        roles: action.items
      };
    case ActionKeys.UPDATE_REMITTANCE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.UPDATED_REMITTANCE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.ACTION_REMITTANCE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: action.message
      };
    case ActionKeys.ACTIONED_REMITTANCE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REQUEST_SHIPPING_METHODS:
      return {
        ...state,
        shippingMethods: []
      };
    case ActionKeys.RECEIVE_SHIPPING_METHODS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        shippingMethods: action.items
      };
    case ActionKeys.REQUEST_PRIORITY_LEVELS:
      return {
        ...state,
        priorityLevels: []
      };
    case ActionKeys.RECEIVE_PRIORITY_LEVELS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        priorityLevels: action.items
      };
    case ActionKeys.REQUEST_ADDITIONAL_CREW_REASONS:
      return {
        ...state,
        additionalCrewReasons: []
      };
    case ActionKeys.RECEIVE_ADDITIONAL_CREW_REASONS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        additionalCrewReasons: action.items
      };
    case ActionKeys.REQUEST_CREWS:
      return {
        ...state,
        crews: []
      };
    case ActionKeys.RECEIVE_CREWS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        crews: action.items
      };
    case ActionKeys.REQUEST_PRIORITY_TYPES:
      return {
        ...state,
        priorityTypes: []
      };
    case ActionKeys.RECEIVE_PRIORITY_TYPES:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        priorityTypes: action.items
      };
    case ActionKeys.REQUEST_SERVICE_ADVISORS:
      return {
        ...state,
        serviceAdvisors: []
      };
    case ActionKeys.RECEIVE_SERVICE_ADVISORS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        serviceAdvisors: action.items
      };
    case ActionKeys.REQUEST_ORDER_LINE_ITEMS:
      return {
        ...state,
        orderLineItems: [],
        orderLineItemCount: 0
      };
    case ActionKeys.RECEIVE_ORDER_LINE_ITEMS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        orderLineItems: action.items,
        orderLineItemCount: action.count
      };
    case ActionKeys.REQUEST_ORDER_LINE_ITEM_DETAIL:
      return {
        ...state,
        isLoading: true,
        orderLineItemDetailsCount: 0
      };
    case ActionKeys.RECEIVE_ORDER_LINE_ITEM_DETAILS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        isLoading: false,
        orderLineItemDetails: action.items,
        orderLineItemDetailsCount: action.count
      };
    case ActionKeys.UPDATE_SERVICE_REQUEST:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: action.message,
        newId: 0
      };
    case ActionKeys.UPDATED_SERVICE_REQUEST:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        newId: action.id
      };
    case ActionKeys.ACTION_SERVICE_REQUEST:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: action.message
      };
    case ActionKeys.ACTIONED_SERVICE_REQUEST:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };

    case ActionKeys.REQUEST_SERVICE_REQUEST_YEARS:
      return {
        ...state,
        years: []
      };
    case ActionKeys.RECEIVE_SERVICE_REQUEST_YEARS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        years: action.items
      };
    case ActionKeys.ASSIGN_SERVICE_REQUEST_TECH:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        loadingMessage: "Updating Service Advisor",
        message: action.message
      };
    case ActionKeys.ASSIGNED_SERVICE_REQUEST_TECH:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        loadingMessage: null,
        message: action.message
      };
    case ActionKeys.REQUEST_PRODUCT_WIZARD:
      return {
        ...state,
        productWizard: {},
        isLoading: true
      };
    case ActionKeys.REQUEST_INCIDENT:
      return {
        ...state,
        isSuccess: false,
        isUploading: false,
        message: ""
      };
    case ActionKeys.RECEIVE_INCIDENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.UPDATE_INCIDENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.UPDATED_INCIDENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REQUEST_INCIDENT_WIZARD:
      return {
        ...state,
        isWizardLoading: true
      };
    case ActionKeys.RECEIVE_INCIDENT_WIZARD:
      return {
        ...state,
        isWizardLoading: false
      };
    case ActionKeys.RECEIVE_PRODUCT_WIZARD:
      return {
        ...state,
        productWizard: action.item,
        isLoading: false
      };
    case ActionKeys.REQUEST_INCIDENT_SERVICE_TYPES:
      return {
        ...state,
        incidentServiceTypes: []
      };
    case ActionKeys.RECEIVE_INCIDENT_SERVICE_TYPES:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        incidentServiceTypes: action.items
      };
    case ActionKeys.ACTION_PROJECT_REQUEST:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: action.message
      };
    case ActionKeys.ACTIONED_PROJECT_REQUEST:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.COMPLETE_PROJECT_REQUEST:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: action.message
      };
    case ActionKeys.COMPLETED_PROJECT_REQUEST:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REQUEST_PROJECT_REQUEST:
      return {
        ...state,
        message: "",
        isUploading: false,
        isLoading: false
      };
    case ActionKeys.RECEIVE_PROJECT_REQUEST:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.REQUEST_PROJECT_REQUEST_REVISIONS:
      return {
        ...state
      };
    case ActionKeys.RECEIVE_PROJECT_REQUEST_REVISIONS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.REQUEST_REQUEST_TYPES:
      return {
        ...state,
        requestTypes: []
      };
    case ActionKeys.RECEIVE_REQUEST_TYPES:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        requestTypes: action.items
      };
    case ActionKeys.REQUEST_REQUEST_STATUSES:
      return {
        ...state,
        requestStatuses: []
      };
    case ActionKeys.RECEIVE_REQUEST_STATUSES:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        requestStatuses: action.items
      };
    case ActionKeys.UPDATE_PROJECT_REQUEST:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.UPDATED_PROJECT_REQUEST:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.UPLOAD_PROJECT_REQUEST_ATTACHMENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isUploading: true,
        message: ""
      };
    case ActionKeys.UPLOADED_PROJECT_REQUEST_ATTACHMENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.UPLOAD_PROJECT_REQUEST_DEPARTMENT_ATTACHMENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isDepartmentUploading: true,
        message: ""
      };
    case ActionKeys.UPLOADED_PROJECT_REQUEST_DEPARTMENT_ATTACHMENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isDepartmentUploading: false,
        message: action.message
      };
    case ActionKeys.RESOLVE_PROJECT_REQUEST:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.RESOLVED_PROJECT_REQUEST:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REQUEST_DAP_ITEM:
      return {
        ...state,
        isLoading: false,
        isUploading: false,
        message: ""
      };
    case ActionKeys.RECEIVE_DAP_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        dapItem: action.item
      };
    case ActionKeys.UPDATE_DAP_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.UPDATED_DAP_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REQUEST_DAP_TYPES:
      return {
        ...state,
        dapTypes: null
      };
    case ActionKeys.RECEIVE_DAP_TYPES:
      return {
        ...state,
        isSuccess: action.isSuccess,
        dapTypes: action.items
      };
    case ActionKeys.REQUEST_DAP_PROCESS:
      return {
        ...state,
        processItems: null
      };
    case ActionKeys.RECEIVE_DAP_PROCESS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        processItems: action.items
      };
    case ActionKeys.REQUEST_QUICK_SELECT_UNITS:
      return {
        ...state,
        quickSelectUnitItems: null
      };
    case ActionKeys.RECEIVE_QUICK_SELECT_UNITS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        quickSelectUnitItems: action.items
      };
    case ActionKeys.REQUEST_LINE_ITEMS:
      return {
        ...state,
        lineItems: null
      };
    case ActionKeys.RECEIVE_LINE_ITEMS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        lineItems: action.items
      };
    case ActionKeys.REQUEST_ITEM_NUMBER:
      return {
        ...state,
        isSuccess: false,
        itemNumber: null
      };
    case ActionKeys.RECEIVE_ITEM_NUMBER:
      return {
        ...state,
        isSuccess: action.isSuccess,
        itemNumber: action.item
      };
    case ActionKeys.INSERT_DAP_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: "",
        newId: null
      };
    case ActionKeys.INSERTED_DAP_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        newId: action.id
      };
    case ActionKeys.UPLOAD_DAP_ATTACHMENT:
      return {
        ...state,
        isUploading: true,
        message: ""
      };
    case ActionKeys.UPLOADED_DAP_ATTACHMENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.DELETE_DAP_ATTACHMENT:
      return {
        ...state,
        isUploading: true,
        message: ""
      };
    case ActionKeys.DELETED_DAP_ATTACHMENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.CLONE_DAP_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.CLONED_DAP_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.UPDATE_PROJECT_VALUE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.UPDATED_PROJECT_VALUE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REQUEST_ACCOUNT_QUOTES:
      return {
        ...state,
        accountQuotes: null
      };
    case ActionKeys.RECEIVE_ACCOUNT_QUOTES:
      return {
        ...state,
        isSuccess: action.isSuccess,
        accountQuotes: action.items
      };
    case ActionKeys.REQUEST_ACCOUNT_ORDERS:
      return {
        ...state,
        accountOrders: null
      };
    case ActionKeys.RECEIVE_ACCOUNT_ORDERS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        accountOrders: action.items
      };
    case ActionKeys.REQUEST_DEALER_LOOKUP:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: ""
      };
    case ActionKeys.RECEIVE_DEALER_LOOKUP:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        dealerLookups: action.items
      };
    case ActionKeys.REQUEST_CHANGE_ITEM:
      return {
        ...state,
        message: ""
      };
    case ActionKeys.RECEIVE_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.UPDATE_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.UPDATED_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.UPDATE_CHANGE_DETAILS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        detailsLoading: true,
        message: ""
      };
    case ActionKeys.UPDATED_CHANGE_DETAILS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        detailsLoading: false,
        message: action.message
      };
    case ActionKeys.VERIFY_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.VERIFIED_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REVERT_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.REVERTED_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REVERT_AM_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.REVERTED_AM_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.PROD_COMMENT_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.PROD_COMMENTED_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.AM_COMMENT_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.AM_COMMENTED_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.FORWARD_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.FORWARDED_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.COMPLETE_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.COMPLETED_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.PRICE_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.PRICED_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.CANCEL_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.CANCELLED_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.ACTION_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.ACTIONED_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.ASSIGN_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.ASSIGNED_CHANGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REQUEST_CHANGE_USER_ITEMS:
      return {
        ...state
      };
    case ActionKeys.RECEIVE_CHANGE_USER_ITEMS:
      return {
        ...state,
        changeUsers: action.items
      };
    case ActionKeys.REQUEST_LOAD_DATES:
      return {
        ...state,
        isLoading: true,
        adminLoadDates: []
      };
    case ActionKeys.RECEIVE_LOAD_DATES:
      return {
        ...state,
        isLoading: false,
        isSuccess: action.isSuccess,
        adminLoadDates: action.items,
        message: action.message
      };
    case ActionKeys.REQUEST_SYSTEM_ROLES:
      return {
        ...state,
        isSuccess: false,
        message: ""
      };
    case ActionKeys.RECEIVE_SYSTEM_ROLES:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        systemRoles: action.items
      };
    case ActionKeys.REQUEST_SYSTEM_SECTIONS:
      return {
        ...state,
        isSuccess: false,
        message: ""
      };
    case ActionKeys.RECEIVE_SYSTEM_SECTIONS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        systemSections: action.items
      };
    case ActionKeys.REQUEST_KNOWLEDGE_CATEGORIES:
      return {
        ...state,
        isSuccess: false,
        message: "",
        knowledgeCategories: null
      };
    case ActionKeys.RECEIVE_KNOWLEDGE_CATEGORIES:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        knowledgeCategories: action.items
      };
    case ActionKeys.REQUEST_KNOWLEDGE_ITEM:
      return {
        ...state,
        message: "",
        isUploading: false
      };
    case ActionKeys.RECEIVE_KNOWLEDGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.REQUEST_PAGE_ITEM:
      return {
        ...state,
        message: ""
      };
    case ActionKeys.RECEIVE_PAGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.UPDATE_KNOWLEDGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.UPDATED_KNOWLEDGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.INSERT_KNOWLEDGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.INSERTED_KNOWLEDGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.DELETE_KNOWLEDGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: ""
      };
    case ActionKeys.DELETED_KNOWLEDGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.UPLOAD_KNOWLEDGE_IMAGE:
      return {
        ...state,
        isUploading: true,
        message: ""
      };
    case ActionKeys.UPLOADED_KNOWLEDGE_IMAGE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.DOWNLOAD_KNOWLEDGE_IMAGE:
      return {
        ...state
      };
    case ActionKeys.DOWNLOADED_KNOWLEDGE_IMAGE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.UPDATE_KNOWLEDGE_STEP:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: ""
      };
    case ActionKeys.UPDATED_KNOWLEDGE_STEP:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.INSERT_KNOWLEDGE_STEP:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: ""
      };
    case ActionKeys.INSERTED_KNOWLEDGE_STEP:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.DELETE_KNOWLEDGE_STEP:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: ""
      };
    case ActionKeys.DELETED_KNOWLEDGE_STEP:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.UPLOAD_STEP_IMAGE:
      return {
        ...state,
        isUploading: true,
        message: ""
      };
    case ActionKeys.UPLOADED_STEP_IMAGE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.DOWNLOAD_STEP_IMAGE:
      return {
        ...state
      };
    case ActionKeys.DOWNLOADED_STEP_IMAGE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.DELETE_STEP_IMAGE:
      return {
        ...state,
        isUploading: true,
        message: ""
      };
    case ActionKeys.DELETED_STEP_IMAGE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.REQUEST_USER_PREFERENCE:
      return {
        ...state,
        userPreference: null
      };
    case ActionKeys.RECEIVE_USER_PREFERENCE:
      return {
        ...state,
        userPreference: action.item
      };
    case ActionKeys.CLEAR_USER_PREFERENCE:
      return {
        ...state,
        userPreference: null
      };
    case ActionKeys.UPDATE_USER_FAVOURITE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: ""
      };
    case ActionKeys.UPDATED_USER_FAVOURITE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.SET_USER_PREFERENCE:
      return {
        ...state,
        userPreference: action.item
      };
    default:
      return state;
  }
}
