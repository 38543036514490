import fetchToken from "./token";

export async function login(username, password, generatedAuthCode) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password, generatedAuthCode })
  };
  const BASEURL = process.env.REACT_APP_API_URL;

  return await fetch(BASEURL + "/account/authenticate", requestOptions)
    .then(handleResponse)
    .then(user => {
      return user;
    });
}

export async function impersonate(account, token) {
  const authToken = (await fetchToken()) ?? token;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`
    },
    body: JSON.stringify({ account: account })
  };
  const BASEURL = process.env.REACT_APP_API_URL;

  return await fetch(BASEURL + "/admin/account/impersonate", requestOptions)
    .then(handleResponse)
    .then(user => {
      return user;
    });
}

export async function loginCode(username, password, code, rememberMe) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password, authCode: code, rememberMe })
  };
  const BASEURL = process.env.REACT_APP_API_URL;

  return await fetch(BASEURL + "/account/authcode", requestOptions)
    .then(handleResponse)
    .then(user => {
      return user;
    });
}

export async function forgotPassword(username, emailAddress) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, emailAddress })
  };
  const BASEURL = process.env.REACT_APP_API_URL;

  return await fetch(BASEURL + "/account/forgotpassword", requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export async function registerUser(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user)
  };
  const BASEURL = process.env.REACT_APP_API_URL;

  return await fetch(BASEURL + "/account/register", requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export async function verifyPasswordReset(
  emailAddress,
  forgotPasswordGuid,
  hash
) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ emailAddress, forgotPasswordGuid, hash })
  };
  const BASEURL = process.env.REACT_APP_API_URL;

  return await fetch(BASEURL + "/account/verifyreset", requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export async function resetPassword(
  emailAddress,
  forgotPasswordGuid,
  hash,
  password
) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ emailAddress, forgotPasswordGuid, hash, password })
  };
  const BASEURL = process.env.REACT_APP_API_URL;

  return await fetch(BASEURL + "/account/resetpassword", requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export async function changePassword(token, id, currentPassword, newPassword) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({ id, currentPassword, password: newPassword })
  };
  const BASEURL = process.env.REACT_APP_API_URL;

  return await fetch(BASEURL + "/account/changepassword", requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export async function getPhoneTypes() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  };
  const BASEURL = process.env.REACT_APP_API_URL;

  return await fetch(BASEURL + "/account/phonetypes", requestOptions)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

export async function authorize(token) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  };
  const BASEURL = process.env.REACT_APP_API_URL;

  return await fetch(BASEURL + "/account/authorize", requestOptions)
    .then(response => handleAuthorize(response, token))
    .then(user => {
      if (user !== null && user !== undefined && user !== "") {
        return user;
      }
    });
}

export async function adminAuthorize(token, instance, history) {
  const authToken = (await fetchToken()) ?? token;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`
    }
  };
  const BASEURL = process.env.REACT_APP_API_URL;

  return await fetch(BASEURL + "/admin/account/authorize", requestOptions)
    .then(response => handleAdminAuthorize(response, token, instance, history))
    .then(response => {
      if (response !== null && response !== undefined && response !== "") {
        return response;
      }
    });
}

export async function logout(token) {
  const authToken = (await fetchToken()) ?? token;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`
    }
  };
  const BASEURL = process.env.REACT_APP_API_URL;

  await fetch(BASEURL + "/account/logout", requestOptions);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status !== 200) {
        return data;
      }
    }
    return data;
  });
}

function handleAuthorize(response, token) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status !== 200) {
        if (location.pathname && location.pathname !== "/logout") {
          window.location = "/logout?refer=" + location.pathname;
        } else {
          window.location = "/logout?";
        }
      }
    }
    return data;
  });
}

const handleAdminAuthorize = (response, token, instance, history) => {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status !== 200) {
        history.push("/dashboard");
      }
    }
    return data;
  });
};
