export const ActionKeys = {
  UPDATED_USER_INFO: "UPDATED_USER_INFO",
  REQUEST_TEST_LISTING: "REQUEST_TEST_LISTING",
  RECEIVE_TEST_LISTING: "RECEIVE_TEST_LISTING",
  UPDATED_USER_TOKEN_DEALER: "UPDATED_USER_TOKEN_DEALER",
  UPDATED_IS_IMPERSONATING: "UPDATED_IS_IMPERSONATING",
  REQUEST_ACTIVE_NOTIFICATIONS: "REQUEST_ACTIVE_NOTIFICATIONS",
  RECEIVE_ACTIVE_NOTIFICATIONS: "RECEIVE_ACTIVE_NOTIFICATIONS",
  REQUEST_NEWS_LISTING: "REQUEST_NEWS_LISTING",
  RECEIVE_NEWS_LISTING: "RECEIVE_NEWS_LISTING",
  REQUEST_NEWS_ITEM: "REQUEST_NEWS_ITEM",
  RECEIVE_NEWS_ITEM: "RECEIVE_NEWS_ITEM",
  REQUEST_CURRENT_ORDER_LISTING: "REQUEST_CURRENT_ORDER_LISTING",
  RECEIVE_CURRENT_ORDER_LISTING: "RECEIVE_CURRENT_ORDER_LISTING",
  REQUEST_PAST_ORDER_LISTING: "REQUEST_PAST_ORDER_LISTING",
  RECEIVE_PAST_ORDER_LISTING: "RECEIVE_PAST_ORDER_LISTING",
  REQUEST_ORDER_ITEM: "REQUEST_ORDER_ITEM",
  RECEIVE_ORDER_ITEM: "RECEIVE_ORDER_ITEM",
  REQUEST_INVOICE_LISTING: "REQUEST_INVOICE_LISTING",
  RECEIVE_INVOICE_LISTING: "RECEIVE_INVOICE_LISTING",
  REQUEST_INVOICE_ITEM: "REQUEST_INVOICE_ITEM",
  RECEIVE_INVOICE_ITEM: "RECEIVE_INVOICE_ITEM",
  REQUEST_INVOICE_LINE_ITEMS: "REQUEST_INVOICE_LINE_ITEMS",
  RECEIVE_INVOICE_LINE_ITEMS: "RECEIVE_INVOICE_LINE_ITEMS",
  REQUEST_ORDER_LINE_ITEMS: "REQUEST_ORDER_LINE_ITEMS",
  RECEIVE_ORDER_LINE_ITEMS: "RECEIVE_ORDER_LINE_ITEMS",
  REQUEST_DDS_ORDER_LINE_ITEMS: "REQUEST_DDS_ORDER_LINE_ITEMS",
  RECEIVE_DDS_ORDER_LINE_ITEMS: "RECEIVE_DDS_ORDER_LINE_ITEMS",
  REQUEST_ORDER_LINE_ITEM_DETAILS: "REQUEST_ORDER_LINE_ITEM_DETAILS",
  RECEIVE_ORDER_LINE_ITEM_DETAILS: "RECEIVE_ORDER_LINE_ITEM_DETAILS",
  UPDATE_USER_EMAIL: "UPDATE_USER_EMAIL",
  UPDATED_USER_EMAIL: "UPDATED_USER_EMAIL",
  UPDATE_USER_MFATYPE: "UPDATE_USER_MFATYPE",
  UPDATED_USER_MFATYPE: "UPDATED_USER_MFATYPE",
  UPDATE_USER_PASSWORD: "UPDATE_USER_PASSWORD",
  UPDATED_USER_PASSWORD: "UPDATED_USER_PASSWORD",
  UPDATE_USER_PHONE: "UPDATE_USER_PHONE",
  UPDATED_USER_PHONE: "UPDATED_USER_PHONE",
  INSERT_USER_PHONE: "INSERT_USER_PHONE",
  INSERTED_USER_PHONE: "INSERTED_USER_PHONE",
  DELETE_USER_PHONE: "DELETE_USER_PHONE",
  DELETED_USER_PHONE: "DELETED_USER_PHONE",
  REQUEST_DEALER_INFO: "REQUEST_DEALER_INFO",
  RECEIVE_DEALER_INFO: "RECEIVE_DEALER_INFO",
  UPDATE_ADDRESS: "UPDATE_ADDRESS",
  UPDATED_ADDRESS: "UPDATED_ADDRESS",
  REQUEST_OPEN_SERVICE_REQUEST_LISTING: "REQUEST_OPEN_SERVICE_REQUEST_LISTING",
  RECEIVE_OPEN_SERVICE_REQUEST_LISTING: "RECEIVE_OPEN_SERVICE_REQUEST_LISTING",
  REQUEST_CLOSED_SERVICE_REQUEST_LISTING:
    "REQUEST_CLOSED_SERVICE_REQUEST_LISTING",
  RECEIVE_CLOSED_SERVICE_REQUEST_LISTING:
    "RECEIVE_CLOSED_SERVICE_REQUEST_LISTING",
  REQUEST_PROCESSED_SERVICE_REQUEST_LISTING:
    "REQUEST_PROCESSED_SERVICE_REQUEST_LISTING",
  RECEIVE_PROCESSED_SERVICE_REQUEST_LISTING:
    "RECEIVE_PROCESSED_SERVICE_REQUEST_LISTING",
  REQUEST_SERVICE_REQUEST_ITEM: "REQUEST_SERVICE_REQUEST_ITEM",
  RECEIVE_SERVICE_REQUEST_ITEM: "RECEIVE_SERVICE_REQUEST_ITEM",
  REQUEST_INCIDENT_ITEMS: "REQUEST_INCIDENT_ITEMS",
  RECEIVE_INCIDENT_ITEMS: "RECEIVE_INCIDENT_ITEMS",
  REQUEST_SHIPPING_METHODS: "REQUEST_SHIPPING_METHODS",
  RECEIVE_SHIPPING_METHODS: "RECEIVE_SHIPPING_METHODS",
  REQUEST_PRIORITY_LEVELS: "REQUEST_PRIORITY_LEVELS",
  RECEIVE_PRIORITY_LEVELS: "RECEIVE_PRIORITY_LEVELS",
  REQUEST_PRIORITY_TYPES: "REQUEST_PRIORITY_TYPES",
  RECEIVE_PRIORITY_TYPES: "RECEIVE_PRIORITY_TYPES",
  REQUEST_ADDITIONAL_CREW_REASONS: "REQUEST_ADDITIONAL_CREW_REASONS",
  RECEIVE_ADDITIONAL_CREW_REASONS: "RECEIVE_ADDITIONAL_CREW_REASONS",
  REQUEST_SERVICE_ADVISORS: "REQUEST_SERVICE_ADVISORS",
  RECEIVE_SERVICE_ADVISORS: "RECEIVE_SERVICE_ADVISORS",
  REQUEST_ORDER_NUMBER_LISTING: "REQUEST_ORDER_NUMBER_LISTING",
  RECEIVE_ORDER_NUMBER_LISTING: "RECEIVE_ORDER_NUMBER_LISTING",
  REQUEST_QUOTE_NUMBER_LISTING: "REQUEST_QUOTE_NUMBER_LISTING",
  RECEIVE_QUOTE_NUMBER_LISTING: "RECEIVE_QUOTE_NUMBER_LISTING",
  CLEAR_ORDER_NUMBER_LISTING: "CLEAR_ORDER_NUMBER_LISTING",
  INSERT_SERVICE_REQUEST: "INSERT_SERVICE_REQUEST",
  INSERTED_SERVICE_REQUEST: "INSERTED_SERVICE_REQUEST",
  UPDATE_SERVICE_REQUEST: "UPDATE_SERVICE_REQUEST",
  UPDATED_SERVICE_REQUEST: "UPDATED_SERVICE_REQUEST",
  REQUEST_PRODUCT_WIZARD: "REQUEST_PRODUCT_WIZARD",
  RECEIVE_PRODUCT_WIZARD: "RECEIVE_PRODUCT_WIZARD",
  REQUEST_INCIDENT_WIZARD: "REQUEST_INCIDENT_WIZARD",
  RECEIVE_INCIDENT_WIZARD: "RECEIVE_INCIDENT_WIZARD",
  REQUEST_ORDER_CUSTOMER: "REQUEST_ORDER_CUSTOMER",
  RECEIVE_ORDER_CUSTOMER: "RECEIVE_ORDER_CUSTOMER",
  REQUEST_PROJECT_LISTING: "REQUEST_PROJECT_LISTING",
  RECEIVE_PROJECT_LISTING: "RECEIVE_PROJECT_LISTING",
  REQUEST_PROJECT_DETAILS: "REQUEST_PROJECT_DETAILS",
  REQUEST_DAP_IMAGES: "REQUEST_DAP_IMAGES",
  RECEIVE_DAP_IMAGES: "RECEIVE_DAP_IMAGES",
  RECEIVE_PROJECT_DETAILS: "RECEIVE_PROJECT_DETAILS",
  CLEAR_PROJECT_DETAILS: "CLEAR_PROJECT_DETAILS",
  INSERT_PROJECT: "INSERT_PROJECT",
  INSERTED_PROJECT: "INSERTED_PROJECT",
  ARCHIVE_PROJECT: "ARCHIVE_PROJECT",
  ARCHIVED_PROJECT: "ARCHIVED_PROJECT",
  REQUEST_PROJECT_REQUEST_TYPES: "REQUEST_PROJECT_REQUEST_TYPES",
  RECEIVE_PROJECT_REQUEST_TYPES: "RECEIVE_PROJECT_REQUEST_TYPES",
  INSERT_PROJECT_REQUEST: "INSERT_PROJECT_REQUEST",
  INSERTED_PROJECT_REQUEST: "INSERTED_PROJECT_REQUEST",
  DOWNLOAD_NEWS_ATTACHMENT: "DOWNLOAD_NEWS_ATTACHMENT",
  DOWNLOAD_SERVICE_REQUEST_IMAGE: "DOWNLOAD_SERVICE_REQUEST_IMAGE",
  UPLOAD_SERVICE_REQUEST_ATTACHMENT: "UPLOAD_SERVICE_REQUEST_ATTACHMENT",
  UPLOADED_SERVICE_REQUEST_ATTACHMENT: "UPLOADED_SERVICE_REQUEST_ATTACHMENT",
  REQUEST_STATIC_PAGES: "REQUEST_STATIC_PAGES",
  RECEIVE_STATIC_PAGES: "RECEIVE_STATIC_PAGES",
  REQUEST_STATIC_PAGE: "REQUEST_STATIC_PAGE",
  RECEIVE_STATIC_PAGE: "RECEIVE_STATIC_PAGE",
  DELETE_SERVICE_REQUEST_ATTACHMENT: "DELETE_SERVICE_REQUEST_ATTACHMENT",
  DELETED_SERVICE_REQUEST_ATTACHMENT: "DELETED_SERVICE_REQUEST_ATTACHMENT",
  UPLOAD_PROJECT_REQUEST_ATTACHMENT: "UPLOAD_PROJECT_REQUEST_ATTACHMENT",
  UPLOADED_PROJECT_REQUEST_ATTACHMENT: "UPLOADED_PROJECT_REQUEST_ATTACHMENT",
  DOWNLOAD_PROJECT_REQUEST_ATTACHMENT: "DOWNLOAD_PROJECT_REQUEST_ATTACHMENT",
  REQUEST_SUPPLEMENTAL_PRICING_LISTING: "REQUEST_SUPPLEMENTAL_PRICING_LISTING",
  RECEIVE_SUPPLEMENTAL_PRICING_LISTING: "RECEIVE_SUPPLEMENTAL_PRICING_LISTING",
  REQUEST_SUPPLEMENTAL_PRICING_ITEM: "REQUEST_SUPPLEMENTAL_PRICING_ITEM",
  RECEIVE_SUPPLEMENTAL_PRICING_ITEM: "RECEIVE_SUPPLEMENTAL_PRICING_ITEM",
  DOWNLOAD_SUPPLEMENTAL_PRICING_IMAGE: "DOWNLOAD_SUPPLEMENTAL_PRICING_IMAGE",
  REQUEST_SERVICE_KNOWLEDGE_LISTING: "REQUEST_SERVICE_KNOWLEDGE_LISTING",
  RECEIVE_SERVICE_KNOWLEDGE_LISTING: "RECEIVE_SERVICE_KNOWLEDGE_LISTING",
  REQUEST_SERVICE_KNOWLEDGE_ITEM: "REQUEST_SERVICE_KNOWLEDGE_ITEM",
  RECEIVE_SERVICE_KNOWLEDGE_ITEM: "RECEIVE_SERVICE_KNOWLEDGE_ITEM",
  DOWNLOAD_SERVICE_KNOWLEDGE_ATTACHMENT:
    "DOWNLOAD_SERVICE_KNOWLEDGE_ATTACHMENT",
  DOWNLOAD_SERVICE_KNOWLEDGE_STEP_ATTACHMENT:
    "DOWNLOAD_SERVICE_KNOWLEDGE_STEP_ATTACHMENT",
  UPLOAD_EMAIL_ATTACHMENT: "UPLOAD_EMAIL_ATTACHMENT",
  UPLOADED_EMAIL_ATTACHMENT: "UPLOADED_EMAIL_ATTACHMENT",
  REQUEST_PHONE_TYPES: "REQUEST_PHONE_TYPES",
  RECEIVE_PHONE_TYPES: "RECEIVE_PHONE_TYPES",
  REQUEST_DEALER_USER_LISTING: "REQUEST_DEALER_USER_LISTING",
  RECEIVE_DEALER_USER_LISTING: "RECEIVE_DEALER_USER_LISTING",
  REQUEST_USER_DETAILS: "REQUEST_USER_DETAILS",
  RECEIVE_USER_DETAILS: "RECEIVE_USER_DETAILS",
  REQUEST_DEALER_SECTIONS: "REQUEST_DEALER_SECTIONS",
  RECEIVE_DEALER_SECTIONS: "RECEIVE_DEALER_SECTIONS",
  UPDATE_DEALER_USER: "UPDATE_DEALER_USER",
  UPDATED_DEALER_USER: "UPDATED_DEALER_USER",
  REQUEST_DEALER_ROLES: "REQUEST_DEALER_ROLES",
  RECEIVE_DEALER_ROLES: "RECEIVE_DEALER_ROLES",
  REQUEST_DEALER_ASSIGNED_ROLES: "REQUEST_DEALER_ASSIGNED_ROLES",
  RECEIVE_DEALER_ASSIGNED_ROLES: "RECEIVE_DEALER_ASSIGNED_ROLES",
  INSERT_DEALER_USER: "INSERT_DEALER_USER",
  INSERTED_DEALER_USER: "INSERTED_DEALER_USER",
  RESET_STATE: "RESET_STATE",
  REQUEST_DEALER_FINANCIALS: "REQUEST_DEALER_FINANCIALS",
  RECEIVE_DEALER_FINANCIALS: "RECEIVE_DEALER_FINANCIALS",
  REQUEST_FINANCIAL_LISTING: "REQUEST_FINANCIAL_LISTING",
  RECEIVE_FINANCIAL_LISTING: "RECEIVE_FINANCIAL_LISTING",
  REQUEST_REMIT_HISTORY: "REQUEST_REMIT_HISTORY",
  RECEIVE_REMIT_HISTORY: "RECEIVE_REMIT_HISTORY",
  INSERT_REMITTANCE: "INSERT_REMITTANCE",
  INSERTED_REMITTANCE: "INSERTED_REMITTANCE",
  REQUEST_REMIT_DETAILS: "REQUEST_REMIT_DETAILS",
  RECEIVE_REMIT_DETAILS: "RECEIVE_REMIT_DETAILS",
  REQUEST_SALES_BUDGET: "REQUEST_SALES_BUDGET",
  RECEIVE_SALES_BUDGET: "RECEIVE_SALES_BUDGET",
  REQUEST_OPEN_SERVICE_ORDERS: "REQUEST_OPEN_SERVICE_ORDERS",
  RECEIVE_OPEN_SERVICE_ORDERS: "RECEIVE_OPEN_SERVICE_ORDERS",
  REQUEST_CLOSED_SERVICE_ORDERS: "REQUEST_CLOSED_SERVICE_ORDERS",
  RECEIVE_CLOSED_SERVICE_ORDERS: "RECEIVE_CLOSED_SERVICE_ORDERS",
  REQUEST_SERVICE_ORDER_ITEM: "REQUEST_SERVICE_ORDER_ITEM",
  RECEIVE_SERVICE_ORDER_ITEM: "RECEIVE_SERVICE_ORDER_ITEM",
  INSERT_CHANGE_REQUEST: "INSERT_CHANGE_REQUEST",
  INSERTED_CHANGE_REQUEST: "INSERTED_CHANGE_REQUEST",
  REQUEST_CHANGE_REQUESTS: "REQUEST_CHANGE_REQUESTS",
  RECEIVE_CHANGE_REQUESTS: "RECEIVE_CHANGE_REQUESTS",
  ACTION_CHANGE_REQUEST: "ACTION_CHANGE_REQUEST",
  ACTIONED_CHANGE_REQUEST: "ACTIONED_CHANGE_REQUEST",
  REQUEST_LOAD_DATES: "REQUEST_LOAD_DATES",
  RECEIVE_LOAD_DATES: "RECEIVE_LOAD_DATES",
  REQUEST_DAP_LISTING: "REQUEST_DAP_LISTING",
  RECEIVE_DAP_LISTING: "RECEIVE_DAP_LISTING",
  REQUEST_DAP_PROJECT_LISTING: "REQUEST_DAP_PROJECT_LISTING",
  RECEIVE_DAP_PROJECT_LISTING: "RECEIVE_DAP_PROJECT_LISTING",
  DOWNLOAD_PROJECT_REQUEST_PDF: "DOWNLOAD_PROJECT_REQUEST_PDF",
  DOWNLOAD_PROJECT_REQUEST_DRAWING: "DOWNLOAD_PROJECT_REQUEST_DRAWING",
  DOWNLOAD_PROJECT_REQUEST_PRICING: "DOWNLOAD_PROJECT_REQUEST_PRICING",
  REQUEST_ACCOUNT_CONTACTS: "REQUEST_ACCOUNT_CONTACTS",
  RECEIVE_ACCOUNT_CONTACTS: "RECEIVE_ACCOUNT_CONTACTS",
  REQUEST_PROJECT_LINE_ITEMS: "REQUEST_PROJECT_LINE_ITEMS",
  RECEIVE_PROJECT_LINE_ITEMS: "RECEIVE_PROJECT_LINE_ITEMS",
  REQUEST_PROJECT_LINE_ITEMS_EXCLUDING_DAP:
    "REQUEST_PROJECT_LINE_ITEMS_EXCLUDING_DAP",
  RECEIVE_PROJECT_LINE_ITEMS_EXCLUDING_DAP:
    "RECEIVE_PROJECT_LINE_ITEMS_EXCLUDING_DAP",
  REQUEST_CURRENT_REVISIONS: "REQUEST_CURRENT_REVISIONS",
  RECEIVE_CURRENT_REVISIONS: "RECEIVE_CURRENT_REVISIONS",
  REQUEST_USER_DISCOUNTS: "REQUEST_USER_DISCOUNTS",
  RECEIVE_USER_DISCOUNTS: "RECEIVE_USER_DISCOUNTS",
  VIEW_USER_DISCOUNT: "VIEW_USER_DISCOUNT",
  VIEWED_USER_DISCOUNT: "VIEWED_USER_DISCOUNT",
  DOWNLOAD_DAP_WAIVER: "DOWNLOAD_DAP_WAIVER"
};
