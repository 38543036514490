import buildQueryParams from "../helpers/queryBuilder";
import HttpService from "./adminHttpService";

export const getNewsListing = token => {
  let uri = `/admin/news/listing`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getNewsItem = (token, id) => {
  let uri = `/admin/news/item/${id}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const updateNewsItem = (token, body) => {
  const uri = "/admin/news/update";
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const insertNewsItem = (token, body) => {
  const uri = `/admin/news/insert`;
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const updateNewsAttachment = (token, id, body) => {
  const uri = `/admin/news/upload/${id}`;
  return async () => {
    return HttpService.postRequest(token, uri, body, {
      "Content-Type": "multipart/form-data"
    });
  };
};

export const downloadNewsAttachment = (token, id, filename) => {
  const uri = `/admin/news/download/${id}/${filename}`;
  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};

export const deleteNewsItem = (token, id) => {
  const uri = `/admin/news/delete/${id}`;
  return async () => {
    return HttpService.deleteRequest(token, uri);
  };
};

export const getNotificationsListing = token => {
  let uri = `/admin/notifications/listing`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getNotificationItem = (token, id) => {
  let uri = `/admin/notifications/item/${id}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const updateNotificationItem = (token, body) => {
  const uri = "/admin/notifications/update";
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const insertNotificationItem = (token, body) => {
  const uri = `/admin/notifications/insert`;
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const deleteNotificationItem = (token, id) => {
  const uri = `/admin/notifications/delete/${id}`;
  return async () => {
    return HttpService.deleteRequest(token, uri);
  };
};

export const getUserListing = (token, options) => {
  const uri = `/admin/users?${buildQueryParams(options)}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getUserLookup = token => {
  const uri = "/admin/users/lookup";

  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getUserItem = (token, id) => {
  const uri = `/admin/users/item/${id}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const sendPasswordReset = (token, body) => {
  let uri = "/account/forgotpassword";
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const updateUserItem = (token, body) => {
  const uri = "/admin/users/update";
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const insertUserItem = (token, body) => {
  const uri = `/admin/users/insert`;
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const deleteUserItem = (token, id) => {
  const uri = `/admin/users/delete/${id}`;
  return async () => {
    return HttpService.deleteRequest(token, uri);
  };
};

export const getDealerListing = token => {
  let uri = "/admin/dealers/listing";
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getFilteredDealerListing = token => {
  let uri = "/admin/dealers/filteredlisting";
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getDealerDiscount = token => {
  let uri = "/admin/discounts/dealerdiscounts";
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getDiscountViewed = (token, status = "all") => {
  let uri = `/admin/discounts/discountviewed?status=${status}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const insertDealerDiscount = (token, body) => {
  let uri = "/admin/discounts";
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const getPageListing = token => {
  let uri = "/admin/pages/listing";
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getPageItem = (token, id) => {
  const uri = `/admin/pages/item/${id}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const updatePageItem = (token, body) => {
  const uri = "/admin/pages/update";
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const insertPageItem = (token, body) => {
  const uri = `/admin/pages/insert`;
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const deletePageItem = (token, id) => {
  const uri = `/admin/pages/delete/${id}`;
  return async () => {
    return HttpService.deleteRequest(token, uri);
  };
};

export const getServiceRequestListing = (token, options) => {
  let uri = `/admin/servicerequests?${buildQueryParams(options)}`;

  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getServiceRequestItem = (token, id) => {
  const uri = `/admin/servicerequests/item/${id}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getPricingListing = token => {
  let uri = "/admin/pricing/listing";
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};
export const getPricingItem = (token, id) => {
  const uri = `/admin/pricing/item/${id}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const updatePricingItem = (token, body) => {
  const uri = "/admin/pricing/update";
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const insertPricingItem = (token, body) => {
  const uri = `/admin/pricing/insert`;
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const deletePricingItem = (token, id) => {
  const uri = `/admin/pricing/delete/${id}`;
  return async () => {
    return HttpService.deleteRequest(token, uri);
  };
};

export const getDaDeptListing = (token, body) => {
  let uri = "/admin/dds/departments/listing";
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getDaDeptItem = (token, id) => {
  const uri = `/admin/dds/departments/item/${id}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const updateDaDeptItem = (token, body) => {
  const uri = "/admin/dds/departments/update";
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const insertDaDeptItem = (token, body) => {
  const uri = `/admin/dds/departments/insert`;
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const deleteDaDeptItem = (token, id) => {
  const uri = `/admin/dds/departments/delete/${id}`;
  return async () => {
    return HttpService.deleteRequest(token, uri);
  };
};

export const getDaBomCommentsListing = (token, body) => {
  let uri = "/admin/dds/bom/listing";
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getDaBomCommentsItem = (token, id) => {
  const uri = `/admin/dds/bom/item/${id}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const updateDaBomCommentsItem = (token, body) => {
  const uri = "/admin/dds/bom/update";
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const insertDaBomCommentsItem = (token, body) => {
  const uri = `/admin/dds/bom/insert`;
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const deleteDaBomCommentsItem = (token, id) => {
  const uri = `/admin/dds/bom/delete/${id}`;
  return async () => {
    return HttpService.deleteRequest(token, uri);
  };
};

export const getDaMfgCommentsListing = (token, body) => {
  let uri = "/admin/dds/mfg/listing";
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getDaMfgCommentsItem = (token, id) => {
  const uri = `/admin/dds/mfg/item/${id}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const updateDaMfgCommentsItem = (token, body) => {
  const uri = "/admin/dds/mfg/update";
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const insertDaMfgCommentsItem = (token, body) => {
  const uri = `/admin/dds/mfg/insert`;
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const deleteDaMfgCommentsItem = (token, id) => {
  const uri = `/admin/dds/mfg/delete/${id}`;
  return async () => {
    return HttpService.deleteRequest(token, uri);
  };
};

export const getDaOrderEntryListing = (token, body) => {
  let uri = "/admin/dds/orderentry/listing";
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getDaOrderEntryItem = (token, id) => {
  const uri = `/admin/dds/orderentry/item/${id}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const updateDaOrderEntryItem = (token, body) => {
  const uri = "/admin/dds/orderentry/update";
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const insertDaOrderEntryItem = (token, body) => {
  const uri = `/admin/dds/orderentry/insert`;
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const deleteDaOrderEntryItem = (token, id) => {
  const uri = `/admin/dds/orderentry/delete/${id}`;
  return async () => {
    return HttpService.deleteRequest(token, uri);
  };
};

export const getDaWarrantyListing = token => {
  let uri = "/admin/dds/warranty/listing";
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const updateDaWarrantyItem = (token, body) => {
  const uri = "/admin/dds/warranty/update";
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const insertDaWarrantyItem = (token, body) => {
  const uri = `/admin/dds/warranty/insert`;
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const deleteDaWarrantyItem = (token, id) => {
  const uri = `/admin/dds/warranty/delete/${id}`;
  return async () => {
    return HttpService.deleteRequest(token, uri);
  };
};

export const updateWarrantyAttachment = (token, id, body) => {
  const uri = `/admin/dds/warranty/upload/${id}`;
  return async () => {
    return HttpService.postRequest(token, uri, body, {
      "Content-Type": "multipart/form-data"
    });
  };
};

export const downloadWarrantyAttachment = (token, id, filename) => {
  const uri = `/admin/dds/warranty/download/${id}/${filename}`;
  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};

export const getPricingCategoriesListing = token => {
  let uri = "/admin/pricing/categories/listing";
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getPricingCategoriesItem = (token, id) => {
  const uri = `/admin/pricing/categories/item/${id}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const updatePricingCategoriesItem = (token, body) => {
  const uri = "/admin/pricing/categories/update";
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const insertPricingCategoriesItem = (token, body) => {
  const uri = `/admin/pricing/categories/insert`;
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const deletePricingCategoriesItem = (token, id) => {
  const uri = `/admin/pricing/categories/delete/${id}`;
  return async () => {
    return HttpService.deleteRequest(token, uri);
  };
};

export const getPricingUomListing = token => {
  let uri = "/admin/pricing/uom/listing";
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getPricingUomItem = (token, id) => {
  const uri = `/admin/pricing/uom/item/${id}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const updatePricingUomItem = (token, body) => {
  const uri = "/admin/pricing/uom/update";
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const insertPricingUomItem = (token, body) => {
  const uri = `/admin/pricing/uom/insert`;
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const deletePricingUomItem = (token, id) => {
  const uri = `/admin/pricing/uom/delete/${id}`;
  return async () => {
    return HttpService.deleteRequest(token, uri);
  };
};

export const getPricingPartsListing = token => {
  let uri = "/admin/pricing/parts";
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getPhoneTypes = token => {
  const uri = `/admin/users/phonetypes`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getDaAcctPrefListing = (token, body) => {
  let uri = "/admin/dds/preference/listing";
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getDaAcctPrefItem = (token, id) => {
  const uri = `/admin/dds/preference/item/${id}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const insertDaAcctPrefItem = (token, body) => {
  const uri = `/admin/dds/preference/insert`;
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const updateDaAcctPrefItem = (token, body) => {
  const uri = "/admin/dds/preference/update";
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const deleteDaAcctPrefItem = (token, id) => {
  const uri = `/admin/dds/preference/delete/${id}`;
  return async () => {
    return HttpService.deleteRequest(token, uri);
  };
};

export const getDdsProjectListing = (token, body) => {
  let uri = "/admin/dds/project/listing";
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getDdsProjectItem = (token, id) => {
  const uri = `/admin/dds/project/item/${id}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const updateDdsProjectItem = (token, body) => {
  S;
  const uri = "/admin/dds/project/update";
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const getDaApprovalUnitListing = (token, body) => {
  let uri = "/admin/dds/approvalunit/listing";
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getDaApprovalUnitItem = (token, id) => {
  const uri = `/admin/dds/approvalunit/item/${id}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const insertDaApprovalUnitItem = (token, body) => {
  const uri = `/admin/dds/approvalunit/insert`;
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const updateDaApprovalUnitItem = (token, body) => {
  const uri = "/admin/dds/approvalunit/update";
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const deleteDaApprovalUnitItem = (token, id) => {
  const uri = `/admin/dds/approvalunit/delete/${id}`;
  return async () => {
    return HttpService.deleteRequest(token, uri);
  };
};

export const getDaApprovedForListing = (token, body) => {
  let uri = "/admin/dds/approvedfor/listing";
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getDaApprovedForItem = (token, id) => {
  const uri = `/admin/dds/approvedfor/item/${id}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const insertDaApprovedForItem = (token, body) => {
  const uri = `/admin/dds/approvedfor/insert`;
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const updateDaApprovedForItem = (token, body) => {
  const uri = "/admin/dds/approvedfor/update";
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const deleteDaApprovedForItem = (token, id) => {
  const uri = `/admin/dds/approvedfor/delete/${id}`;
  return async () => {
    return HttpService.deleteRequest(token, uri);
  };
};

export const getDDSTechniciansByDate = (token, fromDate, toDate) => {
  const uri = `/admin/dds/dashboard/techreport/techbydate?fromDate=${fromDate}&toDate=${toDate}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getDDSTechReportData = (token, techId, type, fromDate, toDate) => {
  const uri = `/admin/dds/dashboard/techreport/reportdata?techId=${techId}&type=${type}&fromDate=${fromDate}&toDate=${toDate}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getDDSAccountReportData = (
  token,
  account,
  type,
  fromDate,
  toDate
) => {
  const uri = `/admin/dds/dashboard/accountreport/reportdata?account=${account}&type=${type}&fromDate=${fromDate}&toDate=${toDate}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getDDSDepartmentReportData = (token, fromDate, toDate) => {
  const uri = `/admin/dds/dashboard/departmentreport/reportdata?fromDate=${fromDate}&toDate=${toDate}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getDDSPriorityReportData = (token, fromDate, toDate) => {
  const uri = `/admin/dds/dashboard/priorityreport/reportdata?fromDate=${fromDate}&toDate=${toDate}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getAdminRoles = token => {
  let uri = "/admin/roles";
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getSystemRoles = token => {
  let uri = "/admin/roles/roles";
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getSystemSections = token => {
  let uri = "/admin/roles/sections";
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getRemittanceItems = token => {
  let uri = "/admin/remittance/listing";
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getRemittanceDetails = (token, id) => {
  const uri = `/admin/remittance/${id}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getRemittanceInvoiceItems = (token, remitId) => {
  let uri = `/admin/remittance/${remitId}/details`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const actionRemittance = (token, remitId, action) => {
  let uri = `/admin/remittance/${remitId}/action?action=${action}`;
  return async () => {
    return HttpService.putRequest(token, uri);
  };
};

export const updateRemittance = (token, body) => {
  const uri = `/admin/remittance/${body.id}`;
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const updateRemittanceInvoice = (token, body) => {
  const uri = `/admin/remittance/invoice`;
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const downloadServiceRequestImage = (token, id, filename) => {
  const uri = `/admin/servicerequests/download/${id}/${encodeURIComponent(
    filename
  )}`;
  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};

export const uploadServiceRequestAttachment = (token, id, body) => {
  let formData = new FormData();
  formData.append("file", body, body.name);

  const uri = `/admin/servicerequests/upload/${id}`;
  return async () => {
    return HttpService.postRequest(token, uri, formData, {
      "Content-Type": "multipart/form-data"
    });
  };
};

export const deleteServiceRequestAttachment = (token, id, requestId) => {
  const uri = `/admin/servicerequests/deleteimage/${id}?requestId=${requestId}`;
  return async () => {
    return HttpService.deleteRequest(token, uri);
  };
};

export const getServiceRequestImages = (token, id) => {
  let uri = `/admin/servicerequests/images/${id}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getRelatedServiceOrders = (token, orderNumber) => {
  let uri = `/admin/servicerequests/related/${orderNumber}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getShippingMethods = token => {
  let uri = `/admin/servicerequests/shipmethods`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getPriorityLevels = token => {
  let uri = `/admin/servicerequests/prioritylevels`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getPriorityTypes = token => {
  let uri = `/admin/servicerequests/prioritytypes`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getAdditionalCrewReasons = token => {
  let uri = `/admin/servicerequests/additionalcrewreasons`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getServiceRequestCrews = token => {
  let uri = `/admin/servicerequests/crews`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getServiceAdvisors = token => {
  let uri = `/admin/servicerequests/advisors`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const insertServiceRequest = (token, body) => {
  const uri = `/admin/servicerequests/request`;
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const updateServiceRequest = (token, body) => {
  const uri = `/admin/servicerequests/request`;
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const actionServiceRequest = (token, id, action, reason, crewNumber) => {
  let uri = `/admin/servicerequests/request/action?requestid=${id}&action=${action}`;

  if (reason) {
    uri += `&reason=${reason}`;
  }

  if (crewNumber) {
    uri += `&crewnumber=${encodeURIComponent(crewNumber)}`;
  }

  return async () => {
    return HttpService.putRequest(token, uri);
  };
};

export const dispatchCompleteEmail = (token, requestId) => {
  const uri = `/admin/servicerequests/request/${requestId}/emailcomplete`;
  return async () => {
    return HttpService.postRequest(token, uri, {});
  };
};

export const dispatchMoreInfoEmail = (token, body) => {
  const uri = "/admin/servicerequests/request/moreinfo";
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const assignServiceRequestTech = (token, id, serviceAdvisorId) => {
  const uri = `/admin/servicerequests/request/tech?requestid=${id}&serviceadvisorid=${serviceAdvisorId}`;
  return async () => {
    return HttpService.putRequest(token, uri);
  };
};

export const getProductWizard = (token, body) => {
  const uri = `/admin/servicerequests/productwizard`;
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const getIncidentWizard = (token, body) => {
  const uri = `/admin/servicerequests/incidentwizard`;
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const getOrderLineItems = (token, id, isQuote) => {
  let uri = `/admin/order/lineitems?id=${id}`;

  if (isQuote) {
    uri += `&quote=${isQuote}`;
  }

  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getOrderLineItemDetails = (token, id, lineNumber) => {
  let uri = `/admin/order/lineitems/details?id=${id}&lineNumber=${lineNumber}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getKnowledgeItems = token => {
  const uri = `/admin/knowledge/listing`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getKnowledgeItem = (token, id) => {
  const uri = `/admin/knowledge/${id}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const insertKnowledgebaseItem = (token, body) => {
  const uri = `/admin/knowledge/insert`;
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const updateKnowledgebaseItem = (token, body) => {
  const uri = "/admin/knowledge/update";
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const deleteKnowledgebaseItem = (token, id) => {
  const uri = `/admin/knowledge/delete/${id}`;
  return async () => {
    return HttpService.deleteRequest(token, uri);
  };
};

export const getKnowledgebaseCategories = token => {
  const uri = `/admin/knowledge/categories`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const downloadKnowledgebaseImage = (token, id, filename) => {
  const uri = `/admin/knowledge/download/${id}/${filename}`;
  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};

export const uploadKnowledgebaseAttachment = (token, id, body) => {
  let formData = new FormData();
  formData.append("file", body, body.name);

  const uri = `/admin/knowledge/upload/${id}`;
  return async () => {
    return HttpService.postRequest(token, uri, formData, {
      "Content-Type": "multipart/form-data"
    });
  };
};

export const getStepListing = (token, id) => {
  const uri = `/admin/knowledge/item/${id}/steps`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getStepItem = (token, id) => {
  const uri = `/admin/knowledge/step/item/${id}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const insertStepItem = (token, body) => {
  const uri = `/admin/knowledge/step/insert`;
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const updateStepItem = (token, body) => {
  const uri = "/admin/knowledge/step/update";
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const deleteStepItem = (token, id) => {
  const uri = `/admin/knowledge/step/delete/${id}`;
  return async () => {
    return HttpService.deleteRequest(token, uri);
  };
};

export const downloadStepImage = (token, id, filename) => {
  const uri = `/admin/knowledge/step/download/${id}/${filename}`;
  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};

export const uploadStepAttachment = (token, id, body) => {
  let formData = new FormData();
  formData.append("file", body, body.name);

  const uri = `/admin/knowledge/step/upload/${id}`;
  return async () => {
    return HttpService.postRequest(token, uri, formData, {
      "Content-Type": "multipart/form-data"
    });
  };
};

export const deleteStepAttachment = (token, id, requestId) => {
  const uri = `/admin/knowledge/step/deleteimage/${id}?requestId=${requestId}`;
  return async () => {
    return HttpService.deleteRequest(token, uri);
  };
};

export const downloadPricingAttachment = (token, id, filename) => {
  const uri = `/admin/pricing/download/${id}/${filename}`;
  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};

export const uploadPricingAttachment = (token, id, body) => {
  const uri = `/admin/pricing/upload/${id}`;
  return async () => {
    return HttpService.postRequest(token, uri, body, {
      "Content-Type": "multipart/form-data"
    });
  };
};

export const getIncidents = (token, id, order) => {
  let uri = `/admin/servicerequests/incidents/${id}`;

  if (order) {
    uri += `?orderNumber=${order}`;
  }

  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getIncident = (token, id) => {
  const uri = `/admin/servicerequests/incident/${id}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const updateIncident = (token, body) => {
  const uri = "/admin/servicerequests/incidents";
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const getIncidentServiceTypes = token => {
  const uri = `/admin/servicerequests/incidents/servicetypes`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getServiceRequestYears = token => {
  const uri = `/admin/servicerequests/years`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getProjectRequestHeaders = (token, reportType) => {
  let uri = `/admin/dds/requests`;

  if (reportType) {
    uri += `?reportType=${reportType}`;
  }

  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const completeProjectRequest = (token, requestId) => {
  const uri = `/admin/dds/project/request/${requestId}/complete`;
  return async () => {
    return HttpService.postRequest(token, uri);
  };
};

export const actionProjectRequest = (token, action, requestId) => {
  const uri = `/admin/dds/project/request/action?action=${action}&requestId=${requestId}`;

  return async () => {
    return HttpService.putRequest(token, uri);
  };
};

export const getProjectRequest = (token, id) => {
  const uri = `/admin/dds/project/request/${id}`;

  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getProjectRequestRevisions = (token, projectId) => {
  const uri = `/admin/dds/project/request/revisions?projectId=${projectId}`;

  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getRequestTypes = token => {
  const uri = "/admin/dds/requesttypes";

  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getRequestStatuses = token => {
  const uri = "/admin/dds/requeststatuses";

  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const updateProjectRequest = (token, body) => {
  const uri = `/admin/dds/project/request/${body.id}`;
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const downloadProjectRequestAttachment = (
  token,
  id,
  filename,
  isInternal
) => {
  const uri = `/admin/dds/project/request/download/${encodeURIComponent(
    id
  )}/${encodeURIComponent(filename)}?isInternal=${encodeURIComponent(
    isInternal
  )}`;
  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};

export const deleteProjectRequestAttachment = (
  token,
  id,
  requestId,
  projectId
) => {
  const uri = `/admin/dds/project/request/deletefile/${id}?projectId=${projectId}&requestId=${requestId}`;
  return async () => {
    return HttpService.deleteRequest(token, uri);
  };
};

export const uploadProjectRequestAttachment = (
  token,
  id,
  body,
  isQuote,
  isInternal
) => {
  let formData = new FormData();
  formData.append("file", body, body.name);

  const uri = `/admin/dds/project/request/upload/${id}?isQuote=${isQuote}&isInternal=${isInternal}`;
  return async () => {
    return HttpService.postRequest(token, uri, formData, {
      "Content-Type": "multipart/form-data"
    });
  };
};

export const getProjectRequestDepartments = (token, requestId) => {
  const uri = `/admin/dds/project/request/${requestId}/departments`;

  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const insertProjectRequestDepartment = (token, body) => {
  const uri = "/admin/dds/project/request/department";

  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const deleteProjectRequestDepartment = (token, requestId, id) => {
  const uri = `/admin/dds/project/request/${requestId}/department/${id}`;

  return async () => {
    return HttpService.deleteRequest(token, uri);
  };
};

export const updateProjectRequestDepartment = (token, body, isResponse) => {
  let uri = `/admin/dds/project/request/department`;

  if (isResponse) {
    uri += "?isResponse=true";
  }

  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const uploadProjectRequestDepartmentAttachment = (token, id, body) => {
  const uri = `/admin/dds/project/request/department/upload/${id}`;
  return async () => {
    return HttpService.postRequest(token, uri, body, {
      "Content-Type": "multipart/form-data"
    });
  };
};

export const downloadProjectRequestDepartmentAttachment = (
  token,
  id,
  filename
) => {
  const uri = `/admin/dds/project/request/department/download/${id}/${filename}`;
  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};

export const resolveOrphanedRequest = (token, body) => {
  let uri = `/admin/dds/project/request/${body.id}/orphaned`;

  return async () => {
    return HttpService.patchRequest(token, uri, body, {
      "Content-Type": "multipart/form-data"
    });
  };
};

export const getDAPListing = (token, projectRequestId) => {
  const uri = `/admin/dds/project/request/dap/drawings/${projectRequestId}`;

  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getDAPItem = (token, dapId) => {
  const uri = `/admin/dds/project/request/dap/drawing/${dapId}`;

  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const updateDapItem = (
  token,
  body,
  drawingChanged,
  approvalChanged,
  pricingChanged
) => {
  const uri = `/admin/dds/project/request/dap/drawing?drawing=${drawingChanged}&approval=${approvalChanged}&pricing=${pricingChanged}`;
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const insertDapItem = (
  token,
  body,
  drawingChanged,
  approvalChanged,
  pricingChanged
) => {
  const uri = `/admin/dds/project/request/dap/drawing?drawing=${drawingChanged}&approval=${approvalChanged}&pricing=${pricingChanged}`;
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const uploadDapAttachment = (token, id, body, fileType) => {
  let formData = new FormData();
  formData.append("file", body, body.name);

  const uri = `/admin/dds/project/request/dap/${id}/attachment?fileType=${fileType}`;
  return async () => {
    return HttpService.postRequest(token, uri, formData, {
      "Content-Type": "multipart/form-data"
    });
  };
};

export const downloadDapAttachment = (token, id, filename, fileType) => {
  const uri = `/admin/dds/project/request/dap/${id}/${filename}?fileType=${fileType}`;
  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};

export const deleteDapAttachment = (token, id) => {
  const uri = `/admin/dds/project/request/dap/attachment/${id}`;
  return async () => {
    return HttpService.deleteRequest(token, uri);
  };
};

export const cloneDapItem = (token, body) => {
  const uri = `/admin/dds/project/request/dap/clone`;
  return async () => {
    return HttpService.postRequest(token, uri, body);
  };
};

export const getDapTypes = token => {
  const uri = `/admin/dds/project/request/dap/approvals`;

  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getProcessTypes = token => {
  const uri = `/admin/dds/project/request/dap/process`;

  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getQuickSelectUnits = token => {
  const uri = `/admin/dds/project/request/dap/quickselect`;

  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getRequestLineItems = (token, requestId) => {
  const uri = `/admin/dds/project/request/${requestId}/lineitems`;

  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getItemNumber = (token, requestId) => {
  const uri = `/admin/dds/project/request/dap/generateitemnumber?requestId=${requestId}`;

  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const downloadDapPrint = (token, requestId, drawingIds) => {
  let uri = `/admin/dds/project/request/dap/drawings/${requestId}/print?`;
  for (let i = 0; i < drawingIds.length; i++) {
    if (i !== 0) {
      uri += "&";
    }
    uri += `dapIds=${drawingIds[i]}`;
  }

  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};

export const updateAdditionalPrice = (token, body) => {
  const uri = `/admin/dds/project/${body.id}/price`;
  return async () => {
    return HttpService.patchRequest(token, uri, body);
  };
};

export const getChangeRequestItems = (token, options) => {
  const uri = `/admin/orders/changerequests?${buildQueryParams(options)}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const updateChangeRequestItem = (token, body) => {
  const uri = `/admin/orders/changerequests/${body.id}`;
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const updateChangeRequestDetails = (token, id, body) => {
  const uri = `/admin/orders/changerequests/${id}/details`;
  return async () => {
    return HttpService.putRequest(token, uri, body);
  };
};

export const getChangeRequestItem = (token, id) => {
  const uri = `/admin/orders/changerequests/${id}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getChangeRequestUsers = token => {
  const uri = `/admin/orders/changerequests/users`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const verifyChangeRequestItem = (token, id) => {
  const uri = `/admin/orders/changerequests/${id}/verify`;
  return async () => {
    return HttpService.patchRequest(token, uri, null);
  };
};

export const revertChangeRequestItem = (token, id) => {
  const uri = `/admin/orders/changerequests/${id}/revert`;
  return async () => {
    return HttpService.patchRequest(token, uri, null);
  };
};

export const revertAmChangeRequestItem = (token, id) => {
  const uri = `/admin/orders/changerequests/${id}/revertmanager`;
  return async () => {
    return HttpService.patchRequest(token, uri, null);
  };
};

export const attachRequestPlanningComment = (token, id, comment) => {
  const uri = `/admin/orders/changerequests/${id}/planningcomments`;

  const body = { comment };

  return async () => {
    return HttpService.patchRequest(token, uri, body);
  };
};

export const attachRequestAMComment = (token, id, comment, hours) => {
  const uri = `/admin/orders/changerequests/${id}/amcomments`;

  const body = { comment, hours };

  return async () => {
    return HttpService.patchRequest(token, uri, body);
  };
};

export const forwardChangeRequestItem = (token, id, comment, forwardTo) => {
  const uri = `/admin/orders/changerequests/${id}/forward`;

  const body = {
    comment,
    forwardTo
  };

  return async () => {
    return HttpService.patchRequest(token, uri, body);
  };
};

export const priceChangeRequestItem = (token, id, price, comment) => {
  const uri = `/admin/orders/changerequests/${id}/price`;

  const body = { price, comment };

  return async () => {
    return HttpService.patchRequest(token, uri, body);
  };
};

export const cancelChangeRequestItem = (token, id, comment) => {
  const uri = `/admin/orders/changerequests/${id}/cancel`;

  const body = {
    id,
    comment
  };

  return async () => {
    return HttpService.patchRequest(token, uri, body);
  };
};

export const supervisorActionChangeRequestItem = (token, id, isApproved) => {
  const uri = `/admin/orders/changerequests/${id}/supervisoraction?approved=${isApproved}`;
  return async () => {
    return HttpService.patchRequest(token, uri, null);
  };
};

export const supervisorAssignChangeRequestItem = (token, id) => {
  const uri = `/admin/orders/changerequests/${id}/supervisorassign`;
  return async () => {
    return HttpService.patchRequest(token, uri, null);
  };
};

export const completeChangeRequestItem = (token, id, comment) => {
  const uri = `/admin/orders/changerequests/${id}/complete`;

  const body = {
    id,
    comment
  };

  return async () => {
    return HttpService.patchRequest(token, uri, body);
  };
};

export const getUsersDds = token => {
  return async () => {
    return HttpService.getRequest(
      token,
      "/admin/dds/draftingrequests/departments/users"
    );
  };
};

export const getAccountQuotes = (token, account, requestId) => {
  let uri = `/admin/dds/accountquotes?`;
  if (account) {
    uri += `account=${account}`;
  } else if (requestId) {
    uri += `requestId=${requestId}`;
  }

  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getAccountOrders = (token, account, requestId) => {
  let uri = `/admin/dds/accountorders?`;
  if (account) {
    uri += `account=${account}`;
  } else if (requestId) {
    uri += `requestId=${requestId}`;
  }

  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getDealerLookup = token => {
  const uri = `/admin/dds/preference/lookup`;

  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getAdminLoadDates = (token, account) => {
  let uri = `/admin/loaddates?account=${account}`;
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const getUserPreference = token => {
  const uri = "/admin/accounts/preferences";
  return async () => {
    return HttpService.getRequest(token, uri);
  };
};

export const updateUserFavourite = (token, favourite) => {
  let uri = `/admin/accounts/preferences/favourite`;

  if (favourite) {
    uri += `?favourite=${favourite}`;
  }

  return async () => {
    return HttpService.patchRequest(token, uri);
  };
};

export const refreshAccessToken = () => {};
