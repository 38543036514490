export const ActionKeys = {
  REQUEST_PAGE_LISTING: "REQUEST_PAGE_LISTING",
  RECEIVE_PAGE_LISTING: "RECEIVE_PAGE_LISTING",
  REQUEST_PAGE_ITEM: "REQUEST_PAGE_ITEM",
  RECEIVE_PAGE_ITEM: "RECEIVE_PAGE_ITEM",
  REQUEST_DEALER_LISTING: "REQUEST_DEALER_LISTING",
  RECEIVE_DEALER_LISTING: "RECEIVE_DEALER_LISTING",
  REQUEST_FILTERED_DEALER_LISTING: "REQUEST_FILTERED_DEALER_LISTING",
  RECEIVE_FILTERED_DEALER_LISTING: "RECEIVE_FILTERED_DEALER_LISTING"
};
