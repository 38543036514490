export const ActionKeys = {
  REQUEST_NEWS_LISTING: "REQUEST_NEWS_LISTING",
  RECEIVE_NEWS_LISTING: "RECEIVE_NEWS_LISTING",
  REQUEST_NEWS_ITEM: "REQUEST_NEWS_ITEM",
  RECEIVE_NEWS_ITEM: "RECEIVE_NEWS_ITEM",
  UPDATE_NEWS_ITEM: "UPDATE_NEWS_ITEM",
  UPDATED_NEWS_ITEM: "UPDATED_NEWS_ITEM",
  INSERT_NEWS_ITEM: "INSERT_NEWS_ITEM",
  INSERTED_NEWS_ITEM: "INSERTED_NEWS_ITEM",
  DELETE_NEWS_ITEM: "DELETE_NEWS_ITEM",
  DELETED_NEWS_ITEM: "DELETED_NEWS_ITEM",
  REQUEST_NOTIFICATION_LISTING: "REQUEST_NOTIFICATION_LISTING",
  RECEIVE_NOTIFICATION_LISTING: "RECEIVE_NOTIFICATION_LISTING",
  REQUEST_NOTIFICATION_ITEM: "REQUEST_NOTIFICATION_ITEM",
  RECEIVE_NOTIFICATION_ITEM: "RECEIVE_NOTIFICATION_ITEM",
  UPDATE_NOTIFICATION_ITEM: "UPDATE_NOTIFICATION_ITEM",
  UPDATED_NOTIFICATION_ITEM: "UPDATED_NOTIFICATION_ITEM",
  INSERT_NOTIFICATION_ITEM: "INSERT_NOTIFICATION_ITEM",
  INSERTED_NOTIFICATION_ITEM: "INSERTED_NOTIFICATION_ITEM",
  DELETE_NOTIFICATION_ITEM: "DELETE_NOTIFICATION_ITEM",
  DELETED_NOTIFICATION_ITEM: "DELETED_NOTIFICATION_ITEM",
  REQUEST_USER_LISTING: "REQUEST_USER_LISTING",
  RECEIVE_USER_LISTING: "RECEIVE_USER_LISTING",
  REQUEST_USER_LOOKUP: "REQUEST_USER_LOOKUP",
  RECEIVE_USER_LOOKUP: "RECEIVE_USER_LOOKUP",
  REQUEST_DDS_USER_LOOKUP: "REQUEST_DDS_USER_LOOKUP",
  RECEIVE_DDS_USER_LOOKUP: "RECEIVE_DDS_USER_LOOKUP",
  REQUEST_USER_ITEM: "REQUEST_USER_ITEM",
  RECEIVE_USER_ITEM: "RECEIVE_USER_ITEM",
  UPDATE_USER_ITEM: "UPDATE_USER_ITEM",
  UPDATED_USER_ITEM: "UPDATED_USER_ITEM",
  INSERT_USER_ITEM: "INSERT_USER_ITEM",
  INSERTED_USER_ITEM: "INSERTED_USER_ITEM",
  SEND_PASSWORD_RESET: "SEND_PASSWORD_RESET",
  SENT_PASSWORD_RESET: "SENT_PASSWORD_RESET",
  DELETE_USER_ITEM: "DELETE_USER_ITEM",
  DELETED_USER_ITEM: "DELETED_USER_ITEM",
  REQUEST_DEALER_LISTING: "REQUEST_DEALER_LISTING",
  RECEIVE_DEALER_LISTING: "RECEIVE_DEALER_LISTING",
  REQUEST_DEALER_DISCOUNT: "REQUEST_DEALER_DISCOUNT",
  RECIEVE_DEALER_DISCOUNT: "RECIEVE_DEALER_DISCOUNT",
  REQUEST_DISCOUNT_VIEWED: "REQUEST_DISCOUNT_VIEWED",
  RECIEVE_DISCOUNT_VIEWED: "RECIEVE_DISCOUNT_VIEWED",
  INSERT_DEALER_DISCOUNT: "INSERT_DEALER_DISCOUNT",
  INSERTED_DEALER_DISCOUNT: "INTERTED_DEALER_DISCOUNT",
  UPDATE_PAGE_ITEM: "UPDATE_PAGE_ITEM",
  UPDATED_PAGE_ITEM: "UPDATED_PAGE_ITEM",
  INSERT_PAGE_ITEM: "INSERT_PAGE_ITEM",
  INSERTED_PAGE_ITEM: "INSERTED_PAGE_ITEM",
  DELETE_PAGE_ITEM: "DELETE_PAGE_ITEM",
  DELETED_PAGE_ITEM: "DELETED_PAGE_ITEM",
  REQUEST_SERVICE_REQUEST_LISTING: "REQUEST_SERVICE_REQUEST_LISTING",
  RECEIVE_SERVICE_REQUEST_LISTING: "RECEIVE_SERVICE_REQUEST_LISTING",
  REQUEST_SERVICE_REQUEST_ITEM: "REQUEST_SERVICE_REQUEST_ITEM",
  RECEIVE_SERVICE_REQUEST_ITEM: "RECEIVE_SERVICE_REQUEST_ITEM",
  REQUEST_PRICING_LISTING: "REQUEST_PRICING_LISTING",
  RECEIVE_PRICING_LISTING: "RECEIVE_PRICING_LISTING",
  REQUEST_PRICING_CATEGORIES: "REQUEST_PRICING_CATEGORIES",
  RECEIVE_PRICING_CATEGORIES: "RECEIVE_PRICING_CATEGORIES",
  REQUEST_DA_DEPT_LISTING: "REQUEST_DA_DEPT_LISTING",
  RECEIVE_DA_DEPT_LISTING: "RECEIVE_DA_DEPT_LISTING",
  REQUEST_DA_DEPT_ITEM: "REQUEST_DA_DEPT_ITEM",
  RECEIVE_DA_DEPT_ITEM: "RECEIVE_DA_DEPT_ITEM",
  UPDATE_DA_DEPT_ITEM: "UPDATE_DA_DEPT_ITEM",
  UPDATED_DA_DEPT_ITEM: "UPDATED_DA_DEPT_ITEM",
  INSERT_DA_DEPT_ITEM: "INSERT_DA_DEPT_ITEM",
  INSERTED_DA_DEPT_ITEM: "INSERTED_DA_DEPT_ITEM",
  DELETE_DA_DEPT_ITEM: "DELETE_DA_DEPT_ITEM",
  DELETED_DA_DEPT_ITEM: "DELETED_DA_DEPT_ITEM",
  REQUEST_DA_BOMCOMMENTS_LISTING: "REQUEST_DA_BOMCOMMENTS_LISTING",
  RECEIVE_DA_BOMCOMMENTS_LISTING: "RECEIVE_DA_BOMCOMMENTS_LISTING",
  REQUEST_DA_BOMCOMMENTS_ITEM: "REQUEST_DA_BOMCOMMENTS_ITEM",
  RECEIVE_DA_BOMCOMMENTS_ITEM: "RECEIVE_DA_BOMCOMMENTS_ITEM",
  UPDATE_DA_BOMCOMMENTS_ITEM: "UPDATE_DA_BOMCOMMENTS_ITEM",
  UPDATED_DA_BOMCOMMENTS_ITEM: "UPDATED_DA_BOMCOMMENTS_ITEM",
  INSERT_DA_BOMCOMMENTS_ITEM: "INSERT_DA_BOMCOMMENTS_ITEM",
  INSERTED_DA_BOMCOMMENTS_ITEM: "INSERTED_DA_BOMCOMMENTS_ITEM",
  DELETE_DA_BOMCOMMENTS_ITEM: "DELETE_DA_BOMCOMMENTS_ITEM",
  DELETED_DA_BOMCOMMENTS_ITEM: "DELETED_DA_BOMCOMMENTS_ITEM",
  REQUEST_DA_MFGCOMMENTS_LISTING: "REQUEST_DA_MFGCOMMENTS_LISTING",
  RECEIVE_DA_MFGCOMMENTS_LISTING: "RECEIVE_DA_MFGCOMMENTS_LISTING",
  REQUEST_DA_MFGCOMMENTS_ITEM: "REQUEST_DA_MFGCOMMENTS_ITEM",
  RECEIVE_DA_MFGCOMMENTS_ITEM: "RECEIVE_DA_MFGCOMMENTS_ITEM",
  UPDATE_DA_MFGCOMMENTS_ITEM: "UPDATE_DA_MFGCOMMENTS_ITEM",
  UPDATED_DA_MFGCOMMENTS_ITEM: "UPDATED_DA_MFGCOMMENTS_ITEM",
  INSERT_DA_MFGCOMMENTS_ITEM: "INSERT_DA_MFGCOMMENTS_ITEM",
  INSERTED_DA_MFGCOMMENTS_ITEM: "INSERTED_DA_MFGCOMMENTS_ITEM",
  DELETE_DA_MFGCOMMENTS_ITEM: "DELETE_DA_MFGCOMMENTS_ITEM",
  DELETED_DA_MFGCOMMENTS_ITEM: "DELETED_DA_MFGCOMMENTS_ITEM",
  REQUEST_DA_ORDERENTRY_LISTING: "REQUEST_DA_ORDERENTRY_LISTING",
  RECEIVE_DA_ORDERENTRY_LISTING: "RECEIVE_DA_ORDERENTRY_LISTING",
  REQUEST_DA_ORDERENTRY_ITEM: "REQUEST_DA_ORDERENTRY_ITEM",
  RECEIVE_DA_ORDERENTRY_ITEM: "RECEIVE_DA_ORDERENTRY_ITEM",
  UPDATE_DA_ORDERENTRY_ITEM: "UPDATE_DA_ORDERENTRY_ITEM",
  UPDATED_DA_ORDERENTRY_ITEM: "UPDATED_DA_ORDERENTRY_ITEM",
  INSERT_DA_ORDERENTRY_ITEM: "INSERT_DA_ORDERENTRY_ITEM",
  INSERTED_DA_ORDERENTRY_ITEM: "INSERTED_DA_ORDERENTRY_ITEM",
  DELETE_DA_ORDERENTRY_ITEM: "DELETE_DA_ORDERENTRY_ITEM",
  DELETED_DA_ORDERENTRY_ITEM: "DELETED_DA_ORDERENTRY_ITEM",
  REQUEST_DA_ACCTPREF_LISTING: "REQUEST_DA_ACCTPREF_LISTING",
  RECEIVE_DA_ACCTPREF_LISTING: "RECEIVE_DA_ACCTPREF_LISTING",
  REQUEST_DA_ACCTPREF_ITEM: "REQUEST_DA_ACCTPREF_ITEM",
  RECEIVE_DA_ACCTPREF_ITEM: "RECEIVE_DA_ACCTPREF_ITEM",
  UPDATE_DA_ACCTPREF_ITEM: "UPDATE_DA_ACCTPREF_ITEM",
  UPDATED_DA_ACCTPREF_ITEM: "UPDATED_DA_ACCTPREF_ITEM",
  INSERT_DA_ACCTPREF_ITEM: "INSERT_DA_ACCTPREF_ITEM",
  INSERTED_DA_ACCTPREF_ITEM: "INSERTED_DA_ACCTPREF_ITEM",
  DELETE_DA_ACCTPREF_ITEM: "DELETE_DA_ACCTPREF_ITEM",
  DELETED_DA_ACCTPREF_ITEM: "DELETED_DA_ACCTPREF_ITEM",
  REQUEST_DA_WARRANTY_LISTING: "REQUEST_DA_WARRANTY_ITEM",
  RECEIVE_DA_WARRANTY_LISTING: "RECEIVE_DA_WARRANTY_ITEM",
  REQUEST_PHONE_TYPES: "REQUEST_PHONE_TYPES",
  RECEIVE_PHONE_TYPES: "RECEIVE_PHONE_TYPES",
  UPDATED_USER_TOKEN: "UPDATED_USER_TOKEN",
  UPDATED_LOGGED_IN_USER: "UPDATED_LOGGED_IN_USER",
  UPDATE_NEWS_ATTACHMENT: "UPDATE_NEWS_ATTACHMENT",
  UPDATED_NEWS_ATTACHMENT: "UPDATED_NEWS_ATTACHMENT",
  DOWNLOAD_NEWS_ATTACHMENT: "DOWNLOAD_NEWS_ATTACHMENT",
  DOWNLOADED_NEWS_ATTACHMENT: "DOWNLOADED_NEWS_ATTACHMENT",
  REQUEST_ADMIN_ROLES: "REQUEST_ADMIN_ROLES",
  RECEIVE_ADMIN_ROLES: "RECEIVE_ADMIN_ROLES",
  DOWNLOAD_SERVICE_REQUEST_IMAGE: "DOWNLOAD_SERVICE_REQUEST_IMAGE",
  UPLOAD_SERVICE_REQUEST_ATTACHMENT: "UPLOAD_SERVICE_REQUEST_ATTACHMENT",
  UPLOADED_SERVICE_REQUEST_ATTACHMENT: "UPLOADED_SERVICE_REQUEST_ATTACHMENT",
  DELETE_SERVICE_REQUEST_ATTACHMENT: "DELETE_SERVICE_REQUEST_ATTACHMENT",
  DELETED_SERVICE_REQUEST_ATTACHMENT: "DELETED_SERVICE_REQUEST_ATTACHMENT",
  REQUEST_DDS_PROJECT_LISTING: "REQUEST_DDS_PROJECT_LISTING",
  RECEIVE_DDS_PROJECT_LISTING: "RECEIVE_DDS_PROJECT_LISTING",
  REQUEST_DDS_PROJECT_ITEM: "REQUEST_DDS_PROJECT_ITEM",
  RECEIVE_DDS_PROJECT_ITEM: "RECEIVE_DDS_PROJECT_ITEM",
  UPDATE_DDS_PROJECT_ITEM: "UPDATE_DDS_PROJECT_ITEM",
  UPDATED_DDS_PROJECT_ITEM: "UPDATED_DDS_PROJECT_ITEM",
  INSERT_DDS_PROJECT_ITEM: "INSERT_DDS_PROJECT_ITEM",
  INSERTED_DDS_PROJECT_ITEM: "INSERTED_DDS_PROJECT_ITEM",
  DELETE_DDS_PROJECT_ITEM: "DELETE_DDS_PROJECT_ITEM",
  DELETED_DDS_PROJECT_ITEM: "DELETED_DDS_PROJECT_ITEM",
  REQUEST_DA_APPROVALUNIT_LISTING: "REQUEST_DA_APPROVALUNIT_LISTING",
  RECEIVE_DA_APPROVALUNIT_LISTING: "RECEIVE_DA_APPROVALUNIT_LISTING",
  REQUEST_DA_APPROVALUNIT_ITEM: "REQUEST_DA_APPROVALUNIT_ITEM",
  RECEIVE_DA_APPROVALUNIT_ITEM: "RECEIVE_DA_APPROVALUNIT_ITEM",
  UPDATE_DA_APPROVALUNIT_ITEM: "UPDATE_DA_APPROVALUNIT_ITEM",
  UPDATED_DA_APPROVALUNIT_ITEM: "UPDATED_DA_APPROVALUNIT_ITEM",
  INSERT_DA_APPROVALUNIT_ITEM: "INSERT_DA_APPROVALUNIT_ITEM",
  INSERTED_DA_APPROVALUNIT_ITEM: "INSERTED_DA_APPROVALUNIT_ITEM",
  DELETE_DA_APPROVALUNIT_ITEM: "DELETE_DA_APPROVALUNIT_ITEM",
  DELETED_DA_APPROVALUNIT_ITEM: "DELETED_DA_APPROVALUNIT_ITEM",
  REQUEST_DA_APPROVEDFOR_LISTING: "REQUEST_DA_APPROVEDFOR_LISTING",
  RECEIVE_DA_APPROVEDFOR_LISTING: "RECEIVE_DA_APPROVEDFOR_LISTING",
  REQUEST_DA_APPROVEDFOR_ITEM: "REQUEST_DA_APPROVEDFOR_ITEM",
  RECEIVE_DA_APPROVEDFOR_ITEM: "RECEIVE_DA_APPROVEDFOR_ITEM",
  UPDATE_DA_APPROVEDFOR_ITEM: "UPDATE_DA_APPROVEDFOR_ITEM",
  UPDATED_DA_APPROVEDFOR_ITEM: "UPDATED_DA_APPROVEDFOR_ITEM",
  INSERT_DA_APPROVEDFOR_ITEM: "INSERT_DA_APPROVEDFOR_ITEM",
  INSERTED_DA_APPROVEDFOR_ITEM: "INSERTED_DA_APPROVEDFOR_ITEM",
  DELETE_DA_APPROVEDFOR_ITEM: "DELETE_DA_APPROVEDFOR_ITEM",
  DELETED_DA_APPROVEDFOR_ITEM: "DELETED_DA_APPROVEDFOR_ITEM",
  REQUEST_DDSTECHREPORT_DATA: "REQUEST_DDSTECHREPORT_DATA",
  RECIEVE_DDSTECHREPORT_DATA: "RECIEVE_DDSTECHREPORT_DATA",
  REQUEST_DDSACCOUNTREPORT_DATA: "REQUEST_DDSACCOUNTREPORT_DATA",
  RECIEVE_DDSACCOUNTREPORT_DATA: "RECIEVE_DDSACCOUNTREPORT_DATA",
  REQUEST_DDSDEPARTMENTREPORT_DATA: "REQUEST_DDSDEPARTMENTREPORT_DATA",
  RECIEVE_DDSDEPARTMENTREPORT_DATA: "RECIEVE_DDSDEPARTMENTREPORT_DATA",
  REQUEST_DDSPRIORITYREPORT_DATA: "REQUEST_DDSPRIORITYREPORT_DATA",
  RECIEVE_DDSPRIORITYREPORT_DATA: "RECIEVE_DDSPRIORITYREPORT_DATA",
  REQUEST_REMITTANCE_ITEMS: "REQUEST_REMITTANCE_ITEMS",
  RECEIVE_REMITTANCE_ITEMS: "RECEIVE_REMITTANCE_ITEMS",
  REQUEST_REMITTANCE_ITEM: "REQUEST_REMITTANCE_ITEM",
  RECEIVE_REMITTANCE_ITEM: "RECEIVE_REMITTANCE_ITEM",
  REQUEST_REMITTANCE_INVOICE_ITEMS: "REQUEST_REMITTANCE_INVOICE_ITEMS",
  RECEIVE_REMITTANCE_INVOICE_ITEMS: "RECEIVE_REMITTANCE_INVOICE_ITEMS",
  ACTION_REMITTANCE: "ACTION_REMITTANCE",
  ACTIONED_REMITTANCE: "ACTIONED_REMITTANCE",
  UPDATE_REMITTANCE: "UPDATE_REMITTANCE",
  UPDATED_REMITTANCE: "UPDATED_REMITTANCE",
  UPDATE_REMITTANCE_INVOICE: "UPDATE_REMITTANCE_INVOICE",
  UPDATED_REMITTANCE_INVOICE: "UPDATED_REMITTANCE_INVOICE",
  REQUEST_SHIPPING_METHODS: "REQUEST_SHIPPING_METHODS",
  RECEIVE_SHIPPING_METHODS: "RECEIVE_SHIPPING_METHODS",
  REQUEST_PRIORITY_LEVELS: "REQUEST_PRIORITY_LEVELS",
  RECEIVE_PRIORITY_LEVELS: "RECEIVE_PRIORITY_LEVELS",
  REQUEST_PRIORITY_TYPES: "REQUEST_PRIORITY_TYPES",
  RECEIVE_PRIORITY_TYPES: "RECEIVE_PRIORITY_TYPES",
  REQUEST_ADDITIONAL_CREW_REASONS: "REQUEST_ADDITIONAL_CREW_REASONS",
  RECEIVE_ADDITIONAL_CREW_REASONS: "RECEIVE_ADDITIONAL_CREW_REASONS",
  REQUEST_CREWS: "REQUEST_CREWS",
  RECEIVE_CREWS: "RECEIVE_CREWS",
  REQUEST_SERVICE_ADVISORS: "REQUEST_SERVICE_ADVISORS",
  RECEIVE_SERVICE_ADVISORS: "RECEIVE_SERVICE_ADVISORS",
  INSERT_SERVICE_REQUEST: "INSERT_SERVICE_REQUEST",
  INSERTED_SERVICE_REQUEST: "INSERTED_SERVICE_REQUEST",
  UPDATE_SERVICE_REQUEST: "UPDATE_SERVICE_REQUEST",
  UPDATED_SERVICE_REQUEST: "UPDATED_SERVICE_REQUEST",
  ACTION_SERVICE_REQUEST: "ACTION_SERVICE_REQUEST",
  ACTIONED_SERVICE_REQUEST: "ACTIONED_SERVICE_REQUEST",
  ASSIGN_SERVICE_REQUEST_TECH: "ASSIGN_SERVICE_REQUEST_TECH",
  ASSIGNED_SERVICE_REQUEST_TECH: "ASSIGNED_SERVICE_REQUEST_TECH",
  REQUEST_SERVICE_REQUEST_YEARS: "REQUEST_SERVICE_REQUEST_YEARS",
  RECEIVE_SERVICE_REQUEST_YEARS: "RECEIVE_SERVICE_REQUEST_YEARS",
  REQUEST_PRODUCT_WIZARD: "REQUEST_PRODUCT_WIZARD",
  RECEIVE_PRODUCT_WIZARD: "RECEIVE_PRODUCT_WIZARD",
  REQUEST_INCIDENT_WIZARD: "REQUEST_INCIDENT_WIZARD",
  RECEIVE_INCIDENT_WIZARD: "RECEIVE_INCIDENT_WIZARD",
  REQUEST_ORDER_LINE_ITEMS: "REQUEST_ORDER_LINE_ITEMS",
  RECEIVE_ORDER_LINE_ITEMS: "RECEIVE_ORDER_LINE_ITEMS",
  REQUEST_ORDER_LINE_ITEM_DETAILS: "REQUEST_ORDER_LINE_ITEM_DETAILS",
  RECEIVE_ORDER_LINE_ITEM_DETAILS: "RECEIVE_ORDER_LINE_ITEM_DETAILS",
  REQUEST_PROJECT_REQUEST_HEADERS: "REQUEST_PROJECT_REQUEST_HEADERS",
  RECEIVE_PROJECT_REQUEST_HEADERS: "RECEIVE_PROJECT_REQUEST_HEADERS",
  REQUEST_INCIDENT_SERVICE_TYPES: "REQUEST_INCIDENT_SERVICE_TYPES",
  RECEIVE_INCIDENT_SERVICE_TYPES: "RECEIVE_INCIDENT_SERVICE_TYPES",
  ACTION_PROJECT_REQUEST: "ACTION_PROJECT_REQUEST",
  ACTIONED_PROJECT_REQUEST: "ACTIONED_PROJECT_REQUEST",
  BEGIN_LOAD_PROJECT_REQUEST: "BEGIN_LOAD_PROJECT_REQUEST",
  END_LOAD_PROJECT_REQUEST: "END_LOAD_PROJECT_REQUEST",
  COMPLETE_PROJECT_REQUEST: "COMPLETE_PROJECT_REQUEST",
  COMPLETED_PROJECT_REQUEST: "COMPLETED_PROJECT_REQUEST",
  REQUEST_PROJECT_REQUEST: "REQUEST_PROJECT_REQUEST",
  RECEIVE_PROJECT_REQUEST: "RECEIVE_PROJECT_REQUEST",
  REQUEST_PROJECT_REQUEST_REVISIONS: "REQUEST_PROJECT_REQUEST_REVISIONS",
  RECEIVE_PROJECT_REQUEST_REVISIONS: "RECEIVE_PROJECT_REQUEST_REVISIONS",
  REQUEST_REQUEST_TYPES: "REQUEST_REQUEST_TYPES",
  RECEIVE_REQUEST_TYPES: "RECEIVE_REQUEST_TYPES",
  REQUEST_REQUEST_STATUSES: "REQUEST_REQUEST_STATUSES",
  RECEIVE_REQUEST_STATUSES: "RECEIVE_REQUEST_STATUSES",
  REQUEST_INCIDENTS: "REQUEST_INCIDENTS",
  RECEIVE_INCIDENTS: "RECEIVE_INCIDENTS",
  REQUEST_INCIDENT: "REQUEST_INCIDENT",
  RECEIVE_INCIDENT: "RECEIVE_INCIDENT",
  UPDATE_INCIDENT: "UPDATE_INCIDENT",
  UPDATED_INCIDENT: "UPDATED_INCIDENT",
  UPDATE_PROJECT_REQUEST: "UPDATE_PROJECT_REQUEST",
  UPDATED_PROJECT_REQUEST: "UPDATED_PROJECT_REQUEST",
  DOWNLOAD_PROJECT_REQUEST_ATTACHMENT: "DOWNLOAD_PROJECT_REQUEST_ATTACHMENT",
  DOWNLOADED_PROJECT_REQUEST_ATTACHMENT:
    "DOWNLOADED_PROJECT_REQUEST_ATTACHMENT",
  DELETE_PROJECT_REQUEST_ATTACHMENT: "DELETE_PROJECT_REQUEST_ATTACHMENT",
  DELETED_PROJECT_REQUEST_ATTACHMENT: "DELETED_PROJECT_REQUEST_ATTACHMENT",
  UPLOAD_PROJECT_REQUEST_ATTACHMENT: "UPLOAD_PROJECT_REQUEST_ATTACHMENT",
  UPLOADED_PROJECT_REQUEST_ATTACHMENT: "UPLOADED_PROJECT_REQUEST_ATTACHMENT",
  REQUEST_PROJECT_REQUEST_DEPARTMENTS: "REQUEST_PROJECT_REQUEST_DEPARTMENTS",
  RECEIVE_PROJECT_REQUEST_DEPARTMENTS: "RECEIVE_PROJECT_REQUEST_DEPARTMENTS",
  INSERT_PROJECT_REQUEST_DEPARTMENT: "INSERT_PROJECT_REQUEST_DEPARTMENT",
  INSERTED_PROJECT_REQUEST_DEPARTMENT: "INSERTED_PROJECT_REQUEST_DEPARTMENT",
  DELETE_PROJECT_REQUEST_DEPARTMENT: "INSERT_PROJECT_REQUEST_DEPARTMENT",
  DELETED_PROJECT_REQUEST_DEPARTMENT: "INSERTED_PROJECT_REQUEST_DEPARTMENT",
  UPDATE_PROJECT_REQUEST_DEPARTMENT: "INSERT_PROJECT_REQUEST_DEPARTMENT",
  UPDATED_PROJECT_REQUEST_DEPARTMENT: "INSERTED_PROJECT_REQUEST_DEPARTMENT",
  UPLOAD_PROJECT_REQUEST_DEPARTMENT_ATTACHMENT:
    "UPLOAD_PROJECT_REQUEST_DEPARTMENT_ATTACHMENT",
  UPLOADED_PROJECT_REQUEST_DEPARTMENT_ATTACHMENT:
    "UPLOADED_PROJECT_REQUEST_DEPARTMENT_ATTACHMENT",
  DOWNLOAD_PROJECT_REQUEST_DEPARTMENT_ATTACHMENT:
    "DOWNLOAD_PROJECT_REQUEST_DEPARTMENT_ATTACHMENT",
  DOWNLOADED_PROJECT_REQUEST_DEPARTMENT_ATTACHMENT:
    "DOWNLOADED_PROJECT_REQUEST_DEPARTMENT_ATTACHMENT",
  RESOLVE_PROJECT_REQUEST: "RESOLVE_PROJECT_REQUEST",
  RESOLVED_PROJECT_REQUEST: "RESOLVED_PROJECT_REQUEST",
  REQUEST_DAP_LISTING: "REQUEST_DAP_LISTING",
  RECEIVE_DAP_LISTING: "RECEIVE_DAP_LISTING",
  REQUEST_DAP_ITEM: "REQUEST_DAP_ITEM",
  RECEIVE_DAP_ITEM: "RECEIVE_DAP_ITEM",
  UPDATE_DAP_ITEM: "UPDATE_DAP_ITEM",
  UPDATED_DAP_ITEM: "UPDATED_DAP_ITEM",
  CLONE_DAP_ITEM: "CLONE_DAP_ITEM",
  CLONED_DAP_ITEM: "CLONED_DAP_ITEM",
  REQUEST_DAP_TYPES: "REQUEST_DAP_TYPES",
  RECEIVE_DAP_TYPES: "RECEIVE_DAP_TYPES",
  REQUEST_DAP_PROCESS: "REQUEST_DAP_PROCESS",
  RECEIVE_DAP_PROCESS: "RECEIVE_DAP_PROCESS",
  REQUEST_QUICK_SELECT_UNITS: "REQUEST_QUICK_SELECT_UNITS",
  RECEIVE_QUICK_SELECT_UNITS: "RECEIVE_QUICK_SELECT_UNITS",
  REQUEST_LINE_ITEMS: "REQUEST_LINE_ITEMS",
  RECEIVE_LINE_ITEMS: "RECEIVE_LINE_ITEMS",
  REQUEST_ITEM_NUMBER: "REQUEST_ITEM_NUMBER",
  RECEIVE_ITEM_NUMBER: "RECEIVE_ITEM_NUMBER",
  INSERT_DAP_ITEM: "INSERT_DAP_ITEM",
  INSERTED_DAP_ITEM: "INSERTED_DAP_ITEM",
  UPLOAD_DAP_ATTACHMENT: "UPLOAD_DAP_ATTACHMENT",
  UPLOADED_DAP_ATTACHMENT: "UPLOADED_DAP_ATTACHMENT",
  DOWNLOAD_DAP_ATTACHMENT: "DOWNLOAD_NEWS_ATTACHMENT",
  DOWNLOADED_DAP_ATTACHMENT: "DOWNLOADED_NEWS_ATTACHMENT",
  DELETE_DAP_ATTACHMENT: "DELETE_DAP_ATTACHMENT",
  DELETED_DAP_ATTACHMENT: "DELETED_DAP_ATTACHMENT",
  DOWNLOAD_DAP_PRINT: "DOWNLOAD_DAP_PRINT",
  DOWNLOADED_DAP_PRINT: "DOWNLOADED_DAP_PRINT",
  UPDATE_PROJECT_VALUE: "UPDATE_PROJECT_VALUE",
  UPDATED_PROJECT_VALUE: "UPDATED_PROJECT_VALUE",
  REQUEST_CHANGE_ITEMS: "REQUEST_CHANGE_ITEMS",
  RECEIVE_CHANGE_ITEMS: "RECEIVE_CHANGE_ITEMS",
  REQUEST_CHANGE_ITEM: "REQUEST_CHANGE_ITEM",
  RECEIVE_CHANGE_ITEM: "RECEIVE_CHANGE_ITEM",
  REQUEST_CHANGE_USER_ITEMS: "REQUEST_CHANGE_USER_ITEMS",
  RECEIVE_CHANGE_USER_ITEMS: "RECEIVE_CHANGE_USER_ITEMS",
  UPDATE_CHANGE_ITEM: "UPDATE_CHANGE_ITEM",
  UPDATED_CHANGE_ITEM: "UPDATED_CHANGE_ITEM",
  UPDATE_CHANGE_DETAILS: "UPDATE_CHANGE_DETAILS",
  UPDATED_CHANGE_DETAILS: "UPDATED_CHANGE_DETAILS",
  VERIFY_CHANGE_ITEM: "VERIFY_CHANGE_ITEM",
  VERIFIED_CHANGE_ITEM: "VERIFIED_CHANGE_ITEM",
  REVERT_CHANGE_ITEM: "REVERT_CHANGE_ITEM",
  REVERTED_CHANGE_ITEM: "REVERTED_CHANGE_ITEM",
  REVERT_AM_CHANGE_ITEM: "REVERT_AM_CHANGE_ITEM",
  REVERTED_AM_CHANGE_ITEM: "REVERTED_AM_CHANGE_ITEM",
  FORWARD_CHANGE_ITEM: "FORWARD_CHANGE_ITEM",
  FORWARDED_CHANGE_ITEM: "FORWARDED_CHANGE_ITEM",
  PROD_COMMENT_CHANGE_ITEM: "PROD_COMMENT_CHANGE_ITEM",
  PROD_COMMENTED_CHANGE_ITEM: "PROD_COMMENTED_CHANGE_ITEM",
  AM_COMMENT_CHANGE_ITEM: "AM_COMMENT_CHANGE_ITEM",
  AM_COMMENTED_CHANGE_ITEM: "AM_COMMENTED_CHANGE_ITEM",
  PRICE_CHANGE_ITEM: "PRICE_CHANGE_ITEM",
  PRICED_CHANGE_ITEM: "PRICED_CHANGE_ITEM",
  CANCEL_CHANGE_ITEM: "CANCEL_CHANGE_ITEM",
  CANCELLED_CHANGE_ITEM: "CANCELLED_CHANGE_ITEM",
  ACTION_CHANGE_ITEM: "ACTION_CHANGE_ITEM",
  ACTIONED_CHANGE_ITEM: "ACTIONED_CHANGE_ITEM",
  ASSIGN_CHANGE_ITEM: "ASSIGN_CHANGE_ITEM",
  ASSIGNED_CHANGE_ITEM: "ASSIGNED_CHANGE_ITEM",
  COMPLETE_CHANGE_ITEM: "COMPLETE_CHANGE_ITEM",
  COMPLETED_CHANGE_ITEM: "COMPLETED_CHANGE_ITEM",
  REQUEST_ACCOUNT_QUOTES: "REQUEST_ACCOUNT_QUOTES",
  RECEIVE_ACCOUNT_QUOTES: "RECEIVE_ACCOUNT_QUOTES",
  REQUEST_ACCOUNT_ORDERS: "REQUEST_ACCOUNT_ORDERS",
  RECEIVE_ACCOUNT_ORDERS: "RECEIVE_ACCOUNT_ORDERS",
  REQUEST_DEALER_LOOKUP: "REQUEST_DEALER_LOOKUP",
  RECEIVE_DEALER_LOOKUP: "RECEIVE_DEALER_LOOKUP",
  REQUEST_LOAD_DATES: "REQUEST_LOAD_DATES",
  RECEIVE_LOAD_DATES: "RECEIVE_LOAD_DATES",
  REQUEST_SYSTEM_ROLES: "REQUEST_SYSTEM_ROLES",
  RECEIVE_SYSTEM_ROLES: "RECEIVE_SYSTEM_ROLES",
  REQUEST_SYSTEM_SECTIONS: "REQUEST_SYSTEM_SECTIONS",
  RECEIVE_SYSTEM_SECTIONS: "RECEIVE_SYSTEM_SECTIONS",
  REQUEST_KNOWLEDGE_ITEMS: "REQUEST_KNOWLEDGE_ITEMS",
  RECEIVE_KNOWLEDGE_ITEMS: "RECEIVE_KNOWLEDGE_ITEMS",
  REQUEST_KNOWLEDGE_ITEM: "REQUEST_KNOWLEDGE_ITEM",
  RECEIVE_KNOWLEDGE_ITEM: "RECEIVE_KNOWLEDGE_ITEM",
  UPDATE_KNOWLEDGE_ITEM: "UPDATE_KNOWLEDGE_ITEM",
  UPDATED_KNOWLEDGE_ITEM: "UPDATED_KNOWLEDGE_ITEM",
  INSERT_KNOWLEDGE_ITEM: "INSERT_KNOWLEDGE_ITEM",
  INSERTED_KNOWLEDGE_ITEM: "INSERTED_KNOWLEDGE_ITEM",
  DELETE_KNOWLEDGE_ITEM: "DELETE_KNOWLEDGE_ITEM",
  DELETED_KNOWLEDGE_ITEM: "DELETED_KNOWLEDGE_ITEM",
  UPLOAD_KNOWLEDGE_IMAGE: "UPLOAD_KNOWLEDGE_IMAGE",
  UPLOADED_KNOWLEDGE_IMAGE: "UPLOADED_KNOWLEDGE_IMAGE",
  DOWNLOAD_KNOWLEDGE_IMAGE: "DOWNLOAD_KNOWLEDGE_IMAGE",
  DOWNLOADED_KNOWLEDGE_IMAGE: "DOWNLOADED_KNOWLEDGE_IMAGE",
  REQUEST_KNOWLEDGE_CATEGORIES: "REQUEST_KNOWLEDGE_CATEGORIES",
  RECEIVE_KNOWLEDGE_CATEGORIES: "RECEIVE_KNOWLEDGE_CATEGORIES",
  REQUEST_KNOWLEDGE_STEPS: "REQUEST_KNOWLEDGE_STEPS",
  RECEIVE_KNOWLEDGE_STEPS: "RECEIVE_KNOWLEDGE_STEPS",
  INSERT_KNOWLEDGE_STEP: "INSERT_KNOWLEDGE_STEP",
  INSERTED_KNOWLEDGE_STEP: "INSERTED_KNOWLEDGE_STEP",
  UPDATE_KNOWLEDGE_STEP: "UPDATE_KNOWLEDGE_STEP",
  UPDATED_KNOWLEDGE_STEP: "UPDATED_KNOWLEDGE_STEP",
  DELETE_KNOWLEDGE_STEP: "DELETE_KNOWLEDGE_STEP",
  DELETED_KNOWLEDGE_STEP: "DELETED_KNOWLEDGE_STEP",
  UPLOAD_STEP_IMAGE: "UPLOAD_STEP_IMAGE",
  UPLOADED_STEP_IMAGE: "UPLOADED_STEP_IMAGE",
  DOWNLOAD_STEP_IMAGE: "DOWNLOAD_STEP_IMAGE",
  DOWNLOADED_STEP_IMAGE: "DOWNLOADED_STEP_IMAGE",
  DELETE_STEP_IMAGE: "DELETE_STEP_IMAGE",
  DELETED_STEP_IMAGE: "DELETED_STEP_IMAGE",
  REQUEST_USER_PREFERENCE: "REQUEST_USER_PREFERENCE",
  RECEIVE_USER_PREFERENCE: "RECEIVE_USER_PREFERENCE",
  CLEAR_USER_PREFERENCE: "CLEAR_USER_PREFERENCE",
  UPDATE_USER_FAVOURITE: "UPDATE_USER_FAVOURITE",
  UPDATED_USER_FAVOURITE: "UPDATED_USER_FAVOURITE",
  SET_USER_PREFERENCE: "SET_USER_PREFERENCE",
  REQUEST_PAGE_ITEM: "REQUEST_PAGE_ITEM",
  RECEIVE_PAGE_ITEM: "RECEIVE_PAGE_ITEM"
};
