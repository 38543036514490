import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import userReducer from "./userReducer";
import adminReducer from "./adminReducer";
import layoutReducer from "./layoutReducer";
import sharedReducer from "./sharedReducer";

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    userReducer,
    adminReducer,
    layoutReducer,
    sharedReducer
  });

export default createRootReducer;
