import HttpService from "./httpService";

export const getNewsListing = (token, pageSize, offset, search) => {
  let uri = `/news/listing?pageSize=${pageSize}&offset=${offset}`;
  if (search !== "") {
    uri += `&search=${search}`;
  }
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getNewsItem = (token, id) => {
  let uri = `/news/item?id=${id}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getActiveNotifications = token => {
  let uri = `/news/notifications`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getCurrentOrderListing = (token, year) => {
  let uri = `/order/current`;
  if (year != null) uri += `?year=${year}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getPastOrderListing = (token, year) => {
  let uri = `/order/past`;
  if (year != null) uri += `?year=${year}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getOrderItem = (token, id) => {
  let uri = `/order?id=${id}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getChangeRequests = (token, id) => {
  let uri = `/order/changerequests?orderid=${id}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const actionChangeRequest = (token, id, approve) => {
  let uri = `/order/changerequestaction?id=${id}&approve=${approve}`;
  return async () => {
    return HttpService.postRequest(uri, token);
  };
};

export const getOrderCustomer = (token, id) => {
  let uri = `/order/customer?id=${id}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getInvoiceListing = (token, year) => {
  let uri = `/invoice/list`;
  if (year != null) uri += `?year=${year}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getInvoiceItem = (token, id) => {
  let uri = `/invoice?id=${id}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getInvoiceLineItems = (token, id, orderNumber, orderSuffix) => {
  let uri = `/invoice/lineitems?id=${id}&orderNumber=${orderNumber}-${orderSuffix}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getOrderLineItems = (token, id, quote = false) => {
  let uri = `/order/lineitems?id=${id}&quote=${quote}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getDDSOrderLineItems = (token, id, quote = false) => {
  let uri = `/dds/project/lineitems?id=${id}&quote=${quote}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getProjectLineItems = (token, id) => {
  let uri = `/dds/project/projectlineitems?id=${id}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getProjectLineItemsExcludingDAP = (token, id) => {
  let uri = `/dds/project/projectlineitems?id=${id}&excludeDAP=true`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getCurrentRevisions = (token, id) => {
  let uri = `/dds/project/revisions?projectid=${id}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getDDSOrderLineItemsCount = (token, id, quote = false) => {
  let uri = `/dds/project/lineitemscount?id=${id}&quote=${quote}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getOrderLineItemDetails = (token, id, lineNumber) => {
  let uri = `/order/lineitems/details?id=${id}&lineNumber=${lineNumber}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const updateUserEmail = (token, email) => {
  const uri = "/user/email";
  return async () => {
    return HttpService.putRequest(uri, token, email);
  };
};

export const updateMFAType = (token, mfaType) => {
  const uri = "/user/mfatype";
  return async () => {
    return HttpService.putRequest(uri, token, mfaType);
  };
};

export const updateUserPassword = (token, password) => {
  const uri = "/user/password";
  return async () => {
    return HttpService.putRequest(uri, token, password);
  };
};

export const updateUserPhone = (token, phone) => {
  const uri = "/user/phone";
  return async () => {
    return HttpService.putRequest(uri, token, phone);
  };
};

export const insertUserPhone = (token, phone) => {
  const uri = "/user/phone";
  return async () => {
    return HttpService.postRequest(uri, token, phone);
  };
};

export const deleteUserPhone = (token, id) => {
  const uri = `/user/phone/${id}`;
  return async () => {
    return HttpService.deleteRequest(uri, token);
  };
};

export const getDealerInfo = token => {
  let uri = `/dealer/info`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getDealerFinancials = token => {
  let uri = `/financial/summary`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getSalesBudget = token => {
  let uri = `/financial/budget`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const updateAddress = (token, dealer) => {
  const uri = "/user/address";
  return async () => {
    return HttpService.postRequest(uri, token, dealer);
  };
};

export const getOpenServiceRequestListing = (token, year) => {
  let uri = `/service/open`;
  uri += year != null ? `?year=${year}` : "";
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getClosedServiceRequestListing = (token, year) => {
  let uri = `/service/closed`;
  uri += year != null ? `?year=${year}` : "";
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getProcessedServiceRequestListing = (token, year) => {
  let uri = `/service/processed`;
  uri += year != null ? `?year=${year}` : "";
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getServiceRequestItem = (token, id) => {
  let uri = `/service?id=${id}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getIncidentItems = (token, id) => {
  let uri = `/service/incidents?id=${id}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getShippingMethods = token => {
  let uri = `/service/shipmethods`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getPriorityLevels = token => {
  let uri = `/service/prioritylevels`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getPriorityTypes = token => {
  let uri = `/service/prioritytypes`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getAdditionalCrewReasons = token => {
  let uri = `/service/additionalcrewreasons`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getServiceAdvisors = token => {
  let uri = `/service/advisors`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getOrderNumberListing = (token, orderNumber, account) => {
  let uri = `/order/listing?1=1`;
  if (orderNumber) {
    uri += "&orderNumber=" + orderNumber;
  }
  if (account) {
    uri += "&account=" + account;
  }

  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getOrderNumberLookup = (token, account) => {
  const uri = `/order/lookup?account=${account}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getDDSOrderNumberListing = (token, orderNumber, account) => {
  let uri = `/order/ddslisting?1=1`;
  if (orderNumber) {
    uri += "&orderNumber=" + orderNumber;
  }
  if (account) {
    uri += "&account=" + account;
  }

  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getDDSQuoteNumberListing = (token, quoteNumber, account) => {
  let uri = `/order/ddsquotelisting?1=1`;
  if (quoteNumber) {
    uri += "&quoteNumber=" + quoteNumber;
  }
  if (account) {
    uri += "&account=" + account;
  }
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const insertServiceRequest = (token, body) => {
  const uri = `/service/request`;
  return async () => {
    return HttpService.postRequest(uri, token, body);
  };
};

export const updateServiceRequest = (token, body) => {
  const uri = `/service/request`;
  return async () => {
    return HttpService.putRequest(uri, token, body);
  };
};

export const getProductWizard = (token, body) => {
  const uri = `/service/productwizard`;
  return async () => {
    return HttpService.postRequest(uri, token, body);
  };
};

export const getIncidentWizard = (token, body) => {
  const uri = `/service/incidentwizard`;
  return async () => {
    return HttpService.postRequest(uri, token, body);
  };
};

export const getProjectListing = token => {
  let uri = `/dds/project/listing`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getProjectDetails = (token, id) => {
  let uri = `/dds/project?id=${id}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getDapImages = (token, id) => {
  let uri = `/dds/project/dapimages/${id}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getProjectTypes = token => {
  let uri = `/dds/project/projecttypes`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const insertProject = (token, body) => {
  const uri = `/dds/project`;
  return async () => {
    return HttpService.postRequest(uri, token, body);
  };
};

export const archiveProject = (token, id) => {
  const uri = `/dds/project/archive/${id}`;
  return async () => {
    return HttpService.putRequest(uri, token);
  };
};

export const getProjectRequestTypes = token => {
  let uri = `/dds/project/request/types`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const insertProjectRequest = (token, body) => {
  const uri = `/dds/project/request`;
  return async () => {
    return HttpService.postRequest(uri, token, body);
  };
};

export const uploadProjectRequestAttachment = (token, id, body, isQuote) => {
  const uri = `/dds/project/upload/${id}?isQuote=${isQuote}`;
  return async () => {
    return HttpService.postRequest(uri, token, body, {
      "Content-Type": "multipart/form-data"
    });
  };
};

export const downloadProjectRequestAttachment = (token, id, filename) => {
  const uri = `/dds/project/download/${id}/${filename}`;
  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};

export const downloadProjectRequestDrawingAttachment = (
  token,
  id,
  filename
) => {
  const uri = `/dds/project/downloadimage/${id}/${filename}`;
  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};
export const downloadServiceKnowledgeAttachment = (token, id, filename) => {
  const uri = `/knowledge/download/${id}/${filename}`;
  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};

export const downloadServiceKnowledgeStepAttachment = (token, id, filename) => {
  const uri = `/knowledge/step/download/${id}/${filename}`;
  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};

export const downloadNewsAttachment = (token, id, filename) => {
  const uri = `/news/download/${id}/${filename}`;
  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};

export const downloadServiceRequestImage = (token, id, filename) => {
  const uri = `/service/download/${id}/${encodeURIComponent(filename)}`;
  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};

export const uploadServiceRequestAttachment = (token, id, body) => {
  const uri = `/service/upload/${id}`;
  return async () => {
    return HttpService.postRequest(uri, token, body, {
      "Content-Type": "multipart/form-data"
    });
  };
};

export const deleteServiceRequestAttachment = (token, id, requestId) => {
  const uri = `/service/deleteimage/${id}?requestId=${requestId}`;
  return async () => {
    return HttpService.deleteRequest(uri, token);
  };
};

export const getStaticPages = token => {
  let uri = `/pages/listing`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getStaticPage = (token, id) => {
  let uri = `/pages/item/${id}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getSupplementalPricingListing = token => {
  let uri = `/pricing/listing?active=true`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getSupplementalPricingItem = (token, id) => {
  let uri = `/pricing/item/${id}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const downloadSupplementalPricingImage = (token, id, filename) => {
  const uri = `/pricing/download/${id}/${filename}`;
  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};

export const getServiceKnowledgeListing = token => {
  let uri = `/knowledge/listing`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getServiceKnowledgeItem = (token, id) => {
  let uri = `/knowledge/item/${id}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const uploadEmailAttachment = (token, body) => {
  const uri = `/email/send`;
  return async () => {
    return HttpService.postRequest(uri, token, body);
  };
};

export const uploadInvoiceAttachment = (token, invoiceId) => {
  const uri = `/email/invoice?invoiceId=${invoiceId}`;
  return async () => {
    return HttpService.postRequest(uri, token);
  };
};

export const uploadOrderAttachment = (token, body, orderNumber) => {
  const uri = `/email/send?orderNumber=${orderNumber}`;
  return async () => {
    return HttpService.postRequest(uri, token, body);
  };
};

export const getPhoneTypes = token => {
  const uri = `/account/phonetypes`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const addressSearch = (token, address, country) => {
  const uri = `/address/search?address=${address}&country=${country}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const addressRetrieve = (token, id) => {
  const uri = `/address/retrieve?id=${id}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const addressFind = (token, country, lastId) => {
  const uri = `/address/find?country=${country}&lastId=${lastId}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getDealerUserListing = token => {
  const uri = `/user/users`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getUserDetails = (token, id) => {
  const uri = `/user/user?id=${id}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getDealerSections = token => {
  let uri = "/user/sections";
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getDealerRoles = token => {
  let uri = "/user/roles";
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getDealerAssignedRoles = token => {
  let uri = "/user/dealerroles";
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const updateDealerUser = (token, user) => {
  const uri = "/user/user";
  return async () => {
    return HttpService.putRequest(uri, token, user);
  };
};

export const insertDealerUser = (token, user) => {
  const uri = "/user/user";
  return async () => {
    return HttpService.postRequest(uri, token, user);
  };
};

export const getFinancialListing = (token, account) => {
  let uri = `/financial/list?account=${account}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getRemitHistory = (token, account) => {
  let uri = `/financial/history?account=${account}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const insertRemittance = (token, body) => {
  const uri = `/financial/remit`;
  return async () => {
    return HttpService.postRequest(uri, token, body);
  };
};

export const getRemitDetails = (token, id) => {
  let uri = `/financial/details?id=${id}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getOpenServiceOrders = (token, year) => {
  let uri = `/serviceorder/open`;
  if (year != null) uri += `?year=${year}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getClosedServiceOrders = (token, year) => {
  let uri = `/serviceorder/closed`;
  if (year != null) uri += `?year=${year}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getServiceOrderItem = (token, id) => {
  let uri = `/serviceorder?id=${id}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const insertChangeRequest = (token, body) => {
  const uri = `/order/changerequest`;
  return async () => {
    return HttpService.postRequest(uri, token, body);
  };
};

export const getLoadDates = (token, account) => {
  let uri = `/loaddates?account=${account}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getDAPListing = (token, id) => {
  let uri = `/dds/project/drawings/${id}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getDAPProjectListing = (token, id) => {
  let uri = `/dds/project/projectdrawings/${id}`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const downloadProjectRequestPdf = (token, id, filename) => {
  const uri = `/dds/project/downloadpdf/${id}/${filename}`;
  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};

export const downloadProjectRequestPricing = (token, id, filename) => {
  const uri = `/dds/project/downloadpricing/${id}/${filename}`;
  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};

export const downloadProjectRequestPricingCombined = (token, id) => {
  const uri = `/dds/project/downloadpricingcombined/${id}`;
  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};

export const downloadProjectRequestDrawing = (token, id, filename) => {
  const uri = `/dds/project/downloaddrawing/${id}/${filename}`;
  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};

export const downloadDapPrint = (token, requestId, drawingIds) => {
  let uri = `/dds/project/downloaddrawings/${requestId}/print?`;
  for (let i = 0; i < drawingIds.length; i++) {
    if (i !== 0) {
      uri += "&";
    }
    uri += `dapIds=${drawingIds[i]}`;
  }

  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};

export const getAccountContacts = token => {
  let uri = `/dealer/contacts`;
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getUserDiscounts = token => {
  const uri = "/discounts/user";
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const getImpersonatorDiscounts = token => {
  const uri = "/discounts/impersonator";
  return async () => {
    return HttpService.getRequest(uri, token);
  };
};

export const viewUserDiscount = (token, discountId) => {
  const uri = `/discounts/${discountId}/view`;
  return async () => {
    return HttpService.postRequest(uri, token, {});
  };
};

export const downloadDapWarrantyWaiver = (token, waiverId, filename) => {
  let uri = `/dds/project/downloadwaiver/${waiverId}/${filename}`;
  return async () => {
    return HttpService.getRequestWithHeader(token, uri, {
      responseType: "blob"
    });
  };
};
