import { useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators as actionCreatorsUser } from "../../actions/adminActions";
import { actionCreators as actionCreatorsShared } from "../../actions/sharedActions";
import { adminAuthorize } from "../../services/authService";
import { useHistory } from "react-router-dom";
import fetchToken from "../../services/token";

function SignInListener(props) {
  const { instance } = useMsal();
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();

  const handleUserPostAuthentication = async token => {
    let adminUser = await adminAuthorize(token, instance, history);
    if (adminUser?.isSuccess) {
      props.updateLoggedInUser(adminUser.user);
      props.updateAdminToken(token);
      props.getAdminRoles(token);
      props.getStaticPages(token);
      props.getUserPreference(token);
    } else {
      instance
        .logoutRedirect({
          postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URL
        })
        .catch(e => {
          console.error(e);
        });
    }
  };

  const handleSignIn = message => {
    const token = message.payload?.accessToken;
    if (!message.error && token) {
      handleUserPostAuthentication(token);
    }
  };

  const userCheck = async () => {
    if (isAuthenticated) {
      if (!props.userPreference || !props.adminRoles || !props.user) {
        //We're authenticated but something happened when fetching user info.
        const token = await fetchToken();
        if (token) {
          handleUserPostAuthentication(token);
        }
      }
    }
  };

  useEffect(() => {
    const callbackId = instance.addEventCallback(message => {
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        handleSignIn(message);
      } else if (message.eventType === EventType.INITIALIZE_END) {
        userCheck();
      }

      return () => {
        if (callbackId) {
          instance.removeEventCallback(callbackId);
        }
      };
    });
  }, []);

  return props.children;
}

const mapStateToProps = state => {
  return {
    userPreference: state.adminReducer.userPreference,
    adminRoles: state.adminReducer.roles,
    user: state.adminReducer.user
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...actionCreatorsUser, ...actionCreatorsShared },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SignInListener);
