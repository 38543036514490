import { ActionKeys } from "../actions/constants/layoutConstants";

const initialState = { showMenu: false };

export default function layoutReducer(state, action) {
  state = state || initialState;

  switch (action.type) {
    case ActionKeys.SHOW_MENU:
      return {
        ...state,
        showMenu: true
      };
    case ActionKeys.HIDE_MENU:
      return {
        ...state,
        showMenu: false
      };
    default:
      return state;
  }
}
