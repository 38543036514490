import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { history } from "./stores/configureStore";
import configureStore from "./stores/configureStore";
import { ConnectedRouter } from "connected-react-router";
import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from "@azure/msal-react";
import { PersistGate } from "redux-persist/integration/react";
import WithAxios from "./components/Auth/withAxios";
import SignInListener from "./components/Auth/SignInListener";
import OutageSplash from "./outagesplash/OutageSplash";
const App = React.lazy(() => import("./App"));
const StaffApp = React.lazy(() => import("./StaffApp"));

export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.handleRedirectPromise().then(handleResponse);

function handleResponse(response) {
  if (response !== null) {
    msalInstance.setActiveAccount(response.account);
  }
}

const store = configureStore();

ReactDOM.render(
  // <React.StrictMode> Unsure why Kevin comment this out, this is bad practice.
  <OutageSplash>
    <BrowserRouter>
      <Provider store={store.store}>
        <PersistGate loading={null} persistor={store.persistor}>
          <MsalProvider instance={msalInstance}>
            <SignInListener>
              <WithAxios store={store} history={history}>
                <ConnectedRouter history={history}>
                  <AuthenticatedTemplate>
                    <Suspense fallback={<></>}>
                      <StaffApp />
                    </Suspense>
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate>
                    <Suspense fallback={<></>}>
                      <App />
                    </Suspense>
                  </UnauthenticatedTemplate>
                </ConnectedRouter>
              </WithAxios>
            </SignInListener>
          </MsalProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </OutageSplash>,
  // </React.StrictMode>
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
