import { useEffect } from "react";
import axios from "axios";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const WithAxios = ({ children, ...props }) => {
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    axios.interceptors.response.use(
      response => response,
      async error => {
        if (error.response?.status === 401) {
          if (isAuthenticated) {
            props.history.push("/dashboard");
          } else {
            window.location = "/logout";
          }
        } else if (error.response?.status === 404) {
          props.history.push("/404");
        }
        throw error;
      }
    );
  }, [useMsal]);

  return children;
};

export default WithAxios;
