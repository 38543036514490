import { API } from "../services/api";
import { ActionKeys } from "./constants/sharedConstants";

export const actionCreators = {
  getStaticPages: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_PAGE_LISTING });
    return dispatch(API.user.getStaticPages(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_PAGE_LISTING,
        isSuccess: response.isSuccess,
        isLoading: false,
        items: response.items,
        message: response.message
      });
    });
  },
  getStaticPage: (token, id) => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_PAGE_ITEM });
    return dispatch(API.user.getStaticPage(token, id)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_PAGE_ITEM,
        isSuccess: response.isSuccess,
        isLoading: false,
        item: response.item,
        message: response.message
      });
    });
  },
  getDealerListing: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_DEALER_LISTING });
    return dispatch(API.admin.getDealerListing(token)).then(response => {
      dispatch({
        type: ActionKeys.RECEIVE_DEALER_LISTING,
        isSuccess: response.isSuccess,
        isLoading: false,
        items: response.items,
        message: response.message
      });
    });
  },
  getFilteredDealerListing: token => async (dispatch, getState) => {
    dispatch({ type: ActionKeys.REQUEST_FILTERED_DEALER_LISTING });
    return dispatch(API.admin.getFilteredDealerListing(token)).then(
      response => {
        dispatch({
          type: ActionKeys.RECEIVE_FILTERED_DEALER_LISTING,
          isSuccess: response.isSuccess,
          isFilteredDealerListingLoading: false,
          items: response.items,
          message: response.message
        });
      }
    );
  }
};
