import { createStore, applyMiddleware, compose } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import createRootReducer from "../reducers/rootReducer";
import { persistStore, persistReducer } from "redux-persist";
import localforage from "localforage";
export const history = createBrowserHistory();

const persistConfig = {
  key: "primary",
  storage: localforage
};

export default function configureStore() {
  const enhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const persistedReducer = persistReducer(
    persistConfig,
    createRootReducer(history)
  );

  const store = createStore(
    persistedReducer,
    enhancers(applyMiddleware(thunk, routerMiddleware(history)))
  );

  const persistor = persistStore(store);

  return { store, persistor };
}
