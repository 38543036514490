import { msalInstance } from "../index";
import { loginRequest } from "../authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

async function fetchToken() {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    return null;
  }

  const response = await msalInstance
    .acquireTokenSilent({
      ...loginRequest,
      account
    })
    .catch(async e => {
      //AD is angry we aren't connecting from the same location, reauthenticate.
      if (e instanceof InteractionRequiredAuthError) {
        msalInstance.acquireTokenRedirect(loginRequest).catch(e => {
          console.log(e);
        });
      } else {
        console.log(e);
      }
      throw e;
    });
  return response.accessToken;
}

export default fetchToken;
