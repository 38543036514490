/* eslint-disable no-undef */
import axios from "axios";

export default class HttpService {
  static getRequest(url, token, optionalOptions = {}) {
    const BASEURL = process.env.REACT_APP_API_URL;
    const options = {
      ...optionalOptions,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        CacheControl: "no-cache",
        Pragma: "no-cache",
        Expires: "0"
      }
    };
    return axios
      .get(BASEURL + url, options)
      .then(res => {
        return res.data;
      })
      .catch(error => {
        return { error };
      });
  }

  static postRequest(
    url,
    token,
    body,
    header = { Accept: "application/json" }
  ) {
    const BASEURL = process.env.REACT_APP_API_URL;
    return axios
      .post(BASEURL + url, body, {
        headers: { ...header, Authorization: `Bearer ${token}` }
      })
      .then(res => {
        return res.data;
      })
      .catch(error => {
        return { isSuccess: false, message: error.message };
      });
  }

  static getRequestWithHeader(token, url, optionalOptions = {}) {
    const BASEURL = process.env.REACT_APP_API_URL;
    const options = {
      ...optionalOptions,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        CacheControl: "no-cache",
        Pragma: "no-cache",
        Expires: "0"
      }
    };
    return axios.get(BASEURL + url, options).catch(error => {
      return { error };
    });
  }

  static putRequest(url, token, body, optionalOptions = {}) {
    const BASEURL = process.env.REACT_APP_API_URL;
    const options = {
      ...optionalOptions,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    };
    return axios
      .put(BASEURL + url, body, options)
      .then(res => {
        return res.data;
      })
      .catch(error => {
        return { error };
      });
  }

  static deleteRequest(url, token, data = {}) {
    const BASEURL = process.env.REACT_APP_API_URL;
    return axios
      .delete(BASEURL + url, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json"
        },
        data
      })
      .then(res => {
        return res.data;
      })
      .catch(error => {
        return { error };
      });
  }
}
