const paramNames = [
  "skip",
  "take",
  "requireTotalCount",
  "requireGroupCount",
  "sort",
  "filter",
  "totalSummary",
  "group",
  "groupSummary"
];

export default function buildQueryParams(options, params = paramNames) {
  return params
    .filter(paramName => isNotEmpty(options[paramName]))
    .map(
      paramName =>
        `${paramName}=${encodeURIComponent(JSON.stringify(options[paramName]))}`
    )
    .join("&");
}

function isNotEmpty(value) {
  return value !== undefined && value !== null && value !== "";
}
