import { ActionKeys } from "../actions/constants/sharedConstants";

const initialState = {
  isLoading: false
};

export default function adminReducer(state, action) {
  state = state || initialState;

  switch (action.type) {
    case ActionKeys.REQUEST_PAGE_LISTING:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: ""
      };
    case ActionKeys.RECEIVE_PAGE_LISTING:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        pageItems: action.items
      };
    case ActionKeys.REQUEST_PAGE_ITEM:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: "",
        pageItem: {}
      };
    case ActionKeys.RECEIVE_PAGE_ITEM:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        pageItem: action.item
      };
    case ActionKeys.REQUEST_DEALER_LISTING:
      return {
        ...state,
        isSuccess: false,
        isFilteredDealerListingLoading: true,
        message: "",
        dealers: []
      };
    case ActionKeys.RECEIVE_DEALER_LISTING:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isFilteredDealerListingLoading: false,
        isLoading: false,
        message: action.message,
        dealers: action.items
      };
    case ActionKeys.REQUEST_FILTERED_DEALER_LISTING:
      return {
        ...state,
        isSuccess: false,
        isFilteredDealerListingLoading: true,
        message: "",
        filteredDealers: []
      };
    case ActionKeys.RECEIVE_FILTERED_DEALER_LISTING:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isFilteredDealerListingLoading: false,
        isLoading: false,
        message: action.message,
        filteredDealers: action.items
      };
    default:
      return state;
  }
}
