class Http {
  // GET =========================================================
  static async getRequest(
    url,
    token,
    abortController = null,
    optionalOptions = {},
    optionalHeaders = { Accept: "application/json" }
  ) {
    const requestOptions = {
      ...optionalOptions,
      method: "GET",
      signal: abortController?.signal,
      headers: new Headers({
        ...optionalHeaders,
        Authorization: `Bearer ${token}`,
        CacheControl: "no-cache",
        Pragma: "no-cache",
        Expires: "0"
      })
    };
    return await this.makeRequest(url, requestOptions);
  }

  static async getFile(
    url,
    token,
    abortController = null,
    optionalOptions = {},
    optionalHeaders = { Accept: "application/json" }
  ) {
    const requestOptions = {
      ...optionalOptions,
      method: "GET",
      signal: abortController?.signal,
      headers: new Headers({
        ...optionalHeaders,
        Authorization: `Bearer ${token}`,
        CacheControl: "no-cache",
        Pragma: "no-cache",
        Expires: "0"
      })
    };
    return await this.makeRequestRaw(url, requestOptions).then(
      async response => {
        const data = await response.blob();
        return data;
      }
    );
  }

  // POST ========================================================
  static async postRequest(
    url,
    token,
    body,
    abortController = null,
    header = { Accept: "application/json" }
  ) {
    const requestOptions = {
      method: "POST",
      signal: abortController?.signal,
      headers: new Headers({
        ...header,
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body)
    };
    return await this.makeRequest(url, requestOptions);
  }

  static async postRequestRaw(
    url,
    token,
    body,
    abortController = null,
    header = {
      Accept: "application/json"
    }
  ) {
    const requestOptions = {
      method: "POST",
      signal: abortController?.signal,
      headers: new Headers({
        ...header,
        Authorization: `Bearer ${token}`
      }),
      body
    };
    return await this.makeRequest(url, requestOptions);
  }

  // PUT =========================================================
  static async putRequest(
    url,
    token,
    body,
    abortController = null,
    optionalOptions = {}
  ) {
    const requestOptions = {
      ...optionalOptions,
      method: "PUT",
      signal: abortController?.signal,
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json"
      }),
      body: JSON.stringify(body)
    };
    return await this.makeRequest(url, requestOptions);
  }

  static async putRequestRaw(
    url,
    token,
    body,
    abortController = null,
    header = {
      Accept: "application/json"
    }
  ) {
    const requestOptions = {
      method: "PUT",
      signal: abortController?.signal,
      headers: new Headers({
        ...header,
        Authorization: `Bearer ${token}`
      }),
      body
    };
    return await this.makeRequest(url, requestOptions);
  }

  // PATCH =======================================================
  static async patchRequest(
    url,
    token,
    body,
    abortController = null,
    optionalOptions = {}
  ) {
    const requestOptions = {
      ...optionalOptions,
      method: "PATCH",
      signal: abortController?.signal,
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json"
      }),
      body: JSON.stringify(body)
    };
    return await this.makeRequest(url, requestOptions);
  }

  // DELETE ======================================================
  static async deleteRequest(url, token, abortController = null) {
    const requestOptions = {
      method: "DELETE",
      signal: abortController?.signal,
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json"
      })
    };
    return await this.makeRequest(url, requestOptions);
  }

  // MAKE REQUEST ================================================
  static async makeRequest(url, options) {
    const BASEURL = process.env.REACT_APP_API_URL;
    return fetch(`${BASEURL}/${url}`, options).then(async response => {
      let data = null;
      data = await response.json().catch(() => {
        return {};
      });

      if (!response.ok) {
        if (response.status === 401) {
          window.location = "/logout";
        }

        let error = response.statusText;
        if (data?.message) {
          error = data.message;
        }
        return Promise.reject(new Error(error));
      }
      return data;
    });
  }

  static async makeRequestRaw(url, options) {
    const BASEURL = process.env.REACT_APP_API_URL;
    return fetch(`${BASEURL}/${url}`, options).then(async response => {
      if (!response.ok) {
        let data = null;
        data = await response.json().catch(() => {
          return {};
        });

        if (response.status === 401) {
          window.location = "/logout";
        }

        let error = response.statusText;
        if (data?.message) {
          error = data.message;
        }

        return Promise.reject(new Error(error));
      }
      return response;
    });
  }
}

export default Http;
