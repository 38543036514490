import { ActionKeys } from "../actions/constants/userConstants";

const initialState = {
  isLoading: false,
  newsItems: [],
  orderLineItems: [],
  invoiceLineItems: [],
  dealerInfo: [],
  shippingMethods: [],
  priorityLevels: [],
  priorityTypes: [],
  additionalCrewReasons: [],
  serviceAdvisors: [],
  orderNumberListing: [],
  openServiceRequestItems: [],
  closedServiceRequestItems: [],
  processedServiceRequestItems: [],
  incidentItems: [],
  serviceRequestItem: {},
  productWizard: {},
  incidentWizard: {},
  orderItem: {},
  ordercustomer: {},
  projects: [],
  notificationItems: [],
  projectRequestTypes: [],
  staticPages: [],
  newRequestId: 0,
  supplementalPricingItems: [],
  serviceKnowledgeItems: [],
  phoneTypes: [],
  dealerRoles: [],
  dealerAssignedRoles: [],
  dealerFinancials: [],
  financialItems: [],
  historyItems: [],
  salesBudget: [],
  openServiceOrders: [],
  closedServiceOrers: [],
  changeRequests: [],
  loadDates: [],
  dapItems: [],
  dapProjectItems: [],
  dapImages: [],
  accountContacts: [],
  isOrderLineItemsLoading: false,
  projectLineItems: [],
  projectLineItemsExcludingDap: [],
  currentRevisions: []
};

export default function userReducer(state, action) {
  state = state || initialState;

  switch (action.type) {
    case ActionKeys.REQUEST_TEST_LISTING:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: null
      };
    case ActionKeys.RECEIVE_TEST_LISTING:
      return {
        ...state,
        isSuccess: action.isSuccess,
        testItems: action.items,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.UPDATED_USER_TOKEN_DEALER:
      return {
        ...state,
        token: action.token
      };
    case ActionKeys.UPDATED_IS_IMPERSONATING:
      return {
        ...state,
        isImpersonating: action.isImpersonating,
        previousUrl: action.previousUrl || state.previousUrl
      };
    case ActionKeys.RESET_STATE:
      return {
        ...state,
        isSuccess: null,
        message: null,
        newRequestId: null
      };
    case ActionKeys.UPDATED_USER_INFO:
      return {
        ...state,
        user: action.user
      };
    case ActionKeys.REQUEST_NEWS_LISTING:
      return {
        ...state,
        isLoading: true,
        newsItems: []
      };
    case ActionKeys.RECEIVE_NEWS_LISTING:
      return {
        ...state,
        isLoading: false,
        isSuccess: action.isSuccess,
        newsItems: action.items,
        message: action.message,
        newsCount: action.count
      };
    case ActionKeys.REQUEST_NEWS_ITEM:
      return {
        ...state,
        isLoading: true,
        newsItem: {}
      };
    case ActionKeys.RECEIVE_NEWS_ITEM:
      return {
        ...state,
        isLoading: false,
        isSuccess: action.isSuccess,
        newsItem: action.item,
        message: action.message
      };
    case ActionKeys.REQUEST_CURRENT_ORDER_LISTING:
      return {
        ...state,
        isCurrentOrdersLoading: true,
        currentOrderItems: [],
        currentOrderCount: 0
      };
    case ActionKeys.RECEIVE_CURRENT_ORDER_LISTING:
      return {
        ...state,
        isCurrentOrdersLoading: false,
        isSuccess: action.isSuccess,
        currentOrderItems: action.items,
        message: action.message,
        currentOrderCount: action.count
      };
    case ActionKeys.REQUEST_ORDER_ITEM:
      return {
        ...state,
        isLoading: true,
        orderItem: {}
      };
    case ActionKeys.RECEIVE_ORDER_ITEM:
      return {
        ...state,
        isLoading: false,
        isSuccess: action.isSuccess,
        message: action.message,
        orderItem: action.item
      };
    case ActionKeys.REQUEST_CHANGE_REQUESTS:
      return {
        ...state,
        isRequestsLoading: true,
        changeRequests: []
      };
    case ActionKeys.RECEIVE_CHANGE_REQUESTS:
      return {
        ...state,
        isRequestsLoading: false,
        isSuccess: action.isSuccess,
        message: action.message,
        changeRequests: action.items
      };
    case ActionKeys.ACTION_CHANGE_REQUEST:
      return {
        ...state,
        isRequestsLoading: true
      };
    case ActionKeys.ACTIONED_CHANGE_REQUEST:
      return {
        ...state,
        isRequestsLoading: false,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.REQUEST_ORDER_CUSTOMER:
      return {
        ...state,
        isLoading: true,
        orderCustomer: {}
      };
    case ActionKeys.RECEIVE_ORDER_CUSTOMER:
      return {
        ...state,
        isLoading: false,
        isSuccess: action.isSuccess,
        message: action.message,
        orderCustomer: action.item
      };
    case ActionKeys.REQUEST_PAST_ORDER_LISTING:
      return {
        ...state,
        isPastOrdersLoading: true,
        pastOrderItems: [],
        pastOrderCount: 0
      };
    case ActionKeys.RECEIVE_PAST_ORDER_LISTING:
      return {
        ...state,
        isPastOrdersLoading: false,
        isSuccess: action.isSuccess,
        pastOrderItems: action.items,
        message: action.message,
        pastOrderCount: action.count
      };
    case ActionKeys.REQUEST_INVOICE_ITEM:
      return {
        ...state,
        isLoading: true,
        invoiceItem: {}
      };
    case ActionKeys.RECEIVE_INVOICE_ITEM:
      return {
        ...state,
        isLoading: false,
        isSuccess: action.isSuccess,
        message: action.message,
        invoiceItem: action.item
      };
    case ActionKeys.REQUEST_ACTIVE_NOTIFICATIONS:
      return {
        ...state,
        isNotificationLoaded: false,
        notificationItems: []
      };
    case ActionKeys.RECEIVE_ACTIVE_NOTIFICATIONS:
      return {
        ...state,
        isNotificationLoaded: true,
        isSuccess: action.isSuccess,
        message: action.message,
        notificationItems: action.items
      };
    case ActionKeys.REQUEST_INVOICE_LISTING:
      return {
        ...state,
        isInvoiceListLoading: true,
        invoiceItems: [],
        invoiceCount: 0
      };
    case ActionKeys.RECEIVE_INVOICE_LISTING:
      return {
        ...state,
        isInvoiceListLoading: false,
        isSuccess: action.isSuccess,
        invoiceItems: action.items,
        message: action.message,
        invoiceCount: action.count
      };
    case ActionKeys.REQUEST_INVOICE_LINE_ITEMS:
      return {
        ...state,
        invoiceLineItems: [],
        invoiceLineItemCount: 0
      };
    case ActionKeys.RECEIVE_INVOICE_LINE_ITEMS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        invoiceLineItems: action.items,
        message: action.message,
        invoiceLineItemCount: action.count
      };
    case ActionKeys.REQUEST_ORDER_LINE_ITEMS:
      return {
        ...state,
        orderLineItems: [],
        orderLineItemCount: 0
      };
    case ActionKeys.RECEIVE_ORDER_LINE_ITEMS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        orderLineItems: action.items,
        orderLineItemCount: action.count
      };
    case ActionKeys.REQUEST_DDS_ORDER_LINE_ITEMS:
      return {
        ...state,
        orderLineItems: null,
        orderLineItemCount: 0,
        isOrderLineItemsLoading: true
      };
    case ActionKeys.RECEIVE_DDS_ORDER_LINE_ITEMS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        orderLineItems: action.items,
        orderLineItemCount: action.count,
        isOrderLineItemsLoading: false
      };
    case ActionKeys.REQUEST_ORDER_LINE_ITEM_DETAIL:
      return {
        ...state,
        isLoading: true,
        orderLineItemDetailsCount: 0
      };
    case ActionKeys.RECEIVE_ORDER_LINE_ITEM_DETAILS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        isLoading: false,
        orderLineItemDetails: action.items,
        orderLineItemDetailsCount: action.count
      };
    case ActionKeys.UPDATE_USER_EMAIL:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: "",
        authCode: ""
      };
    case ActionKeys.UPDATED_USER_EMAIL:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.UPDATE_USER_MFATYPE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: "",
        authCode: ""
      };
    case ActionKeys.UPDATED_USER_MFATYPE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.UPDATE_USER_PHONE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.UPDATED_USER_PHONE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        authCode: action.authCode
      };
    case ActionKeys.INSERT_USER_PHONE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.INSERTED_USER_PHONE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        authCode: action.authCode
      };
    case ActionKeys.DELETE_USER_PHONE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.DELETED_USER_PHONE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.UPDATE_USER_PASSWORD:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: "",
        authCode: ""
      };
    case ActionKeys.UPDATED_USER_PASSWORD:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REQUEST_DEALER_INFO:
      return {
        ...state,
        dealerInfo: [],
        isLoading: true
      };
    case ActionKeys.RECEIVE_DEALER_INFO:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        dealerInfo: action.items,
        isLoading: false
      };
    case ActionKeys.REQUEST_DEALER_FINANCIALS:
      return {
        ...state,
        dealerFinancials: [],
        isSummaryLoading: true
      };
    case ActionKeys.RECEIVE_DEALER_FINANCIALS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        dealerFinancials: action.items,
        isSummaryLoading: false
      };
    case ActionKeys.REQUEST_SALES_BUDGET:
      return {
        ...state,
        salesBudget: [],
        isLoading: true
      };
    case ActionKeys.RECEIVE_SALES_BUDGET:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        salesBudget: action.items,
        isLoading: false
      };
    case ActionKeys.UPDATE_ADDRESS:
      return {
        ...state,
        isLoading: true
      };
    case ActionKeys.UPDATED_ADDRESS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        isLoading: false
      };
    case ActionKeys.REQUEST_OPEN_SERVICE_REQUEST_LISTING:
      return {
        ...state,
        isOpenServiceRequestListingLoading: true,
        openServiceRequestItems: [],
        openServiceRequestCount: 0
      };
    case ActionKeys.RECEIVE_OPEN_SERVICE_REQUEST_LISTING:
      return {
        ...state,
        isOpenServiceRequestListingLoading: false,
        isSuccess: action.isSuccess,
        openServiceRequestItems: action.items,
        message: action.message,
        openServiceRequestCount: action.count
      };
    case ActionKeys.REQUEST_CLOSED_SERVICE_REQUEST_LISTING:
      return {
        ...state,
        isClosedServiceRequestListingLoading: true,
        closedServiceRequestItems: [],
        closedServiceRequestCount: 0
      };
    case ActionKeys.RECEIVE_CLOSED_SERVICE_REQUEST_LISTING:
      return {
        ...state,
        isClosedServiceRequestListingLoading: false,
        isSuccess: action.isSuccess,
        closedServiceRequestItems: action.items,
        message: action.message,
        closedServiceRequestCount: action.count
      };
    case ActionKeys.REQUEST_PROCESSED_SERVICE_REQUEST_LISTING:
      return {
        ...state,
        isProcessedServiceRequestListingLoading: true,
        processedServiceRequestItems: [],
        processedServiceRequestCount: 0
      };
    case ActionKeys.RECEIVE_PROCESSED_SERVICE_REQUEST_LISTING:
      return {
        ...state,
        isProcessedServiceRequestListingLoading: false,
        isSuccess: action.isSuccess,
        processedServiceRequestItems: action.items,
        message: action.message,
        processedServiceRequestCount: action.count
      };
    case ActionKeys.REQUEST_SERVICE_REQUEST_ITEM:
      return {
        ...state,
        isLoading: true,
        serviceRequestItem: {}
      };
    case ActionKeys.RECEIVE_SERVICE_REQUEST_ITEM:
      return {
        ...state,
        isLoading: false,
        isSuccess: action.isSuccess,
        message: action.message,
        serviceRequestItem: action.item
      };
    case ActionKeys.REQUEST_INCIDENT_ITEMS:
      return {
        ...state,
        incidentItems: [],
        incidentCount: 0
      };
    case ActionKeys.RECEIVE_INCIDENT_ITEMS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        incidentItems: action.items,
        incidentCount: action.count
      };
    case ActionKeys.REQUEST_SHIPPING_METHODS:
      return {
        ...state,
        shippingMethods: []
      };
    case ActionKeys.RECEIVE_SHIPPING_METHODS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        shippingMethods: action.items
      };
    case ActionKeys.REQUEST_PRIORITY_LEVELS:
      return {
        ...state,
        priorityLevels: []
      };
    case ActionKeys.RECEIVE_PRIORITY_LEVELS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        priorityLevels: action.items
      };
    case ActionKeys.REQUEST_ADDITIONAL_CREW_REASONS:
      return {
        ...state,
        additionalCrewReasons: []
      };
    case ActionKeys.RECEIVE_ADDITIONAL_CREW_REASONS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        additionalCrewReasons: action.items
      };
    case ActionKeys.REQUEST_PRIORITY_TYPES:
      return {
        ...state,
        priorityTypes: []
      };
    case ActionKeys.RECEIVE_PRIORITY_TYPES:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        priorityTypes: action.items
      };
    case ActionKeys.REQUEST_SERVICE_ADVISORS:
      return {
        ...state,
        serviceAdvisors: []
      };
    case ActionKeys.RECEIVE_SERVICE_ADVISORS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        serviceAdvisors: action.items
      };
    case ActionKeys.REQUEST_ORDER_NUMBER_LISTING:
      return {
        ...state,
        isOrderNumbersLoading: true,
        orderNumberListing: []
      };
    case ActionKeys.RECEIVE_ORDER_NUMBER_LISTING:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        isOrderNumbersLoading: false,
        orderNumberListing: action.items
      };
    case ActionKeys.CLEAR_ORDER_NUMBER_LISTING:
      return {
        ...state,
        orderNumberListing: []
      };
    case ActionKeys.REQUEST_QUOTE_NUMBER_LISTING:
      return {
        ...state,
        quoteNumberListing: []
      };
    case ActionKeys.RECEIVE_QUOTE_NUMBER_LISTING:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        quoteNumberListing: action.items
      };
    case ActionKeys.INSERT_SERVICE_REQUEST:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: action.message,
        newId: 0
      };
    case ActionKeys.INSERTED_SERVICE_REQUEST:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        newId: action.id
      };
    case ActionKeys.UPDATE_SERVICE_REQUEST:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: action.message,
        newId: 0
      };
    case ActionKeys.UPDATED_SERVICE_REQUEST:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        newId: action.id
      };
    case ActionKeys.REQUEST_PRODUCT_WIZARD:
      return {
        ...state,
        productWizard: {},
        isLoading: true
      };
    case ActionKeys.REQUEST_INCIDENT_WIZARD:
      return {
        ...state,
        incidentWizard: {},
        isLoading: true
      };
    case ActionKeys.RECEIVE_PRODUCT_WIZARD:
      return {
        ...state,
        productWizard: action.item,
        isLoading: false
      };
    case ActionKeys.RECEIVE_INCIDENT_WIZARD:
      return {
        ...state,
        incidentWizard: action.item,
        isLoading: false
      };
    case ActionKeys.REQUEST_PROJECT_LISTING:
      return {
        ...state,
        isProjectListingLoading: true,
        projects: []
      };
    case ActionKeys.RECEIVE_PROJECT_LISTING:
      return {
        ...state,
        isProjectListingLoading: false,
        isSuccess: action.isSuccess,
        projects: action.items,
        message: action.message
      };
    case ActionKeys.INSERT_PROJECT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: action.message,
        newRequestId: 0
      };
    case ActionKeys.INSERTED_PROJECT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        projectId: action.id
      };
    case ActionKeys.ARCHIVE_PROJECT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: action.message
      };
    case ActionKeys.ARCHIVED_PROJECT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        projectId: 0
      };
    case ActionKeys.CLEAR_PROJECT_DETAILS:
      return {
        ...state,
        isLoading: false,
        project: {}
      };
    case ActionKeys.REQUEST_PROJECT_DETAILS:
      return {
        ...state,
        isProjectDetailsLoading: true,
        project: {}
      };
    case ActionKeys.RECEIVE_PROJECT_DETAILS:
      return {
        ...state,
        isProjectDetailsLoading: false,
        isSuccess: action.isSuccess,
        message: action.message,
        project: action.item
      };
    case ActionKeys.REQUEST_DAP_IMAGES:
      return {
        ...state,
        isDapImagesLoading: true,
        dapImages: []
      };
    case ActionKeys.RECEIVE_DAP_IMAGES:
      return {
        ...state,
        isDapImagesLoading: false,
        isSuccess: action.isSuccess,
        message: action.message,
        dapImages: action.items
      };
    case ActionKeys.REQUEST_PROJECT_REQUEST_TYPES:
      return {
        ...state,
        projectRequestTypes: []
      };
    case ActionKeys.RECEIVE_PROJECT_REQUEST_TYPES:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        projectRequestTypes: action.items
      };
    case ActionKeys.INSERT_PROJECT_REQUEST:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: action.message,
        newRequestId: 0,
        projectId: 0
      };
    case ActionKeys.INSERTED_PROJECT_REQUEST:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        newRequestId: action.id,
        projectId: 0
      };
    case ActionKeys.REQUEST_STATIC_PAGES:
      return {
        ...state,
        staticPages: []
      };
    case ActionKeys.RECEIVE_STATIC_PAGES:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        staticPages: action.items
      };
    case ActionKeys.REQUEST_STATIC_PAGE:
      return {
        ...state,
        staticPage: null
      };
    case ActionKeys.RECEIVE_STATIC_PAGE:
      return {
        ...state,
        staticPage: action.item
      };
    case ActionKeys.UPLOAD_SERVICE_REQUEST_ATTACHMENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isUploading: true,
        message: ""
      };
    case ActionKeys.UPLOADED_SERVICE_REQUEST_ATTACHMENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isUploading: false,
        message: action.message
      };
    case ActionKeys.UPLOAD_PROJECT_REQUEST_ATTACHMENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isUploading: true,
        message: "",
        projectId: 0
      };
    case ActionKeys.UPLOADED_PROJECT_REQUEST_ATTACHMENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isUploading: false,
        message: action.message,
        projectId: 0
      };
    case ActionKeys.DELETE_SERVICE_REQUEST_ATTACHMENT:
      return {
        ...state,
        isSuccess: action.isSuccess
      };
    case ActionKeys.DELETED_SERVICE_REQUEST_ATTACHMENT:
      return {
        ...state,
        isSuccess: action.isSuccess
      };
    case ActionKeys.REQUEST_SUPPLEMENTAL_PRICING_LISTING:
      return {
        ...state,
        isLoading: true,
        supplementalPricingItems: []
      };
    case ActionKeys.RECEIVE_SUPPLEMENTAL_PRICING_LISTING:
      return {
        ...state,
        isLoading: false,
        isSuccess: action.isSuccess,
        supplementalPricingItems: action.items,
        message: action.message
      };
    case ActionKeys.REQUEST_SUPPLEMENTAL_PRICING_ITEM:
      return {
        ...state,
        isLoading: true,
        supplementalPricingItem: {}
      };
    case ActionKeys.RECEIVE_SUPPLEMENTAL_PRICING_ITEM:
      return {
        ...state,
        isLoading: false,
        isSuccess: action.isSuccess,
        message: action.message,
        supplementalPricingItem: action.item
      };
    case ActionKeys.REQUEST_SERVICE_KNOWLEDGE_LISTING:
      return {
        ...state,
        isLoading: true,
        serviceKnowledgeItems: []
      };
    case ActionKeys.REQUEST_SERVICE_KNOWLEDGE_ITEM:
      return {
        ...state,
        isLoading: false,
        isSuccess: action.isSuccess,
        message: action.message,
        serviceKnowledgeItem: action.item
      };
    case ActionKeys.RECEIVE_SERVICE_KNOWLEDGE_LISTING:
      return {
        ...state,
        isLoading: false,
        isSuccess: action.isSuccess,
        serviceKnowledgeItems: action.items,
        message: action.message
      };
    case ActionKeys.RECEIVE_SERVICE_KNOWLEDGE_ITEM:
      return {
        ...state,
        isLoading: false,
        isSuccess: action.isSuccess,
        serviceKnowledgeItem: action.item,
        message: action.message
      };
    case ActionKeys.UPLOAD_EMAIL_ATTACHMENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isUploading: true,
        message: ""
      };
    case ActionKeys.UPLOADED_EMAIL_ATTACHMENT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isUploading: false,
        message: action.message,
        noRefresh: true
      };
    case ActionKeys.REQUEST_PHONE_TYPES:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: ""
      };
    case ActionKeys.RECEIVE_PHONE_TYPES:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        phoneTypes: action.items
      };
    case ActionKeys.REQUEST_DEALER_USER_LISTING:
      return {
        ...state,
        isSuccess: false,
        isUsersLoading: true,
        message: ""
      };
    case ActionKeys.RECEIVE_DEALER_USER_LISTING:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isUsersLoading: false,
        message: action.message,
        dealerUsers: action.items
      };
    case ActionKeys.REQUEST_USER_DETAILS:
      return {
        ...state,
        isLoading: true,
        isSuccess: action.isSuccess,
        message: action.message,
        userDetails: null
      };
    case ActionKeys.RECEIVE_USER_DETAILS:
      return {
        ...state,
        isLoading: false,
        isSuccess: action.isSuccess,
        userDetails: action.item,
        message: action.message
      };
    case ActionKeys.REQUEST_DEALER_SECTIONS:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: ""
      };
    case ActionKeys.RECEIVE_DEALER_SECTIONS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        dealerSections: action.items
      };
    case ActionKeys.REQUEST_DEALER_ROLES:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: "",
        dealerRoles: []
      };
    case ActionKeys.RECEIVE_DEALER_ROLES:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        dealerRoles: action.items
      };
    case ActionKeys.REQUEST_DEALER_ASSIGNED_ROLES:
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
        message: "",
        dealerAssignedRoles: []
      };
    case ActionKeys.RECEIVE_DEALER_ASSIGNED_ROLES:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message,
        dealerAssignedRoles: action.items
      };
    case ActionKeys.UPDATE_DEALER_USER:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.UPDATED_DEALER_USER:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.INSERT_DEALER_USER:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: ""
      };
    case ActionKeys.INSERTED_DEALER_USER:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REQUEST_FINANCIAL_LISTING:
      return {
        ...state,
        isLoading: true,
        financialItems: [],
        financialCount: 0
      };
    case ActionKeys.RECEIVE_FINANCIAL_LISTING:
      return {
        ...state,
        isLoading: false,
        isSuccess: action.isSuccess,
        financialItems: action.items,
        message: action.message,
        financialCount: action.count
      };
    case ActionKeys.REQUEST_REMIT_HISTORY:
      return {
        ...state,
        isLoading: true,
        historyItems: [],
        historyCount: 0
      };
    case ActionKeys.RECEIVE_REMIT_HISTORY:
      return {
        ...state,
        isLoading: false,
        isSuccess: action.isSuccess,
        historyItems: action.items,
        message: action.message,
        historyCount: action.count
      };
    case ActionKeys.INSERT_REMITTANCE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        remittanceMessage: action.message,
        remittanceId: 0
      };
    case ActionKeys.INSERTED_REMITTANCE:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        remittanceMessage: action.message,
        remittanceId: action.id
      };
    case ActionKeys.REQUEST_REMIT_DETAILS:
      return {
        ...state,
        isLoading: true,
        remitDetails: {}
      };
    case ActionKeys.RECEIVE_REMIT_DETAILS:
      return {
        ...state,
        isLoading: false,
        isSuccess: action.isSuccess,
        message: action.message,
        remitDetails: action.item
      };
    case ActionKeys.REQUEST_OPEN_SERVICE_ORDERS:
      return {
        ...state,
        isOpenServiceOrdersLoading: true,
        openServiceOrders: [],
        openServiceOrderCount: 0
      };
    case ActionKeys.RECEIVE_OPEN_SERVICE_ORDERS:
      return {
        ...state,
        isOpenServiceOrdersLoading: false,
        isSuccess: action.isSuccess,
        openServiceOrders: action.items,
        message: action.message,
        openServiceOrderCount: action.count
      };
    case ActionKeys.REQUEST_CLOSED_SERVICE_ORDERS:
      return {
        ...state,
        isClosedServiceOrdersLoading: true,
        closedServiceOrders: [],
        closedServiceOrderCount: 0
      };
    case ActionKeys.RECEIVE_CLOSED_SERVICE_ORDERS:
      return {
        ...state,
        isClosedServiceOrdersLoading: false,
        isSuccess: action.isSuccess,
        closedServiceOrders: action.items,
        message: action.message,
        closedServiceOrderCount: action.count
      };
    case ActionKeys.REQUEST_SERVICE_ORDER_ITEM:
      return {
        ...state,
        isLoading: true,
        serviceOrderItem: {}
      };
    case ActionKeys.RECEIVE_SERVICE_ORDER_ITEM:
      return {
        ...state,
        isLoading: false,
        isSuccess: action.isSuccess,
        message: action.message,
        serviceOrderItem: action.item
      };
    case ActionKeys.INSERT_CHANGE_REQUEST:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: true,
        message: action.message
      };
    case ActionKeys.INSERTED_CHANGE_REQUEST:
      return {
        ...state,
        isSuccess: action.isSuccess,
        isLoading: false,
        message: action.message
      };
    case ActionKeys.REQUEST_LOAD_DATES:
      return {
        ...state,
        isLoading: true,
        loadDates: []
      };
    case ActionKeys.RECEIVE_LOAD_DATES:
      return {
        ...state,
        isLoading: false,
        isSuccess: action.isSuccess,
        loadDates: action.items,
        message: action.message
      };
    case ActionKeys.REQUEST_DAP_LISTING:
      return {
        ...state,
        isDapListingLoading: true,
        dapItems: []
      };
    case ActionKeys.RECEIVE_DAP_LISTING:
      return {
        ...state,
        isDapListingLoading: false,
        isSuccess: action.isSuccess,
        dapItems: action.items,
        message: action.message
      };
    case ActionKeys.REQUEST_DAP_PROJECT_LISTING:
      return {
        ...state,
        isDapListingLoading: true,
        dapProjectItems: []
      };
    case ActionKeys.RECEIVE_DAP_PROJECT_LISTING:
      return {
        ...state,
        isDapListingLoading: false,
        isSuccess: action.isSuccess,
        dapProjectItems: action.items,
        message: action.message
      };
    case ActionKeys.REQUEST_ACCOUNT_CONTACTS:
      return {
        ...state,
        accountContacts: [],
        isAccountContactsLoading: true
      };
    case ActionKeys.RECEIVE_ACCOUNT_CONTACTS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        accountContacts: action.items,
        isAccountContactsLoading: false
      };
    case ActionKeys.REQUEST_PROJECT_LINE_ITEMS:
      return {
        ...state,
        projectLineItems: null,
        projectLineItemsCount: 0,
        projectLineItemsLoading: true
      };
    case ActionKeys.RECEIVE_PROJECT_LINE_ITEMS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        projectLineItems: action.items,
        projectLineItemsCount: action.count,
        projectLineItemsLoading: false
      };
    case ActionKeys.REQUEST_PROJECT_LINE_ITEMS_EXCLUDING_DAP:
      return {
        ...state,
        projectLineItemsExcludingDap: null,
        projectLineItemsExcludingDapCount: 0,
        projectLineItemsExcludingDAPLoading: true
      };
    case ActionKeys.RECEIVE_PROJECT_LINE_ITEMS_EXCLUDING_DAP:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        projectLineItemsExcludingDap: action.items,
        projectLineItemsExcludingDapCount: action.count,
        projectLineItemsExcludingDAPLoading: false
      };
    case ActionKeys.REQUEST_CURRENT_REVISIONS:
      return {
        ...state,
        currentRevisions: [],
        currentRevisionsCount: 0,
        isCurrentRevisionsLoading: true
      };
    case ActionKeys.RECEIVE_CURRENT_REVISIONS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message,
        currentRevisions: action.items,
        currentRevisionsCount: action.count,
        isCurrentRevisionsLoading: false
      };
    case ActionKeys.REQUEST_USER_DISCOUNTS:
      return {
        ...state
      };
    case ActionKeys.RECEIVE_USER_DISCOUNTS:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    case ActionKeys.VIEW_USER_DISCOUNT:
      return {
        ...state
      };
    case ActionKeys.VIEWED_USER_DISCOUNT:
      return {
        ...state,
        isSuccess: action.isSuccess,
        message: action.message
      };
    default:
      return state;
  }
}
