import { useEffect, useState } from "react";
import LogoImage from "../images/Loewen-DealerNet-Vert-2c.png";
import Http from "../services/http";

const checkOutage = async abortController => {
  return Http.getRequest("isup", null, abortController);
};

export default function OutageSplash({ children }) {
  const [hasOutage, setHasOutage] = useState(false);
  const [isSunday, setIsSunday] = useState(false);

  useEffect(() => {
    const abort = new AbortController();

    checkOutage(abort)
      .then(response => {
        setHasOutage(response.isOutage);
        setIsSunday(response.isSunday);
      })
      .catch(e => {
        console.log(e);
        if (e.name !== "AbortError") {
          setHasOutage(true);
        }
      });

    return () => abort.abort();
  }, []);

  const nonSundayOutage = (
    <p>
      The Loewen DealerNet system is currently unavailable. Please check back
      later.
    </p>
  );

  const sundayOutage = (
    <p>
      The Loewen DealerNet system will be down from approximately 5:00 AM to
      9:00AM CST for scheduled maintenance.
      <br />
      Thank you for your patience.
    </p>
  );

  const outageSplash = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "calc(100vh - 100px)",
        textAlign: "center"
      }}
    >
      <img src={LogoImage} width={175} />
      {isSunday ? sundayOutage : nonSundayOutage}
    </div>
  );

  return hasOutage ? outageSplash : children;
}
